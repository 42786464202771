import React from "react";
import { useLocation } from "react-router-dom";

import { Container, Typography } from "@mui/material";

import CancelButton from "../components/CancelButton";
import Header from "../components/Header";
import PrimaryBlock from "../components/PrimaryBlock";
import GeneralSettings from "../models/GeneralSettings";
import PreConsent from "../models/PreConsent";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";

interface ErrorProps {
    basePath: string;
    api: OpenBankingPlatformAPI;
    generalSettingsConfig?: GeneralSettings;
    preConsentConfig?: PreConsent;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
}

interface ErrorDetails {
    errorText?: string;
    errorCode?: number;
    uuid?: string;
    type?: string;
}

const Error = (props: ErrorProps) => {
    const location = useLocation();

    const errorDetails = location.state as ErrorDetails;
    const errorDescription = (errorDetails?.errorCode ? 'Code ' + errorDetails?.errorCode : '') + (errorDetails?.uuid ? ' - ' + errorDetails?.uuid : '')

    return <>
        <div className={"page-top"}>
            <Header
                generalSettings={props.generalSettingsConfig}
                principalLogoUrl={props.principalLogoUrl}
                headerBgImageUrl={props.headerBgImageUrl}
            />
            <main>
                <PrimaryBlock>
                    <Container maxWidth="sm" className="success-confirmation">
                        <h2>An error has occurred</h2>
                        <div>
                            <p>{errorDetails?.errorText ?? 'Please contact our support team for assistance.'}</p>
                            <Typography color="textSecondary" variant="caption" paragraph={true}>{errorDescription}</Typography>
                        </div>
                        <div className="text-align-center">
                            <CancelButton
                                basePath={props.basePath}
                                type={errorDetails?.type || 'error'}
                                api={props.api}
                                generalSettingsConfig={props.generalSettingsConfig}
                                showConfirmation={false}
                            >Exit</CancelButton>
                        </div>
                    </Container>
                </PrimaryBlock>
            </main>
        </div>
    </>;
}

export default Error;