import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Breadcrumbs, Grid, Hidden, Link } from "@mui/material";

import ConsentHistoryHeader from "../components/ConsentHistoryHeader";
import ConsentHistoryListItem from "../components/ConsentHistoryListItem";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoadingSpinner from "../components/LoadingSpinner";
import PrimaryBlock from "../components/PrimaryBlock";
import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import Consent from "../models/Consent";
import ConsentHistoryListItemData from "../models/ConsentHistoryListItemData";
import ConsentListItemData from "../models/ConsentListItemData";
import GeneralSettings from "../models/GeneralSettings";
import PreConsentData from "../models/PreConsentData";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";

interface ConsentHistoryProps {
    basePath: string;
    api: OpenBankingPlatformAPI;
    dashboardPath: string;
    consentConfig?: Consent;
    loadedPreConsentData?: PreConsentData;
    generalSettingsConfig?: GeneralSettings;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
    loadPrincipalConfiguration: (principal_id?: number) => void;
    footerText?: string;
    isTrustedAdviser?: boolean;
}

interface ConsentHistoryState {
    consentDetails?: ConsentListItemData;
    consentStatus: string;
    apiHasReturnedAnError?: boolean;
    data: Array<ConsentHistoryListItemData>;
    isLoading: boolean;
    downloadPdfData?: any;
}

const ConsentHistory = (props: ConsentHistoryProps) => {
    const { id } = useParams<{ id: any }>();

    const navigate = useNavigate();

    const [state, setState] = useState<ConsentHistoryState>({
        consentStatus: "",
        data: [],
        isLoading: false
    });

    //eslint-disable-next-line
    const getConsent = useCallback(props.api.getConsentById, []);

    //eslint-disable-next-line
    const getConsentHistory = useCallback(props.api.getConsentHistoryById, []);

    useEffect(() => {
        (async () => {
            setState({ ...state, isLoading: true });
            try {
                const loadConsent = await getConsent(id);
                const loadHistory = await getConsentHistory(id);
                setState({
                    ...state,
                    consentDetails: loadConsent,
                    consentStatus: loadConsent.status.toLowerCase() === 'active' ? 'Active' : 'Inactive',
                    data: loadHistory,
                    isLoading: false
                })
            } catch (error) {
                setState((state) => ({
                    ...state,
                    apiHasReturnedAnError: true,
                    isLoading: false
                }))
                console.log(state.apiHasReturnedAnError);
                consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath)
            }
        })();
    }, // eslint-disable-next-line
        []
    )

    //eslint-disable-next-line
    const getConsentHistoryReceipt = useCallback(props.api.getConsentHistoryDownloadPdf, []);
    const handleDownloadConsentHistoryReceipt = (consent_uuid: string, consent_type: number) => {
        //Send to server
        (async (consent_uuid, consent_type) => {
            try {
                const pdfData = await getConsentHistoryReceipt(consent_uuid, consent_type);
                setState(state => ({
                    ...state,
                    downloadPdfData: pdfData,
                }));
            } catch (error) {
                consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })(consent_uuid, consent_type);
    };

    useEffect(() => {
        if (state.downloadPdfData) {
            let blob = new Blob([state.downloadPdfData], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.setAttribute('download', 'consent.pdf');
            link.href = window.URL.createObjectURL(blob);
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }, [state.downloadPdfData]);

    const handleGoBack = () => {
        navigate(props.dashboardPath + `/consent-details/${id}`);
    };

    // Reset to default configured principal on dashboard load
    useEffect(() => {
        (async () => {
            await props.loadPrincipalConfiguration(0);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className={"page-wrapper consent-details"}>
        <div className={"page-top"}>
            <main>
                <Header
                    generalSettings={props.generalSettingsConfig}
                    principalLogoUrl={props.principalLogoUrl}
                    headerBgImageUrl={props.headerBgImageUrl}
                    isTrustedAdviser={props.isTrustedAdviser}
                />
                <PrimaryBlock className={"pt-10"}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href={props.dashboardPath}>Dashboard</Link>
                        <Link color="inherit" href={props.dashboardPath + `/consent-details/${id}`}>{state.consentDetails?.institution_name || 'Consent Details'}</Link>
                        <span>Consent History</span>
                    </Breadcrumbs>
                    <ConsentHistoryHeader
                        basePath={props.basePath}
                        consentDetails={state.consentDetails}
                        consentStatus={state.consentStatus}
                        handleGoBack={handleGoBack}
                        generalSettingsConfig={props.generalSettingsConfig}
                    />
                    <div className={"consent-history-list paginated-table"}>
                        <Hidden smDown>
                            <Grid container className={"table-head"}>
                                <Grid item xs={11} md={9}>
                                    <Grid container>
                                        <Grid item xs={12} md={3} className="col-update-date">Consent Updated</Grid>
                                        <Grid item xs={12} md={3} className="col-description">Change description</Grid>
                                        <Grid item xs={12} md={3} className="col-granted-date">Consent granted date</Grid>
                                        <Grid item xs={12} md={3} className="col-expiry-date">Expiry date</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} md={3} className="col-actions"></Grid>
                            </Grid>
                        </Hidden>
                        {!state.isLoading && state.data && state.data.map((item, i) => {
                            return <ConsentHistoryListItem downloadHistoryReceipt={handleDownloadConsentHistoryReceipt} key={i} {...item
                            } />
                        })}
                        {state.isLoading &&
                            <LoadingSpinner loadingText={""} />
                        }
                    </div>
                </PrimaryBlock>
            </main>
        </div>

        <Footer generalSettingsConfig={props.generalSettingsConfig} />

    </div>;
}

export default ConsentHistory;