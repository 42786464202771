import React, { FunctionComponent, PropsWithChildren, useCallback, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import {useLocation, useNavigate} from "react-router-dom";

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, useTheme } from "@mui/material";

import CustomerDetailsData from "../models/CustomerDetailsData";
import GeneralSettings from "../models/GeneralSettings";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";

interface CancelButtonProps {
    generalSettingsConfig?: GeneralSettings;
    showConfirmation?: boolean;
    type?: string;
    api: OpenBankingPlatformAPI;
    children?: React.ReactNode;
    basePath: string;
}

export const CancelButton: FunctionComponent<CancelButtonProps> = ({
    generalSettingsConfig,
    showConfirmation = true,
    type = 'cancelled',
    api,
    children,
    basePath
}) => {
    const location = useLocation();
    const getRedirectionUrl = useCallback(async (type: any, error = undefined) => api.getRedirectionUrl(type, error), [api]);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();
    
    const openPopup = () => {
        showConfirmation ? setOpen(true) : cancelProcess();
    }

    const closePopup = () => {
        setOpen(false);
    }

    const cancelProcess = async () => {
        if(loading) return false;
        setLoading(true);

        if(typeof window !== "undefined" && window.top !== window.self && generalSettingsConfig?.callbackURL){
            const customer = sessionStorage.getItem('customer');
            const customerData = customer ? JSON.parse(customer) as CustomerDetailsData : null;

            window.parent.postMessage({
                message: "Cancelled",
                value: {
                    customer_id: customerData?.customer_id,
                }
            }, generalSettingsConfig.callbackURL);
        }

        if (generalSettingsConfig?.cancellationRedirectURL){
            if(generalSettingsConfig?.enhancedRedirection?.enableForCancellation){

                // Make API request to set the redirection URL
                let error = undefined;
                if(type === 'error'){
                    error = {
                        // @ts-ignore
                        errorCode: location?.state?.errorCode,
                        // @ts-ignore
                        errorText: location?.state?.errorText,
                    }
                }
                try {
                    const redirectionUrl = await getRedirectionUrl(type, error as any);
                    if(redirectionUrl.url){
                        window.location.href = redirectionUrl.url;         
                        return true;
                    }
                } catch (error) {
                    window.location.href = generalSettingsConfig.cancellationRedirectURL;
                    return false;
                }
            }
            window.location.href = generalSettingsConfig.cancellationRedirectURL; 
        } else {
            navigate(basePath + "/preconsent")
        }

        setLoading(false);
        return null;
    }

    if(generalSettingsConfig?.includeCancelButtonAndPopUp !== true){
        return <></>
    }
    
    return (
        <>
            <Button
                variant={"outlined"}
                color={"secondary"}
                onClick={openPopup}
            >
                {children}
            </Button>

            <CancelButtonPopup
                heading={generalSettingsConfig?.cancelModalPopUpHeading}
                open={open}
                onYes={cancelProcess}
                onNo={closePopup}
                loading={loading}
            >
                {ReactHtmlParser(generalSettingsConfig?.cancelModalPopUpMainText || '')}
            </CancelButtonPopup>
        </>
    )
}

export default CancelButton;

interface CancelButtonPopupProps {
    heading?: string;
    open?: boolean;
    onYes?: () => void;
    onNo?: () => void;
    loading?: boolean;
}

const CancelButtonPopup = (props: PropsWithChildren<CancelButtonPopupProps>) => {
    const theme = useTheme();
    const style = {
        dialogContent: {
            color: theme.palette.info.contrastText
        },
        loadingSpinner: {
            marginLeft: "15px",
            color: 'rgba(0, 0, 0, 0.26)'
        }
    };

    return (
        <Dialog maxWidth={"sm"} open={props.open || false} onClose={props.onNo} PaperProps={{elevation: undefined}}>
            <DialogContent sx={style.dialogContent}>
                <h2>{props.heading}</h2>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onYes} variant={"outlined"} color={"secondary"} disabled={props.loading}>Yes {props.loading &&
                    <CircularProgress sx={style.loadingSpinner} size={20} color="secondary" />
                }</Button>
                <Button onClick={props.onNo} variant={"outlined"} color={"secondary"}>No</Button>
            </DialogActions>
        </Dialog>
    );
};
