import React, { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { Breadcrumbs, Button, FormControl, Grid, Hidden, InputBase, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import ButtonBlock from "../components/ButtonBlock";
import ConsentListItem from "../components/ConsentListItem";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoadingSpinner from "../components/LoadingSpinner";
import PrimaryBlock from "../components/PrimaryBlock";
import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import GeneralSettings from "../models/GeneralSettings";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import Popup from "../popups/Popup";
import DashboardSettings from "../models/DashboardSettings";

interface DashboardState {
	data: Array<any>;
	isLoading: boolean;
	searchKeyword: string;
	searchCategory: string;
	currentPage: number;
	pageCount: number;
	principals?: {
		principal_id: number;
		name: string;
	}[];
}

interface DashboardProps {
	basePath: string;
	api: OpenBankingPlatformAPI;
	connectDataHolderPath: string;
	dashboardPath: string;
	generalSettingsConfig?: GeneralSettings;
	dashboardSettingsConfig?: DashboardSettings;
	hasApiError?: boolean;
	principalLogoUrl?: string;
	headerBgImageUrl?: string;
	isAccount?: boolean;
	loadPrincipalConfiguration: (principal_id?: number) => void;
	footerText?: string;
	isTrustedAdviser?: boolean;
}

const Dashboard = (props: DashboardProps) => {

	let perPage: number = 10;

	const [state, setState] = useState<DashboardState>({
		data: [],
		isLoading: false,
		searchKeyword: '',
		searchCategory: "all",
		currentPage: 0,
		pageCount: 0,
	});

	const navigate = useNavigate();

	//eslint-disable-next-line
	const getConsents = useCallback(props.api.getDashboardConsents, []);

	const doSearch = () => {
		(async () => {
			try {
				setState((state) => ({
					...state,
					isLoading: true,
				}));
				const loadedConsents = await getConsents(state.currentPage + 1, state.searchKeyword, state.searchCategory);
				perPage = loadedConsents.pagination;
				setState((state) => ({
					...state,
					data: loadedConsents.dataset,
					isLoading: false,
					pageCount: Math.ceil(loadedConsents.total / perPage),
					principals: loadedConsents.principals,
				}));

				if (selectedPrincipal === 0 && loadedConsents.principals[0]) {
					setSelectedPrincipal(loadedConsents.principals[0].principal_id);
				}

				if (state.currentPage > Math.ceil(loadedConsents.total / perPage) - 1) {
					setState((state) => ({
						...state,
						currentPage: 0,
					}));
				}
			} catch (error) {
				// TODO Uncomment this code when fallback is removed
				setState((state) => ({
					...state,
					apiHasReturnedAnError: true,
					isLoading: false,
				}));
				consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
			}
		})();
	}

	const connectNewInstitution = () => {
		if (props.isAccount) {
			setOpen(true);
		} else {
			navigate(props.connectDataHolderPath, {
				state: {
					returnToDashboard: true,
				}
			});
		}
	}

	const handlePageClick = (e: any) => {
		const selectedPage = e.selected;
		const newOffset = selectedPage * perPage;
		setState((state) => ({
			...state,
			currentPage: selectedPage,
			offset: newOffset,
		}));
	}

	const [open, setOpen] = useState(false);
	const [selectedPrincipal, setSelectedPrincipal] = useState(0);

	const changePrincipal = () => {
		(async () => {
			try {
				setState((state) => ({
					...state,
					isLoading: true,
				}));
				if (selectedPrincipal !== 0) {
					if (props.loadPrincipalConfiguration) {
						await props.loadPrincipalConfiguration(selectedPrincipal);
					}
					navigate(props.connectDataHolderPath, {
						state: {
							returnToDashboard: true,
						}
					});
				}
			} catch (error) {
				setState((state) => ({
					...state,
					apiHasReturnedAnError: true,
					isLoading: false,
				}));
				consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
			}
		})();
	}

	useEffect(() => {
		doSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.currentPage]);

	// Reset to default configured principal on dashboard load
	useEffect(() => {
		(async () => {
			await props.loadPrincipalConfiguration(0);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <div className="page-wrapper dashboard">
		<div className={"page-top"}>
			<Header
				generalSettings={props.generalSettingsConfig}
				principalLogoUrl={props.principalLogoUrl}
				headerBgImageUrl={props.headerBgImageUrl}
				isTrustedAdviser={props.isTrustedAdviser}
			/>
			<main>
				{(props.generalSettingsConfig) ? (
					<>
						<PrimaryBlock className={"pt-10"}>
							<Breadcrumbs aria-label="breadcrumb">
								<span>Dashboard</span>
							</Breadcrumbs>
							<h2>Data Sharing Dashboard</h2>
							<Grid container className={"dashboard-action-bar"}>
								<Grid item xs={12} md={5} lg={6} mb={2}>
									{(props.dashboardSettingsConfig?.includeConnectInstitutionButton ?
											<Button variant={"contained"} color={"secondary"} onClick={connectNewInstitution}>Connect institution</Button> : ""
									)}
								</Grid>
								<Grid item xs={12} md={7} lg={6}>
									<Grid container className={"filters-bar"}>
										<Grid item xs={6} sm={7}>
											<InputBase
												id={"keyword-input"}
												placeholder={"Enter search"}
												inputProps={{ "aria-label": "Enter search" }}
												onChange={event => {
													setState({ ...state, searchKeyword: event.target.value.toLowerCase() });
												}}
											/>
										</Grid>
										<Grid item xs={4} sm={3}>
											<FormControl variant="outlined">
												<Select
													id={"category-select"}
													value={state.searchCategory}
													displayEmpty
													onChange={event => {
														setState({ ...state, searchCategory: event.target.value as string });
													}}
												>
													<MenuItem value={"all"}>All</MenuItem>
													<MenuItem value={"active"}>Active</MenuItem>
													<MenuItem value={"expired"}>Expired</MenuItem>
													<MenuItem value={"withdrawn"}>Withdrawn</MenuItem>
													<MenuItem value={"archived"}>Archived</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={2}>
											<Button id={"search-btn"} variant={"contained"} color={"secondary"}
												disabled={state.searchKeyword.length < 3 && state.searchKeyword.length > 0}
												onClick={() => doSearch()}
											>
												<Hidden smDown>Search</Hidden>
												<Hidden smUp><SearchIcon /></Hidden>
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<div className={"institution-list paginated-table"}>
								<Hidden mdDown>
									<Grid container className={"table-head"}>
										<Grid item md={1}></Grid>
										<Grid item md={9}>
											<Grid container>
												<Grid item md={4}>Name</Grid>
												<Grid item md={2}>Status</Grid>
												<Grid item md={3}>Data Recipient</Grid>
												<Grid item md={3}>Consent Last Updated</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Hidden>
								{!state.isLoading && state.data && state.data.map((item, i) => {
									return <ConsentListItem isAccount={props.isAccount} consentDetailsPath={props.dashboardPath + "/consent-details"} key={i} {...item} />
								})}
								{state.isLoading &&
									<LoadingSpinner loadingText={""} />
								}
							</div>
							{state.pageCount > 1 &&
								<ReactPaginate
									previousLabel={'<'}
									nextLabel={'>'}
									breakLabel={'...'}
									pageCount={state.pageCount}
									marginPagesDisplayed={2}
									pageRangeDisplayed={5}
									onPageChange={handlePageClick}
									containerClassName={'pagination'}
									activeClassName={'active'}
								/>
							}
						</PrimaryBlock>

						<Popup
							heading={'Select your data recipient'}
							open={open}
							onClose={() => setOpen(false)}
							buttons={
								<ButtonBlock>
									<Button onClick={() => setOpen(false)} variant={'outlined'} color={'secondary'}>Cancel</Button>
									<Button onClick={() => changePrincipal()} variant={'contained'} color={'secondary'}>Continue</Button>
								</ButtonBlock>
							}
						>
							Please select a data recipient you wish to consent sharing your data with.

							<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
								<Select
									id="data_recipient"
									name="data_recipient"
									value={selectedPrincipal}
									onChange={(event: SelectChangeEvent<number>) => {
										setSelectedPrincipal(event.target.value as number);
									}}
								>
									{state.principals && state.principals.map(principal => (
										<MenuItem key={principal.principal_id} value={principal.principal_id}>{principal.name}</MenuItem>
									))}
								</Select>
							</div>
						</Popup>
					</>
				)
					: (props.hasApiError)
						? <>
							<h3 className={"error-title"}>Error</h3>
							<p className={"error-text"}>We're sorry but our system has encountered an error. Please try
								again later or contact support.</p>
						</>
						: <LoadingSpinner position={"fixed"} overlay />
				}
			</main>
		</div>

		<Footer generalSettingsConfig={props.generalSettingsConfig} />

	</div>;
}

export default Dashboard;