import React from "react";

import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Hidden, useTheme } from "@mui/material";

import ConsentListItemData from "../models/ConsentListItemData";
import GeneralSettings from "../models/GeneralSettings";

interface ConsentHistoryHeaderProps
{
    basePath: string;
    consentDetails?: ConsentListItemData;
    consentStatus: string;
    handleGoBack?: () => void;
    generalSettingsConfig?: GeneralSettings;
}

const ConsentHistoryHeader = (props: ConsentHistoryHeaderProps) => {
    const theme = useTheme();
    const style = {
    	consentHistoryHeader: {
    		borderBottom: `1px dashed ${theme.palette.secondary.main}`
    	}
    };

    return <Box className={"consent-details-header consent-history-header"} sx={style.consentHistoryHeader}>
        <Grid container spacing={3} className={"institution-card"}>
            <Grid item xs={12} sm={8} md={9}>
                <Grid container spacing={2}>
                    <Grid className="institution-logo" item xs={2} md={1}>
                        <span className="institution-logo-helper"></span>
                        <img src={props.consentDetails?.logo_uri} alt="" />
                    </Grid>
                    <Grid className="institution-title" item xs={10} md={11}>
                        <h2>{props.consentDetails?.institution_name}</h2>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Hidden xsDown>
                        <Grid item md={1}></Grid>
                    </Hidden>
                    <Grid item xs={12} md={11}>
                        <div id="card-misc">
                            {props.consentDetails && <span>{props.consentDetails?.principal}</span>}
                            {props.consentStatus && <span className={`status-${props.consentStatus.toLowerCase()}`}><FontAwesomeIcon icon={faCircle} /> {props.consentStatus}</span>}
                            <span className="data-recipient">Data recipient: {props.consentDetails?.principal_accreditation_number}</span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={3} className={"text-align-right text-align-left-xs"}>
                <Button onClick={props.handleGoBack} variant={"contained"} color={"secondary"}>Back</Button>
            </Grid>
        </Grid>
    </Box>
}

export default ConsentHistoryHeader;