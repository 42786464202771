import React from "react";
import {sanitize} from "../helpers/HtmlSanitizerHelper";
import GeneralSettings from "../models/GeneralSettings";
import {hasRichTextSpecifiedForField} from "../helpers/InputFieldValidationHelper";
import EditableRichText from "../admin/components/EditableRichText";
import { Property } from 'csstype';
import { Box } from "@mui/material";

interface FooterProps {
    bottomFixed?: string;
    generalSettingsConfig?: GeneralSettings;
}
const Footer = (props: FooterProps) => {
    const footerBottomClass = props.bottomFixed === "true" ? "footer-bottom" : "footer-custom";

    const footerTextAlignment = props.generalSettingsConfig?.footerTextAlignment ?? 'center';

    const getTextAlign = (align: string):Property.TextAlign => {
        var output = 'center';
        switch (align) {
            case 'left':
                output = 'left';
                break;
            case 'center':
                output = 'center';
                break;
            case 'right':
                output = 'right';
                break;
        }

        return output as Property.TextAlign;
    }
    
    const divStyle = { 
                        textAlign: getTextAlign(footerTextAlignment),  
                        backgroundColor: props.generalSettingsConfig?.footerBackgroundColor,
                        color: props.generalSettingsConfig?.footerTextColor,
                        fontFamily: props.generalSettingsConfig?.footerFontFamily
                     };
                        

    return <div className={"footer page-bottom"} style={divStyle}>
            <Box>
                <img src="/images/logo-illion.png" alt="" style={{ maxWidth: "200px", maxHeight: "45px", marginBottom: "20px", marginTop: "2em" }} /> 
            </Box>
            <footer className={`footer ${footerBottomClass}`}>
                {(props.generalSettingsConfig?.footerText && hasRichTextSpecifiedForField(props.generalSettingsConfig.footerText)) ?
                    <EditableRichText
                        editMode={false}
                        value={props.generalSettingsConfig.footerText}
                        onChange={() => {}}
                    /> :
                    <>Please read our <a href={props.generalSettingsConfig?.cdrPolicyURL}>Consumer Data Right policy</a> for some information on how {sanitize(props.generalSettingsConfig?.principalName)} handles your data.</>
                }
            </footer>
        </div>;
}

export default Footer;
