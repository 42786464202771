import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { faCircle, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Hidden, Typography, useTheme } from "@mui/material";

import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import ConsentListItemData from "../models/ConsentListItemData";
import GeneralSettings from "../models/GeneralSettings";
import OpenBankingPlatformAPIInterface from "../openbankingplatform/OpenBankingPlatformAPIInterface";
import {sanitize} from "../helpers/HtmlSanitizerHelper";

interface ConsentDetailsHeaderProps
{
    basePath: string;
    consentDetails?: ConsentListItemData;
    consentStatus: string;
    handleShowHistory?: () => void;
    handleStopSharing?: () => void;
    generalSettingsConfig?: GeneralSettings;
    api: OpenBankingPlatformAPIInterface;
}

interface ConsentDetailsHeaderState
{
    downloadPdfData?: any;
}

const ConsentDetailsHeader = (props: ConsentDetailsHeaderProps) => {
    const navigate = useNavigate();

    const [state, setState] = useState<ConsentDetailsHeaderState>({
    });

    //eslint-disable-next-line
    const getDownloadPdfData = useCallback(props.api.getDownloadPdf, []);
    const handleDownloadConsentPdf = (consent_uuid: string, consent_type: number) => {
        //Send to server
        (async (consent_uuid, consent_type) => {
            try {
                const pdfData = await getDownloadPdfData(consent_uuid, consent_type);
                setState(state => ({
                    ...state,
                    downloadPdfData: pdfData,
                }));
            } catch (error) {
                //console.log(error);
                consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath)
            }
        })(consent_uuid, consent_type);
    }
    useEffect(() => {
        if (state.downloadPdfData) {
            let blob = new Blob([state.downloadPdfData], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.setAttribute('download', 'consent.pdf');
            link.href = window.URL.createObjectURL(blob);
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }, [state.downloadPdfData]);
    
    const theme = useTheme();
	const style = {
		consentDetailsHeader: {
			borderBottom: `1px dashed ${theme.palette.secondary.main}`
		}
	};

    return <Box component={"div"} className={"consent-details-header"} sx={style.consentDetailsHeader}>
        <Grid container spacing={3} className={"institution-card"}>
            <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                    <Grid className="institution-logo" item xs={2} md={1}>
                        <span className="institution-logo-helper"></span>
                        <img src={props.consentDetails?.logo_uri} alt="" />
                    </Grid>
                    <Grid className="institution-title" item xs={10} md={11}>
                        <h2>{sanitize(props.consentDetails?.institution_name)}</h2>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Hidden xsDown>
                        <Grid item md={1}></Grid>
                    </Hidden>
                    <Grid item xs={12} md={11}>
                        <div id="card-misc">
                            {props.consentDetails?.principal && <span>{props.consentDetails?.principal}</span>}
                            <span className={`status-${props.consentStatus.toLowerCase()}`}><FontAwesomeIcon icon={faCircle} /> {props.consentStatus}</span>
                            {props.consentDetails?.id ? (
                              <Typography component={"span"} color={"secondary"}><FontAwesomeIcon icon={faDownload} /> <a href="/#" onClick={(e) => {e.preventDefault(); handleDownloadConsentPdf(props.consentDetails?.uuid as string, props.consentStatus === 'Withdrawn' ? 2 : 1);}} >Download confirmation of {props.consentStatus === 'Withdrawn' ? 'withdrawal' : 'consent'}</a></Typography>
                            ): null}
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={4} className={"text-align-right text-align-left-xs"}>
                <Button onClick={props.handleShowHistory} variant={"contained"} color={"secondary"}>Show History</Button>
                { (props.handleStopSharing && props.consentDetails?.status === 'Active'
                    && (props.consentDetails.sharing_period !== '0' && props.consentDetails.sharing_period !== undefined))?
                <Button onClick={props.handleStopSharing} variant={"contained"} color={"secondary"}>Stop sharing</Button> : "" }
            </Grid>

        </Grid>
    </Box>
}

export default ConsentDetailsHeader;