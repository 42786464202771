import React from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";

import { faChevronRight, faCircle, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Grid, Hidden, Link, Typography } from "@mui/material";

import ConsentListItemData from "../models/ConsentListItemData";
import MessageBox from "./MessageBox";
import { sanitize } from "../helpers/HtmlSanitizerHelper";

interface ConsentListItemProps extends ConsentListItemData {
	consentDetailsPath: string;
	isAccount?: boolean;
}

const ConsentListItem = (props: ConsentListItemProps) => {
	const navigate = useNavigate();

	const handleViewDetails = () => {
		navigate(props.consentDetailsPath + `/${props.uuid}`);
	};

	const status = props.status;

	return <Grid container className={"consent-list-item"}>
		<Grid item xs={2} md={1}><img src={props.logo_uri} alt="" /></Grid>
		<Grid item xs={9}>
			<Grid container>
				<Grid item xs={12} md={4} className={"col-name"}>{sanitize(props.institution_name)}</Grid>
				<Grid item xs={5} md={2} className={"col-status"}><span className={`status-${status.toLowerCase()}`}><FontAwesomeIcon icon={faCircle} /> {status}</span></Grid>
				<Grid item xs={7} md={3}>{sanitize(props.principal_name)}</Grid>
				<Grid item xs={12} md={3} className={"col-date"}>
					<Moment format="DD MMM YYYY">
						{props.effective_at}
					</Moment>
				</Grid>
			</Grid>
		</Grid>
		<Grid item xs={1} md={2} className={"col-actions"}>
			{(props.is_cdr) ?
				<Link color={"secondary"} href="/#" onClick={(e: any) => { e.preventDefault(); handleViewDetails(); }}>
					<b><Hidden mdDown><u>View details</u> </Hidden><FontAwesomeIcon icon={faChevronRight} /></b>
				</Link>
				: ""}
		</Grid>
		{props.message && <Grid container>
			<Grid item xs={2} md={1} className={"pt-0"}></Grid>
			<Grid item xs={10} md={11} className={"pt-0"}>
				<MessageBox icon={props.message.type === "success" ? <CheckCircleOutlineIcon /> : <ErrorOutlineOutlinedIcon />} background={"green"}>
					{props.message.content}
					{props.message.links.map((link, i) =>
						<Typography key={i}>
							{link.icon === "download" && <><FontAwesomeIcon icon={faDownload} /> &nbsp;</>}
							<a style={{ color: "inherit" }} href={link.url}>{link.text}</a>
						</Typography>
					)}
				</MessageBox>
			</Grid>
		</Grid>}
	</Grid>
}

export default ConsentListItem;


