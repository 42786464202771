import React, { useCallback, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useLocation, useNavigate } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Link } from "@mui/material";

import AccreditationBadge from "../components/AccreditationBadge";
import ButtonBlock from "../components/ButtonBlock";
import CancelButton from "../components/CancelButton";
import Card from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PrimaryBlock from "../components/PrimaryBlock";
import SecondaryBlock from "../components/SecondaryBlock";
import DataHolder from "../models/DataHolder";
import GeneralSettings from "../models/GeneralSettings";
import PreConsent from "../models/PreConsent";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import { sanitize } from "../helpers/HtmlSanitizerHelper";

interface CDRValuePropositionProps {
    prev: string;
    next: string;
    preConsentConfig?: PreConsent;
    generalSettingsConfig?: GeneralSettings;
    dashboardPath: string;
    isMultiBank?: boolean;
    currentDataHolderSelection?: DataHolder;
    api: OpenBankingPlatformAPI;
    basePath: string;
    consentExists?: boolean;
    principalLogoUrl?: string;
    accreditationIconUrl?: string;
    headerBgImageUrl?: string;
    cdrSupportingImageUrl?: string;
    cdrFeature1ImageUrl?: string;
    cdrFeature2ImageUrl?: string;
    cdrFeature3ImageUrl?: string;
    footerText?: string;
    isTrustedAdviser?: boolean;
}

interface CDRValuePropositionState {
    returnToDashboard: boolean;
}

const CDRValueProposition = (props: CDRValuePropositionProps) => {

    const getColumnSize = (numberOfColumns: Number) => {
        if (numberOfColumns === 2) return 6;
        if (numberOfColumns === 3) return 4;
        return 12;
    };
    const columnSize = getColumnSize(props.preConsentConfig?.cdrNumberOfFeatures ?? 0);

    const [state, setState] = useState<CDRValuePropositionState>({
        returnToDashboard: false,
    });

    const navigate = useNavigate();

    /*
    const doesPreConsentDataAlreadyExists = ():boolean => {
        let preConsentDataOptions = sessionStorage.getItem('preConsentDataOptions');
        if (preConsentDataOptions) {
            return true;
        }

        return false;
    }*/

    // eslint-disable-next-line
    const savePreConsentData = useCallback(props.api.savePreConsentData, []);
    const storeDataRequestTypes = () => {
        // This is essentially used for multibank session when there is already at least one bank selected
        // which had the data request type preference already set for it for any subsequent banks simply utilise
        // the same preferences (captured in sessionStorage) for any subsequent banks and then just skip straight
        // to the bank connectivity screen
        let hasDefaultDataRequestTypes = sessionStorage.getItem('hasDefaultDataRequestTypes');
        if (hasDefaultDataRequestTypes) {
            if (props.consentExists) {
                navigate(props.next, {
                    state: {
                        returnToDashboard: state.returnToDashboard,
                        skipDataDeletionStep: true,
                    }
                });
            } else {
                navigate(props.basePath + '/data-holder-connect-confirmation', {
                    state: {
                        returnToDashboard: state.returnToDashboard
                    }
                })
            }

        } else {
            navigate(props.next, {
                state: {
                    returnToDashboard: state.returnToDashboard,
                    skipDataDeletionStep: props.consentExists ? true : false,
                }
            })
        }


        /*
        if (props.consentExists) {
            navigate(props.basePath + '/consent/previous-data', {
                state: {
                    returnToDashboard: state.returnToDashboard
                }
            });
        } else {
            let hasDefaultDataRequestTypes = sessionStorage.getItem('hasDefaultDataRequestTypes');
            if (hasDefaultDataRequestTypes) {
                navigate(props.basePath + '/data-holder-connect-confirmation', {
                    state: {
                        returnToDashboard: state.returnToDashboard
                    }
                })
            } else {
                navigate(props.next, {
                    state: {
                        returnToDashboard: state.returnToDashboard
                    }
                })
            }
        }*/
    }

    const handleGoNext = () => {
        if (props.isMultiBank) {
            storeDataRequestTypes();
        } else {
            navigate(props.next, {
                state: {
                    returnToDashboard: state.returnToDashboard
                }
            })
        }
    }

    const handleGoBack = () => {
        navigate(-1);
    };

    const location = useLocation();
    useEffect(() => {
        // @ts-ignore
        if (location.state && location.state.returnToDashboard) {
            // @ts-ignore
            const returnToDashboard: boolean = location.state.returnToDashboard;
            setState((state) => ({
                ...state,
                returnToDashboard: returnToDashboard,
            }));
        }
    }, [location]);

    const getCdrAccreditationName = () => {
        return (props.isTrustedAdviser ? "illion Open Data Solutions Pty Ltd" : props.generalSettingsConfig?.adrName)
    }

    const getCdrAccreditationNumber = () => {
        return (props.isTrustedAdviser ? "ADRBNK000017" : props.generalSettingsConfig?.accreditationNumber)
    }

    return <div className={"page-wrapper cdr-value-proposition"}>
        <div className={"page-top"}>
            <Header
                generalSettings={props.generalSettingsConfig}
                principalLogoUrl={props.principalLogoUrl}
                headerBgImageUrl={props.headerBgImageUrl}
                isTrustedAdviser={props.isTrustedAdviser}
            />
            <main>
                <PrimaryBlock>

                    <Grid  id={"primary-content-0"}  container spacing={3}>
                        <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e: any) => { e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                    </Grid>


                    <br />

                    <Grid id={"primary-content-1"} container spacing={3}>
                        {props.preConsentConfig?.includeCdrSupportingImage && props.cdrSupportingImageUrl &&
                            <Grid item xs={12} sm={5} md={4}>
                                <div className={"hero-image"}>
                                    <img className="mw-100" src={props.cdrSupportingImageUrl} alt="CDR" />
                                </div>
                            </Grid>
                        }
                        <Grid item xs={12} sm={7} md={8} className="align-self-center">
                            {props.preConsentConfig?.includeCdrKeyText ? (
                                <div>
                                    <h3>{sanitize(props.preConsentConfig?.cdrKeyTextHeading)}</h3>
                                    {ReactHtmlParser(sanitize(props.preConsentConfig?.cdrKeyTextMainText))}
                                </div>
                            ) : ""}
                        </Grid>
                    </Grid>

                    <Grid id={"primary-content-2"} className={"pt-30"} container spacing={6}>
                        <Grid item md={6} sm={12}>
                            {props.preConsentConfig?.includeCdrVpInformation ? (
                                <Box mb={2}>
                                    <h3>{sanitize(props.preConsentConfig?.cdrVpInformationHeading)}</h3>
                                    {ReactHtmlParser(sanitize(props.preConsentConfig?.cdrVpInformationMainText))}
                                </Box>
                            ) : ""}
                            <AccreditationBadge
                                companyName={getCdrAccreditationName()}
                                showIcon={props.generalSettingsConfig?.showIconInAccreditationBadge}
                                iconUrl={props.accreditationIconUrl}
                                accreditationNumber={getCdrAccreditationNumber()}
                                cdrLogoUrl={"/images/data-holder-logos/consumer-data-right.png"}
                            />
                            <p>We are an Accredited Consumer Data right Recipient, meaning we are bound by rules set by the Australian Government on how we can handle your data.</p>
                            {/* eslint-disable-next-line */}
                            <p>To learn more, visit <a href="https://www.cdr.gov.au/" rel="noopener" target="_blank" className="CDR-link">www.cdr.gov.au</a></p>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <div className="preconsent-CDR-policy">
                                <h3>What you should know</h3>
                                <ul>
                                    <li>You won't need to use your internet banking password</li>
                                    <li>We don't share or use your data without your consent</li>
                                    <li>You can easily stop sharing your bank data with us at anytime</li>
                                    <li>You can choose to delete shared data that we no longer need</li>
                                </ul>
                                {props.preConsentConfig?.includeCdrVpInformation ? (
                                    <p>Find out more in our <a href={props.generalSettingsConfig?.incInsightStage ? 'https://www.illion.com.au/consumer-data-right-policy' : props.generalSettingsConfig?.cdrPolicyURL} target="_blank" rel="noopener noreferrer" className="CDR-link">Consumer Data Right policy</a></p>
                                ) : ""}
                            </div>
                        </Grid>
                    </Grid>

                    <ButtonBlock>
                        <CancelButton basePath={props.basePath} api={props.api} generalSettingsConfig={props.generalSettingsConfig}>Cancel</CancelButton>
                        <Button variant={"contained"} color={"secondary"} onClick={handleGoNext}>Start</Button>
                    </ButtonBlock>


                </PrimaryBlock>

                <SecondaryBlock
                    header={props.preConsentConfig?.includeKeyFeaturesAndInformation ? <>How to share your data</> : undefined}
                    textAlign={'center'}
                >
                    {props.preConsentConfig?.includeKeyFeaturesAndInformation ? (
                        <>
                            <Grid container spacing={2}>
                                <Grid item md={columnSize} sm={12}>
                                    <Card image={props.cdrFeature1ImageUrl ?? ""}>
                                        {sanitize(props.preConsentConfig?.cdrFeature1Text)}
                                    </Card>
                                </Grid>
                                {(props.preConsentConfig.cdrNumberOfFeatures >= 2) &&
                                    <Grid item md={columnSize} sm={12}>
                                        <Card image={props.cdrFeature2ImageUrl ?? ""}>
                                            {sanitize(props.preConsentConfig?.cdrFeature2Text)}
                                        </Card>
                                    </Grid>
                                }
                                {(props.preConsentConfig.cdrNumberOfFeatures >= 3) &&
                                    <Grid item md={columnSize} sm={12}>
                                        <Card image={props.cdrFeature3ImageUrl ?? ""}>
                                            {sanitize(props.preConsentConfig?.cdrFeature3Text)}
                                        </Card>
                                    </Grid>
                                }
                            </Grid>
                        </>
                    ) : ""}
                </SecondaryBlock>
            </main>
        </div>

        <Footer generalSettingsConfig={props.generalSettingsConfig} />

    </div>;
}

export default CDRValueProposition;