import React, { useCallback, useEffect, useState } from "react";

import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Checkbox, FormControl, FormControlLabel, Grid } from "@mui/material";

import SwitchWithLabel from "../../components/SwitchWithLabel";
import Consent from "../../models/Consent";
import Insight from "../../models/Insight";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import Configuration from "../models/Configuration";
import EditableRichText from "./EditableRichText";
import EditableText from "./EditableText";
import FileItem from "./FileItem";

interface ViewInsightSettingsProps
{
    adminObApi: OpenBankingPlatformAPIInterface,
    configuration: Configuration,
    insight: Insight,
    consent: Consent,
}

const ViewInsightSettings = (props: ViewInsightSettingsProps) => {
    const logoUrl = `/insights/logos/${props.configuration.principal_id}/${props.insight.uploadLogo}`;
    const [logoSrc, setLogoSrc] = useState('');
    const pdfUrl = `/insights/privacy-policy/${props.configuration.principal_id}/${props.insight.privacyPolicyPDF}`;
    const [PdfData, setPdfData]: any = useState();

    const [identities, setIdentities] = useState([
        (props.insight.incIdentityIncName ? "Name" : null),
        (props.insight.incIdentityIncAddress ? "Address" : null),
    ]);

    const [balances, setBalances] = useState([
        (props.insight.incBalanceIncBSBAndAccNumber ? "Account Details - BSB & Account Number or Masked Credit Card Number" : null),
        (props.insight.incBalanceIncAccBalance ? "Account Balance" : null),
        (props.insight.incBalanceIncAvgAccBalance ? "Average Account Balance" : null),
        (props.insight.incBalanceIncDaysInNegative ? "Days in Negative" : null),
    ]);

    const [incomes, setIncomes] = useState([
        (props.insight.incIncomeBusinessNameAndLegalName ? "Name (Business Name and Legal Name for business consumer)" : null),
        (props.insight.incIncomeABN ? "ABN (for business consumer only)" : null),
        (props.insight.incIncomeIncMonthlyAvgIncome ? "Monthly Average Income" : null),
        (props.insight.incIncomeIncTotalIncomeOver6Months ? "Total Income over past 6 months" : null),
        (props.insight.incIncomeIncTotalIncomeOver3Months ? "Total Income over last 3 months" : null)
    ]);

    const [bankAccounts, setBankAccounts] = useState([
        (props.insight.incBankAccountBusinessNameAndLegalName ? "Name  (Business Name and Legal Name for business consumer)" : null),
        (props.insight.incBankAccountABN ? "ABN (for business consumer only)" : null),
        (props.insight.incBankAccountIncBSBAndAccNumber ? "Account Details - BSB & Account Number or Masked Credit Card Number" : null),
        (props.insight.incBankAccountIncAccountName ? "Account Name" : null),
        (props.insight.incBankAccountIncIsOwned ? "Is Owned - `Yes` or `No`" : null),
    ]);

    
    
    useEffect(() => {
        setIdentities(identities.filter(val => val !== null));
        setBalances(balances.filter(val => val !== null));
        setIncomes(incomes.filter(val => val !== null));
        setBankAccounts(bankAccounts.filter(val => val !== null));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //eslint-disable-next-line
    const getFile = useCallback(props.adminObApi.getFile, []);
    useEffect(() => {
        (async(logoUrl) => {
            if (props.insight.uploadLogo !== undefined && props.insight.uploadLogo !== '') {
                try {
                    const data = await getFile(logoUrl);
                    setLogoSrc(URL.createObjectURL(data));
                } catch (error) {
                    console.log(error);
                }
            }
        })(logoUrl);
    //eslint-disable-next-line
    }, [props.insight.uploadLogo]);

    useEffect(() => {
        if (PdfData) {
            let blob = new Blob([PdfData], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.setAttribute('download', 'privacy-policy.pdf');
            link.href = window.URL.createObjectURL(blob);
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }, [PdfData]);

    const handleShowPrivacyPolicyPDF = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        (async(pdfUrl) => {
            try {
                const data = await getFile(pdfUrl);
                setPdfData(data);
            } catch (error) {
                console.log(error);
            }
        })(pdfUrl);
    };

    const dataClusters = 
    <>
    {props.consent.dataClustersWithOptions
        .filter(options => options.clusterId === 2 || options.clusterId === 8 || options.clusterId === 9)
        .map((dataClusterWithOptions, index) => {
                const detailsBox = (dataClusterWithOptions.isEnabled &&
                    <Box className={`details-box`}>
                        <label className={"mt-10"}>"Why we need it" text</label>
                        <EditableText
                            editMode={false}
                            name={"usageExplanatoryText"}
                            value={dataClusterWithOptions.purposeInformationInsight}
                            eventHandlers={{}}
                        />
                        <label className={"mt-10"}>List of data fields</label>
                        <ul>
                            {dataClusterWithOptions.clusterPermissionNames.map((permissionName, index) =>
                                <li key={index}>{permissionName}</li>
                            )}
                        </ul>
                    </Box>);

                    return <div className={"form-group"} key={index}>
                        <FormControlLabel
                            className = {"disabled-checkbox-label-color"}
                            control={<Checkbox 
                                name={dataClusterWithOptions.authorisationScopeId} 
                                checked={dataClusterWithOptions.isInsightEnabled}
                                className = {"disabled-checkbox-color"}
                                color = {"primary"}
                                disabled={true}
                                onChange={() => {}}/>}
                            label={dataClusterWithOptions.clusterName}
                        />
                        {detailsBox}
                    </div>
                }
            )}
    </>;

    return <>

        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={4} md={3}>
                <label>Main Heading</label>
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
                {props.insight.mainHeading}
            </Grid>
        </Grid>
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={4} md={3}>
                <label>Subheading</label>
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
                {props.insight.subHeading}
            </Grid>
        </Grid>

        {/* Third Party  */}

        <Grid container className={"mt-50"}>
            <Box mb={2}>
                <h4>Third Party</h4>
            </Box>
        </Grid>

        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={4} md={3}>
                <label>Name</label>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                {props.insight.name}
            </Grid>
        </Grid>
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={4} md={3}>
                <label>Logo</label>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                <div className={"file-item-wrap"}>
                    <Box className={"details-box"}>
                        <FileItem type={"image"} name={props.insight.uploadLogo} url={logoSrc}/>
                    </Box>
                </div>
            </Grid>
        </Grid>
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={6} md={3}>
                <label>Business Description</label>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                {props.insight.businessDescription}
            </Grid>
        </Grid>
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={6} md={3}>
                <label>Purpose for the insight being requested</label>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                {props.insight.purposeInsightWasRequested}
            </Grid>
        </Grid>
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={6} md={3}>
                <label>Privacy Policy URL</label>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                {props.insight.privacyPolicyURL}
            </Grid>
        </Grid>
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={6} md={3}>
                <label>Privacy Policy PDF</label>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <a href={"/#"} onClick={handleShowPrivacyPolicyPDF}>Download PDF</a>&nbsp;
                <a href={"/#"} onClick={handleShowPrivacyPolicyPDF}><FontAwesomeIcon icon={faDownload} /></a>
            </Grid>
        </Grid>

        {/* Insights */}

        <Grid container className={"mt-50"}>
            <Box mb={2}>
                <h4>Insights</h4>
            </Box>
        </Grid>

        <Grid container className={"mb-30"}>
            <Grid item xs={12} sm={4} md={3}>
                <label>Verify your idenity</label>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                {props.insight.incVerifyIdentity ? "Include" : "Exclude"}
                {props.insight.incVerifyIdentity &&
                <>
                    <Box className={`details-box mb-10`}>
                        <EditableRichText value={props.insight.incIdentityDescription}/>
                    </Box>
                    <ul>
                    {identities.map((entry, k) => 
                        <li key={k}>{entry}</li>
                    )}
                    </ul>
                </>}
            </Grid>
        </Grid>

        <Grid container className={"mb-30"}>
            <Grid item xs={12} sm={4} md={3}>
                <label>Verify your balance</label>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                {props.insight.incVerifyBalance ? "Include" : "Exclude"}
                {props.insight.incVerifyBalance &&
                <>
                    <Box className={`details-box mb-10`}>
                        <EditableRichText value={props.insight.incBalanceDescription}/>
                    </Box>
                    <ul>
                    {balances.map((entry, k) => 
                        <li key={k}>{entry}</li>
                    )}
                    </ul>
                </>}
            </Grid>
        </Grid>

        <Grid container className={"mb-30"}>
            <Grid item xs={12} sm={4} md={3}>    
                <label>Verify your income</label>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                {props.insight.incVerifyIncome ? "Include" : "Exclude"}
                {props.insight.incVerifyIncome &&
                <>
                    <Box className={`details-box mb-10`}>
                        <EditableRichText value={props.insight.incIncomeDescription}/>
                    </Box>
                    <ul>
                    {incomes.map((entry, k) => 
                        <li key={k}>{entry}</li>
                    )}
                    </ul>
                </>}  
            </Grid>
        </Grid>

        <Grid container className={"mb-30"}>
            <Grid item xs={12} sm={4} md={3}>
                <label>Verify your bank account</label>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                {props.insight.incVerifyBankAccount ? "Include" : "Exclude"}
                {props.insight.incVerifyBankAccount &&
                <>
                    <Box className={`details-box mb-10`}>
                        <EditableRichText value={props.insight.incBankAccountDescription}/>
                    </Box>
                    <ul>
                    {bankAccounts.map((entry, k) => 
                        <li key={k}>{entry}</li>
                    )}
                    </ul>
                </>}  
            </Grid>
        </Grid>

        {/* Data request */}

        <Grid container className={"mt-50"}>
            <Box mb={2}>
                <h4>Data Request</h4>
            </Box>
        </Grid>
        
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={6} md={3}>
                <label>Active consent duration explanatory text</label>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                {props.insight.activeConsentDurationText}
            </Grid>
        </Grid>
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={6} md={3}>
                <label>"Data we need" text</label>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                {props.insight.dataWeNeedText}
            </Grid>
        </Grid>

        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={4} md={3}>
                <label>Data types to collect &amp; usage explanatory text</label>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                {dataClusters}
            </Grid>
        </Grid>

        <Grid container className={"mt-50"}>
            <Grid item xs={12} sm={4} md={3}>
                <label>Data Handling</label>
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
                <Box className={`details-box`}>
                    <Box>
                        <label>Heading</label>
                        <EditableText
                            editMode={false}
                            name={"additionalUsesOfData.mainHeading"}
                            value={props.insight.dataHandlingMainHeading}
                            eventHandlers={{onChange: () => {}}}
                        />
                    </Box>
                    <Box mt={4}>
                        <label>Subheading</label>
                        <EditableRichText value={props.insight.dataHandlingSubHeading}/>
                    </Box>
                </Box>
            </Grid>
        </Grid>

        <Grid container className={"mt-50"}>
            <Grid item xs={12} sm={4} md={3}>
                <label>Additional uses of data</label>
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
                <Box className={`details-box`}>
                    <Box>
                        <label>Main Heading</label>
                        <EditableText
                            editMode={false}
                            name={"additionalUsesOfData.mainHeading"}
                            value={props.insight.additionalUsesOfData?.mainHeading}
                            eventHandlers={{onChange: () => {}}}
                        />
                    </Box>
                    <Box mt={4}>
                        <label>Subheading</label>
                        <EditableText
                            editMode={false}
                            name={"additionalUsesOfData.mainHeading"}
                            value={props.insight.additionalUsesOfData?.subheading}
                            eventHandlers={{onChange: () => {}}}
                        />
                    </Box>
                </Box>
                <Box className={`details-box`} mt={4}>
                    <Box display="flex" justifyContent={'space-between'}>
                        <label>Categorisation Improvements</label>
                        <FormControl>
                            <SwitchWithLabel 
                                checked={props.insight.additionalUsesOfData?.categorisation?.include || false}
                                disabled={true}
                                onChange={value => {}}
                            >
                                {props.insight.additionalUsesOfData?.categorisation?.include ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <label>Label</label>
                        <EditableText
                            textArea
                            editMode={false}
                            name={"additionalUsesOfData.subheading"}
                            value={props.insight.additionalUsesOfData?.categorisation?.label}
                            eventHandlers={{onChange: (e: any) => {}}}
                        />
                    </Box>
                </Box>
                <Box className={`details-box`} mt={4}>
                    <Box display="flex" justifyContent={'space-between'}>
                        <label>Spend Analytics</label>
                        <FormControl>
                            <SwitchWithLabel 
                                checked={props.insight.additionalUsesOfData?.spendAnalytics?.include || false}
                                disabled={true}
                                onChange={value => {}}
                            >
                                {props.insight.additionalUsesOfData?.spendAnalytics?.include ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <label>Label</label>
                        <EditableText
                            textArea
                            editMode={false}
                            name={"additionalUsesOfData.subheading"}
                            value={props.insight.additionalUsesOfData?.spendAnalytics?.label}
                            eventHandlers={{onChange: (e: any) => {}}}
                        />
                    </Box>
                </Box>

                <Box className={`details-box`} mt={4}>
                    <Box display="flex" justifyContent={'space-between'}>
                        <label>Marketing</label>
                        <FormControl>
                            <SwitchWithLabel 
                                checked={props.insight.additionalUsesOfData?.marketing?.include || false}
                                disabled={true}
                                onChange={value => {}}
                            >
                                {props.insight.additionalUsesOfData?.marketing?.include ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <label>Label</label>
                        <EditableText
                            textArea
                            editMode={false}
                            name={"additionalUsesOfData.subheading"}
                            value={props.insight.additionalUsesOfData?.marketing?.label}
                            eventHandlers={{onChange: (e: any) => {}}}
                        />
                    </Box>
                </Box>
            </Grid>
        </Grid>

        <Grid container className={"mt-50"}>
            <Grid item xs={12} sm={4} md={3}>
                <label>Frequency of data collection</label>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                {props.insight.includeFrequencyOfDataCollectionText ? "Include" : "Exclude"}
                <Box className={`details-box`}>
                    <label>Main Heading</label>
                    <EditableText
                        textArea
                        editMode={false}
                        name={"insight.frequencyOfDataCollectionTextMainHeading"}
                        value={props.insight.frequencyOfDataCollectionTextMainHeading}
                        eventHandlers={{onChange: (e: any) => {}}}
                    />
                    <label>Main Text</label>
                    <EditableText
                        textArea
                        editMode={false}
                        name={"insight.frequencyOfDataCollectionTextMainText.subheading"}
                        value={props.insight.frequencyOfDataCollectionTextMainText}
                        eventHandlers={{onChange: (e: any) => {}}}
                    />
                </Box>
            </Grid>
        </Grid>

        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={6} md={3}>
                <label>Display Data Deletion section</label>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                {props.insight.includeDataDeletionStandardText ? "Include" : "Exclude"}
            </Grid>
        </Grid>

        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={6} md={3}>
                <label>Replace an existing consent text</label>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                {props.insight.includeReplaceAnExistingConsentText ? "Include" : "Exclude"}
            </Grid>
        </Grid>

    </>
}    

export default ViewInsightSettings;