import React, { useCallback, useEffect, useState } from "react";
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs, Button, Container, Grid, Hidden, Link, Typography, useTheme } from "@mui/material";

import ButtonBlock from "../components/ButtonBlock";
import ConsentDataCluster from "../components/ConsentDataCluster";
import ConsentDetailsHeader from "../components/ConsentDetailsHeader";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LinkButton from "../components/LinkButton";
import LoadingSpinner from "../components/LoadingSpinner";
import SwitchWithLabel from "../components/SwitchWithLabel";
import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import Consent from "../models/Consent";
import ConsentListItemData from "../models/ConsentListItemData";
import DataHolder from "../models/DataHolder";
import GeneralSettings from "../models/GeneralSettings";
import ModalPopUpData from "../models/ModalPopUpData";
import PreConsentData from "../models/PreConsentData";
import DataCluster from "../openbankingplatform/models/DataCluster";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import modalPopUpContents from "../popups/ModalPopUpDataHelper";
import Popup from "../popups/Popup";
import { sanitize } from "../helpers/HtmlSanitizerHelper";
import { getInsightConfig } from "../helpers/PreconsentInsightHelper";
import Insight from "../models/Insight";
import PreConsent from "../models/PreConsent";
import EditableRichText from "../admin/components/EditableRichText";
import {hasRichTextSpecifiedForField} from "../helpers/InputFieldValidationHelper";

interface ConsentDetailsProps {
	basePath: string
	api: OpenBankingPlatformAPI,
	dashboardPath: string,
	onDeleteOptionChange: (newOption: boolean) => void;
	currentDeleteOption: boolean | undefined;
	preconsentConfig?: PreConsent;
	consentConfig?: Consent;
	insightConfig?: Insight;
	loadedPreConsentData?: PreConsentData;
	generalSettingsConfig?: GeneralSettings;
	principalLogoUrl?: string;
	headerBgImageUrl?: string;
	onSelectionChange: (newSelection: DataHolder, loadedPreConsentData?: PreConsentData) => void;
	loadPrincipalConfiguration: (principal_id?: number) => void;
	footerText?: string;
	isTrustedAdviser?: boolean;
}

interface ConsentDetailsState {
	consentDetails?: ConsentListItemData;
	dataClusters: Array<DataCluster>;
	sharingStartAt?: string;
	initialSharingStartAt?: string;
	sharingEndAt?: string;
	consentStatus: string;
	delete_my_data_after_used: boolean;
	apiHasReturnedAnError: boolean;
	isInsight: boolean;
	insights: Array<string>;
	snapShotPreConsentData?: PreConsent;
	snapShotConsentData?: Consent;
	modalPopUpData: Array<ModalPopUpData>;
	footerText?: string;
}

const ConsentDetails = (props: ConsentDetailsProps) => {
	const { id } = useParams<{ id: any }>();

	var newDeleteOption = false;
	if (props.currentDeleteOption) {
		newDeleteOption = props.currentDeleteOption;
	}
	const [deleteDataOption, setDeleteDataOption] = useState(newDeleteOption);

	const navigate = useNavigate();

	//eslint-disable-next-line
	const getConsent = useCallback(props.api.getConsentById, []);
	//eslint-disable-next-line
	const getDataClusters = useCallback(props.api.getSelectedDataClustersForConsent, []);
	//eslint-disable-next-line
	const getInsights = useCallback(props.api.getSelectedInsightsForConsent, []);
	useEffect(() => {
		(async () => {
			try {
				await props.loadPrincipalConfiguration(0);

				const loadedDataClusters = await getDataClusters(id);
				const loadConsent = await getConsent(id);

				setState((state) => ({
					...state,
					consentDetails: loadConsent,
					dataClusters: loadedDataClusters,
					sharingStartAt: loadConsent.sharing_start_at,
					initialSharingStartAt: loadConsent.initial_sharing_start_at,
					sharingEndAt: loadConsent.sharing_end_at,
					consentStatus: loadConsent.status,
					isInsight: loadConsent.is_insight,
					snapShotPreConsentData: loadConsent.consent_snapshot?.preConsent,
					snapShotConsentData: loadConsent.consent_snapshot?.consent,
					modalPopUpData: modalPopUpContents(
						props.generalSettingsConfig,
						loadConsent.consent_snapshot?.preConsent,
						loadConsent.consent_snapshot?.consent,
						props.generalSettingsConfig?.principalName,
						props.loadedPreConsentData?.data_holder_brand_name,
						thirdParties,
						props.consentConfig?.thirdPartiesEmail
					),
				}))
				setDeleteDataOption(loadConsent.delete_my_data_after_used);

				if (state.isInsight) {
					const loadedInsights = await getInsights(id);
					setState((state) => ({
						...state,
						insights: loadedInsights
					}))
				}

			} catch (error) {

				// TODO Uncomment this code when fallback is removed
				setState((state) => ({
					...state,
					apiHasReturnedAnError: true,
				}))
				consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath)
			}
		})();
	},
		//eslint-disable-next-line
		[]
	)

	const [open, setOpen] = React.useState(false);
	const [currentPopupModalData, setCurrentPopupModalData] = React.useState<ModalPopUpData>();

	const openPopup = (modalPopUpData: ModalPopUpData) => {
		setOpen(true);
		setCurrentPopupModalData(modalPopUpData);
	};

	const closePopup = () => {
		setOpen(false);
	};

	//eslint-disable-next-line
	/*const updateConsent = useCallback(props.api.updateConsent, []);
	const handleSave = () => {
		if (props.onDeleteOptionChange){
			props.onDeleteOptionChange(deleteDataOption);
			//Save and send to server
			(async () => {
				try {
					await updateConsent(id, deleteDataOption);
				} catch (error) {
					//console.log(error);
					consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath)
				}
			})();
		}
		navigate(props.dashboardPath);
	};*/

	//eslint-disable-next-line
	const getPreConsentData = useCallback(props.api.getPreConsentData, []);
	const updateConsent = () => {
		if (props.onDeleteOptionChange) {
			props.onDeleteOptionChange(deleteDataOption);
			//Save and send to server
			(async () => {
				try {
					if (state.consentDetails?.institution_id) {
						await props.loadPrincipalConfiguration(state.consentDetails?.principal_id || 0);
						const dataHolder: DataHolder = {
							data_holder_id: state.consentDetails.data_holder_brand_id,
							id: state.consentDetails.institution_id,
							title: state.consentDetails.institution_name,
							logo: state.consentDetails.logo_uri,
							is_cdr: state.consentDetails.data_holder_brand_is_cdr,
							is_top_bank: state.consentDetails.data_holder_brand_is_top_bank,
						}

						const loadedPreConsentData = await getPreConsentData(state.consentDetails?.institution_id);
						if (props.onSelectionChange) {
							loadedPreConsentData.data_holder_brand_identifier = dataHolder.id;

							if (loadedPreConsentData.consent_exists === undefined) {
								loadedPreConsentData.consent_exists = false;
							}

							props.onSelectionChange(dataHolder, loadedPreConsentData);
						}

						//Store data_holder_brand_identifier locally
						sessionStorage.setItem('dataHolderBrand', JSON.stringify(dataHolder));
						navigate(props.basePath + "/consent/data-request", {
							state: {
								returnToDashboard: true
							}
						});
					} else {
						navigate(props.dashboardPath);
					}
				} catch (error) {
					//console.log(error);
					consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath)
				}
			})();
		} else {
			navigate(props.dashboardPath);
		}
	}

	const providerPopup = () => {
		const providerPopUpDatas = state.modalPopUpData[0];
		openPopup(providerPopUpDatas);
	}

	const deIdentifyPopup = () => {
		const identifyPopUpDatas = state.modalPopUpData[1];
		openPopup(identifyPopUpDatas);
	}

	const deletePopup = () => {
		const deletePopUpDatas = state.modalPopUpData[2];
		openPopup(deletePopUpDatas);
	}

	const popUpGeneralText = currentPopupModalData?.popUpGeneralText.map((item, index) =>
		<p key={index} dangerouslySetInnerHTML={{ __html: sanitize(item.content) }}></p>
	);

	const popUpBoxText = currentPopupModalData?.popupBoxContents.map((item) =>
		<>
			<h6>{item.heading}</h6>
			<p>{item.content}</p>
		</>
	);

	const popUpBlockText = currentPopupModalData?.popupBlockContents.map((item, index) => {
		return (
			<div key={index}>
				<h6>{item.heading}</h6>
				<br />
				{
					item.content?.map((word, index) => {
						return (
							<p key={index} dangerouslySetInnerHTML={{ __html: sanitize(word.text) }}></p>
						)
					})
				}
				<br />
			</div>
		)
	});

	const handleDataDeleteOption = (currentOption: boolean) => {
		setDeleteDataOption(currentOption);
	}

	const handleStopSharing = () => {
		navigate(props.dashboardPath + `/consent-details/${id}/withdraw`, {
			state: state.consentDetails
		});
	}

	const handleShowHistory = () => {
		navigate(props.dashboardPath + `/consent-history/${id}`, {
			state: state.consentDetails
		});
	}

	const handleGoBack = () => {
		navigate(props.dashboardPath);
	};

	const thirdParties = props.consentConfig?.thirdParties?.map((el) => {
		return { heading: el.key || '', content: el.label || '' };
	});

	const [state, setState] = useState<ConsentDetailsState>({
		dataClusters: [],
		consentStatus: '',
		delete_my_data_after_used: false,
		apiHasReturnedAnError: false,
		isInsight: false,
		insights: [],
		modalPopUpData: modalPopUpContents(
			props.generalSettingsConfig,
			props.preconsentConfig,
			props.consentConfig,
			props.generalSettingsConfig?.principalName,
			props.loadedPreConsentData?.data_holder_brand_name,
			thirdParties,
			props.consentConfig?.thirdPartiesEmail
		),
	});

	const snapShotConsentConfig = state.snapShotConsentData ? state.snapShotConsentData : props.consentConfig;

	const displayAdditionalUsesOfData = (state.consentDetails?.additional_uses_of_data || []).filter(use => use.isSelected).length > 0;

	const displaySupportingThirdParties = state.snapShotConsentData?.thirdParties && state.snapShotConsentData?.thirdParties.length > 0;


	const dataClustersList = state.dataClusters.map((item, i) =>
		<ConsentDataCluster
			key={i}
			consentDetails={state.consentDetails}
			principalName={props.generalSettingsConfig?.principalName}
			sharingStartAt={state.sharingStartAt}
			initialSharingStartAt={state.initialSharingStartAt}
			sharingEndAt={state.sharingEndAt}
			snapShotConsentData={snapShotConsentConfig}
			generalSettings={props.generalSettingsConfig}
			{...item} />
	);

	const insightsList = state.insights.map((insightName, i) => {
		const insightConfig = getInsightConfig(insightName, props.insightConfig);
		const dataClusterObj = {
			'id': 0,
			'subtitle': '',
			'data_list': [""],
			'scopes': [""],
			'uuid': 'string',
			'identifier': 'string'
		};
		return insightConfig ? <ConsentDataCluster
			key={i}
			title={insightConfig?.name}
			principalName={insightConfig?.name}
			description={''}
			richDescription={insightConfig.description}
			status={'ACTIVE'}
			is_mandatory={'YES'}
			{...dataClusterObj}
		/> : <></>
	});

	const getAdditionalUseLabel = (useKey: string) => {
		const customKeyPrefix = 'custom_';
		let use = null;
		if (useKey === 'categorisation' || useKey === 'marketing' || useKey === 'spendAnalytics') {
			use = props.consentConfig?.additionalUsesOfData && props.consentConfig?.additionalUsesOfData[useKey];
		}
		if (useKey.startsWith(customKeyPrefix)) {
			useKey = useKey.slice(customKeyPrefix.length);
			use = props.consentConfig?.additionalUsesOfData?.custom && props.consentConfig.additionalUsesOfData.custom.find(use => use.key === useKey);
		}

		if (use) {
			return use.label;
		}

		return false;
	}

	const theme = useTheme();
	const style = {
		styledAccordion: {
			backgroundColor: theme.palette.info.main,
			color: theme.palette.info.contrastText
		},
		accordionHeading: {
			color: theme.palette.primary.contrastText
		}
	};

	return <div className={"page-wrapper consent-details"}>
		<div className={"page-top"}>
			<main>
				<Header
					generalSettings={props.generalSettingsConfig}
					principalLogoUrl={props.principalLogoUrl}
					headerBgImageUrl={props.headerBgImageUrl}
					isTrustedAdviser={props.isTrustedAdviser}
				/>
				{(state.consentDetails) ?
					<>
						<Container maxWidth={"lg"}>
							<Breadcrumbs aria-label="breadcrumb">
								<Link color="inherit" href="/#" onClick={(e: any) => { e.preventDefault(); navigate(props.dashboardPath); }}>Dashboard</Link>
								<span>{sanitize(state.consentDetails?.institution_name)}</span>
							</Breadcrumbs>

							<ConsentDetailsHeader
								basePath={props.basePath}
								consentDetails={state.consentDetails}
								consentStatus={state.consentStatus}
								handleStopSharing={handleStopSharing}
								handleShowHistory={handleShowHistory}
								generalSettingsConfig={props.generalSettingsConfig}
								api={props.api}
							/>

							<Grid container spacing={5} className={"data-collection-details"}>
								{/* Column 1 */}
								<Grid item xs={12} md={6}>

									<Box mb={3}>
										<h3 className="font-weight-normal">Data we are collecting</h3>
									</Box>

									{/* Display the list of selected data clusters linked to this consent */}
									{dataClustersList}

									{state.isInsight && <>
										<Box mb={3} sx={{ pt: 5 }}>
											<h3 className="font-weight-normal">Insights we are disclosing</h3>
										</Box>
										{insightsList}
									</>}

								</Grid>
								{/* End of Column 1 */}

								{/* Column 2 */}
								<Grid item xs={12} md={6}>
									{(state.consentDetails.status === 'Active') ?
										<>
											{displayAdditionalUsesOfData ? (
													<Accordion className={"simple-accordion"} defaultExpanded>
														<Typography component={"div"}>
															<AccordionSummary expandIcon={<KeyboardArrowDownRoundedIcon sx={style.accordionHeading} />}>Additional uses of your data</AccordionSummary>
														</Typography>
														<AccordionDetails>
															<p>You have agreed to let us do the following:</p>
															<ul>
																{state.consentDetails.additional_uses_of_data.filter(use => use.isSelected).map(use => {
																	const useLabel = getAdditionalUseLabel(use.key);
																	return useLabel ? <li key={use.key}>{sanitize(useLabel)}</li> : <></>
																})}
															</ul>
														</AccordionDetails>
													</Accordion>
												) : ("")}


											{displaySupportingThirdParties ? (
												<Accordion className={"simple-accordion"} defaultExpanded>
												<Typography component={"div"}>
												<AccordionSummary expandIcon={<KeyboardArrowDownRoundedIcon sx={style.accordionHeading} />}>Supporting third parties</AccordionSummary>
												</Typography>
												<AccordionDetails>
												<p>Supporting third parties will help provide this service. They will be bound by the same terms of this arrangement.</p>
												<p><a href="/#" onClick={(e) => { e.preventDefault(); providerPopup(); }}>See list of providers</a></p>
												</AccordionDetails>
												</Accordion>
												) : ("")}
										</> : ""
									}

									{(state.consentDetails.status === 'Withdrawn') ?
										<Accordion className={"simple-accordion"} defaultExpanded>
											<Typography component={'div'} color={"secondary"}>
												<AccordionSummary expandIcon={<KeyboardArrowDownRoundedIcon color={"secondary"} />}>How your data was used</AccordionSummary>
											</Typography>
											<AccordionDetails>
												<h6>Purpose of data sharing</h6>
												<p>We collected and used your data to help you track your budget.</p>

												<h6>Sharing with third parties</h6>
												<p>Supporting third parties no longer have access to your data. They were bound by the same terms of this arrangement. <a href="/#" onClick={(e) => {
													e.preventDefault();
													providerPopup();
												}}>Learn more</a></p>

												<h6>What happened to your shared data?</h6>
												<p>The data you have shared has been deleted. <a href="/#" onClick={(e) => {
													e.preventDefault();
													deletePopup()
												}}>Learn more</a></p>
											</AccordionDetails>
										</Accordion>
										: ""}


									<Accordion className={"simple-accordion"} defaultExpanded={displayAdditionalUsesOfData}>
										<Typography component={"div"}>
											<AccordionSummary expandIcon={<KeyboardArrowDownRoundedIcon sx={style.accordionHeading} />}>Key dates</AccordionSummary>
										</Typography>
										<AccordionDetails>
											<h6>When you gave consent</h6>
											<p>
												<Moment format="DD MMM YYYY">
													{state.sharingStartAt}
												</Moment>
											</p>

											{state.consentDetails?.status === 'Active' ? (
												<>
													<h6>When your consent will expire</h6>
													<p>
														<Moment format="DD MMM YYYY">
															{state.sharingEndAt}
														</Moment>
													</p>

													<h6>How often we access your data</h6>
													<p>
														{state.consentDetails && state.consentDetails?.sharing_period !== '0' ?
															<>We access your data every time you log into {sanitize(props.generalSettingsConfig?.principalName)} during the period of <Moment format="DD MMM YYYY">{state.sharingStartAt}</Moment> to <Moment format="DD MMM YYYY">{state.sharingEndAt}</Moment></> :
															<>This consent is for once-off use only.</>}
													</p>
												</>
											) : (
												<>
													<h6>When you cancelled your consent</h6>
													<p>
														<Moment format="DD MMM YYYY">
															{state.consentDetails?.withdrawn_at}
														</Moment>
													</p>

													<h6>Sharing period</h6>
													<p>
														<Moment format="DD MMM YYYY">{state.sharingStartAt}</Moment> to <Moment format="DD MMM YYYY">{state.sharingEndAt}</Moment>
													</p>

													<h6>How often we accessed your data</h6>
													<p>We accessed your data every time you used {sanitize(props.generalSettingsConfig?.principalName)}.</p>
												</>
											)}
										</AccordionDetails>
									</Accordion>


									{(state.consentDetails?.status === 'Active') ?

										<Accordion className={"simple-accordion"}>
											<Typography component={"div"}>
												<AccordionSummary expandIcon={<KeyboardArrowDownRoundedIcon sx={style.accordionHeading} />}>Your data when we no longer require it</AccordionSummary>
											</Typography>
											<AccordionDetails>


												{(snapShotConsentConfig?.includeDataDeidentificationStandardText) ?
													<Box>
														{(snapShotConsentConfig?.dataDeidentificationText) ?
															<p>{snapShotConsentConfig.dataDeidentificationText}</p> :
															<p>We will de-identify your data when we no longer need it to help you track your spending, and
																there Is no legal obligation to retain it.</p>
														}

														{(snapShotConsentConfig?.dataDeidentificationPopupLinkText && snapShotConsentConfig?.dataDeidentificationPopupLinkText !== '') ?
															<p><a href="/#" className={"popup-link"} onClick={(e) => {
																e.preventDefault();
																deIdentifyPopup();
															}}>{snapShotConsentConfig?.dataDeidentificationPopupLinkText}</a></p> :
															<p><a href="/#" className={"popup-link"} onClick={(e) => {
																e.preventDefault();
																deIdentifyPopup();
															}}>How and why we de-identify your data</a></p>
														}
													</Box> : ""
												}

												{(state.isInsight ? props.insightConfig?.includeDataDeletionStandardText : snapShotConsentConfig?.includeDataDeletionStandardText) ? (
													<div>
														<br />
														<SwitchWithLabel
															checked={deleteDataOption}
															onChange={value => {
																handleDataDeleteOption(value);
															}}
														>
															<p><strong> Delete my data instead </strong></p>
														</SwitchWithLabel>

														{(snapShotConsentConfig?.dataDeletionText && hasRichTextSpecifiedForField(snapShotConsentConfig?.dataDeletionText)) ?
															<p>
																<EditableRichText
																	editMode={false}
																	value={snapShotConsentConfig?.dataDeletionText}
																	onChange={() => {}}
																/>
															</p> :
															<>
																{(state.consentDetails.sharing_period !== '0') &&
																<p>You can also tell us to delete your data by going to Menu {'>'} Data Sharing or by writing to
																	odssupport@illion.com.au.</p>
																}
																{state.sharingEndAt ? (
																	<p>If you don't do this by <Moment format="DD MMM YYYY">
																		{state.sharingEndAt}
																	</Moment>, your data will be de-identified.</p>
																) : (
																	<p>If you don’t do this, your data will be de-identified.</p>
																)}
															</>
														}

														{(snapShotConsentConfig?.dataDeletionPopupLinkText && snapShotConsentConfig?.dataDeletionPopupLinkText !== '') ?

															<p><a href="/#" className={"popup-link"} onClick={(e) => {
																e.preventDefault();
																deletePopup()
															}}>{snapShotConsentConfig?.dataDeletionPopupLinkText}</a></p> :

															<p><a href="/#" className={"popup-link"} onClick={(e) => {
																e.preventDefault();
																deletePopup()
															}}>See how we delete your data</a></p>
														}
													</div>
												) : ("")}

											</AccordionDetails>
										</Accordion>
										: ""}

								</Grid>
								{/* End of Column 2 */}
							</Grid>

							<Box mt={1} mb={6}>
								<Grid container spacing={4}>
									<Hidden xsDown>
										<Grid item xs={12} sm={3} className={"align-self-center"}>
											<Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e: any) => { e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
										</Grid>
									</Hidden>
									<Grid item xs={12} sm={9}>
										{(state.consentDetails?.status === 'Active') &&
											<ButtonBlock className={"text-align-right text-align-center-xs mt-0"}>
												<LinkButton targetlocation={props.dashboardPath} variant={"outlined"} color={"secondary"}>Cancel</LinkButton>
												<Button onClick={updateConsent} variant={"contained"} color={"secondary"}>Update Consent</Button>
											</ButtonBlock>
										}
									</Grid>
								</Grid>
							</Box>
						</Container>

						{/* Information popup */}
						<Popup
							heading={currentPopupModalData?.popUpHeading}
							open={open}
							withCloseButton
							onClose={closePopup}
						>
							{popUpGeneralText}
							{popUpBoxText && popUpBoxText.length > 0 &&
								<Box my={3} p={3} className={"background-grey"}>
									{popUpBoxText}
								</Box>
							}
							{popUpBlockText}
							<a href={props.generalSettingsConfig?.cdrPolicyURL} target="_blank" rel="noopener noreferrer">View Consumer Data Right policy</a>
						</Popup>
					</>
					: (state.apiHasReturnedAnError) ? <>
						<h3 className={"error-title"}>Error</h3>
						<p className={"error-text"}>We're sorry but our system has encountered an error. Please try
							again later or contact support.</p>
					</> : <LoadingSpinner position={"fixed"} overlay />
				}

			</main>
		</div>

		<Footer generalSettingsConfig={props.generalSettingsConfig} />

	</div>;
}

export default ConsentDetails;