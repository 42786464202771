import React from "react";

import {Container} from "@mui/material";

import CancelButton from "../components/CancelButton";
import Header from "../components/Header";
import PrimaryBlock from "../components/PrimaryBlock";
import GeneralSettings from "../models/GeneralSettings";
import PreConsent from "../models/PreConsent";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import EmailRecoverDashboardLinkForTrustedAdvisers
    from "../components/EmailRecoverDashboardLinkForTrustedAdvisers";

interface SessionExpiredProps {
    basePath: string;
    api: OpenBankingPlatformAPI;
    generalSettingsConfig?: GeneralSettings;
    preConsentConfig?: PreConsent;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
    isTrustedAdviser?: boolean;
    brokerCode?: string;
}

const SessionExpired = (props: SessionExpiredProps) => {
    return <>
        <div className={"page-top"}>
            <Header
                generalSettings={props.generalSettingsConfig}
                principalLogoUrl={props.principalLogoUrl}
                headerBgImageUrl={props.headerBgImageUrl}
                isTrustedAdviser={props.isTrustedAdviser}
            />
            <main>
                <PrimaryBlock>
                    <Container maxWidth="sm" className="success-confirmation">
                        {props.isTrustedAdviser && props.brokerCode ?
                            <EmailRecoverDashboardLinkForTrustedAdvisers
                                obApi={props.api}
                                basePath={props.basePath}
                                brokerCode={props.brokerCode}
                            /> :
                            <>
                                <h2>Session Expired</h2>

                                <div>
                                    <p>Your current session has expired.</p>
                                </div>
                                <div className="text-align-center">
                                    <CancelButton
                                        basePath={props.basePath}
                                        type='error'
                                        api={props.api}
                                        generalSettingsConfig={props.generalSettingsConfig}
                                        showConfirmation={false}
                                    >Exit</CancelButton>
                                </div>
                            </>
                        }
                    </Container>
                </PrimaryBlock>
            </main>
        </div>
    </>;
}

export default SessionExpired;