import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { faAngleRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Container, Divider, FormControl, Grid, MenuItem, Select, TextField, Theme, ThemeProvider, Typography } from "@mui/material";

import AccreditationBadge from "../../components/AccreditationBadge";
import ButtonBlock from "../../components/ButtonBlock";
import SwitchWithLabel from "../../components/SwitchWithLabel";
import { validateIfTargetsAreValidEmails, validateIfTargetsAreValidUrls } from "../../helpers/InputFieldValidationHelper";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import { LightMode } from "../../helpers/ThemeHelper";
import GeneralSettings, { EnhancedRedirectionSettings } from "../../models/GeneralSettings";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import Popup from "../../popups/Popup";
import ThemeBuilder from "../../ThemeBuilder";
import UploadFile from "../models/UploadFile";
import FileItem from "./FileItem";
import LightTooltip from "./LightTooltip";
import {sanitize} from "../../helpers/HtmlSanitizerHelper";
import Configuration from "../models/Configuration";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import CurrentUser from "../../models/CurrentUser";

interface EditGeneralSettingsProps {
    basePath: string,
    generalSettings: GeneralSettings,
    onGeneralSettingsChange?: (newGeneralSettings: GeneralSettings, newFileUpload?: UploadFile) => void,
    configurationName: string,
    configurationDescription: string,
    onConfigurationNameChange?: (name: string) => void,
    onConfigurationDescriptionChange?: (description: string) => void,
    statementDeliveryEmailRef: React.MutableRefObject<any>,
    statementDeliveryEndpointRef: React.MutableRefObject<any>,
    consentExpiryEndpointRef: React.MutableRefObject<any>,
    adminObApi: OpenBankingPlatformAPIInterface,
    configurationPrincipalId?: number,
    previewSettings?: boolean,
    onClosePreviewSettings?: () => void,
    configuration: Configuration,
    currentUser?: CurrentUser,
}

const EditGeneralSettingsDevMode = (props: EditGeneralSettingsProps) => {
    const styles = {
        dataClusterCard: {
            background: props.generalSettings.secondaryColor,
            color: props.generalSettings.secondaryBGTextColor,
            borderRadius: "4px"
        },
        primaryBlock: {
            background: props.generalSettings.backgroundColor,
            color: props.generalSettings.textColor
        },
        secondaryBlock: {
            background: props.generalSettings.secondaryColor,
            color: props.generalSettings.secondaryBGTextColor
        },
        separator: {
            borderTopColor: props.generalSettings.textColor
        }
    };

    const navigate = useNavigate();

    const [status, setStatus] = useState('');
    const [status2, setStatus2] = useState('');
    const [status3, setStatus3] = useState('');
    const pdfUrl = `/cdr-policy/${props.configuration.principal_id}/${props.generalSettings.cdrPolicyPDF}`;
    const [PdfData, setPdfData]: any = useState();

    const customTheme = ThemeBuilder(
        {
            theme: 'Custom',
            generalSettings: props.generalSettings
        }
    );

    const handleGeneralSettingsChange = (newGeneralSettings: GeneralSettings, newFileUpload?: UploadFile) => {
        props.onGeneralSettingsChange && props.onGeneralSettingsChange(newGeneralSettings, newFileUpload);
    }

    const handleEnhancedRedirectionSettingsChange = (newEnhancedRedirectionSettings: EnhancedRedirectionSettings) => {
        handleGeneralSettingsChange({
            ...props.generalSettings,
            enhancedRedirection: newEnhancedRedirectionSettings
        });
    }

    const logoUrl = `/principal-logos/${props.configurationPrincipalId}/${props.generalSettings.cdrUploadLogo}`;
    const [logoSrc, setLogoSrc] = useState('');

    const headerBgUrl = `/header-backgrounds/${props.configurationPrincipalId}/${props.generalSettings.headerBackgroundImage}`;
    const [headerBgSrc, setHeaderBgSrc] = useState('');

    const iconUrl = `/accreditation-icons/${props.configurationPrincipalId}/${props.generalSettings.accreditationUploadIcon}`;
    const [iconSrc, setIconSrc] = useState('');

    //eslint-disable-next-line
    const getFile = useCallback(props.adminObApi.getFile, []);
    useEffect(() => {
        (async (logoUrl) => {
            if (props.generalSettings.cdrUploadLogo && props.generalSettings.cdrUploadLogo !== '') {
                try {
                    const data = await getFile(logoUrl);
                    setLogoSrc(URL.createObjectURL(data));
                    setStatus('loaded');
                } catch (error) {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }
        })(logoUrl);
        //eslint-disable-next-line
    }, [status]);

    useEffect(() => {
        (async (headerBgUrl) => {
            if (props.generalSettings.headerBackgroundImage && props.generalSettings.headerBackgroundImage !== '') {
                try {
                    const data = await getFile(headerBgUrl);
                    setHeaderBgSrc(URL.createObjectURL(data));
                    setStatus2('loaded');
                } catch (error) {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }
        })(headerBgUrl);
        //eslint-disable-next-line
    }, [status2]);

    useEffect(() => {
        (async (iconUrl) => {
            if (props.generalSettings.accreditationUploadIcon && props.generalSettings.accreditationUploadIcon !== '') {
                try {
                    const data = await getFile(iconUrl);
                    setIconSrc(URL.createObjectURL(data));
                    setStatus3('loaded');
                } catch (error) {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }
        })(iconUrl);
        //eslint-disable-next-line
    }, [status3]);


    //eslint-disable-next-line
    const generateApiKey = useCallback(props.adminObApi.generatePrincipalApiKey, []);
    const handleGenerateApiKey = () => {
        (async () => {
            try {
                const newApiKey = await generateApiKey(props.configurationPrincipalId);
                handleGeneralSettingsChange({
                    ...props.generalSettings,
                    principalUuid: newApiKey.new_principal_api_key,
                })
            } catch (error) {
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }


    let statementDeliveryEmailValidationError: any = null;
    let statementDeliveryEndpointValidationError: any = null;
    let consentExpiryNotificationEndpointValidationError: any = null;
    let reportingDeliveryEmailValidationError: any = null;

    useEffect(() => {
        if (PdfData) {
            let blob = new Blob([PdfData], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.setAttribute('download', 'cdr-policy.pdf');
            link.href = window.URL.createObjectURL(blob);
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }, [PdfData]);

    const handleShowCdrPolicyPDF = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        (async(logoUrl) => {
            try {
                const data = await getFile(logoUrl);
                setLogoSrc(URL.createObjectURL(data));
            } catch (error) {
                console.log(error);
            }
        })(logoUrl);

        (async(pdfUrl) => {
            try {
                const data = await getFile(pdfUrl);
                setPdfData(data);
            } catch (error) {
                console.log(error);
            }
        })(pdfUrl);
    };

    return <>
        <form className={"admin-form general-settings-form"} autoComplete={"off"}>
            <div className={"mt-50"}>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>Principal’s Details</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Name<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Principal_name.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {sanitize(props.generalSettings.principalName)}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>ADR Name<LightTooltip
                                    title={"ADR Name"}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {sanitize(props.generalSettings.adrName)}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Reference Code<LightTooltip
                                    title={"Principal’s Reference Code"}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {sanitize(props.generalSettings.referenceCode)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>Legal Details</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>CDR Policy URL<LightTooltip
                                    title="Consumer data right Policy URL"
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {sanitize(props.generalSettings.cdrPolicyURL)}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>CDR Policy PDF<LightTooltip title="Consumer data right Policy PDF"
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={8} md={5} className={"grid-file-input"}>
                                <div className={"file-item-wrap"}>
                                    <a href={"/#"} onClick={handleShowCdrPolicyPDF}>Download PDF</a>&nbsp;
                                    <a href={"/#"} onClick={handleShowCdrPolicyPDF}><FontAwesomeIcon icon={faDownload} /></a>
                                    {props.generalSettings.cdrPolicyPDFUploadDate && <div className={"date"} style={{paddingLeft:0}}>Uploaded {props.generalSettings.cdrPolicyPDFUploadDate}</div>}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <hr className={"mt-30"} />

            <div className={"mt-50"}>
                <Box mb={5}><h4>General Styling</h4></Box>

                <Box mb={2}><h6>Branding</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Brandmark / brand icon<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Brandmark_brand_icon.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={8} md={5} className={"grid-file-input"}>
                                <div className={"file-item-wrap"}>
                                    <Box className={"details-box"}>
                                        <FileItem type={"image"} name={props.generalSettings.cdrUploadLogo} url={logoSrc} />
                                    </Box>
                                    {props.generalSettings.cdrUploadLogoDate && <div className={"date"}>Uploaded {props.generalSettings.cdrUploadLogoDate}</div>}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={"background-grey default-round-corner"} p={3}>
                            Your brandmark will be used for the Data Request part of the process to visually communicate the exchange of data between your business and another. For best results, please use a <strong>PNG file</strong> with a <strong>transparent background</strong>. Your brandmark should be no larger than 250px by 250px and ideally with an aspect ratio of 1:1.
                        </Box>
                    </Grid>
                </Grid>

                <Box mt={5}><h6>Accreditation Badge</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Accreditation number<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Accredation_number.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {sanitize(props.generalSettings.accreditationNumber)}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Show icon in accreditation badge<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Icon_for_accreditation_badge.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} />
                                </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.showIconInAccreditationBadge ? "Yes" : "No"}
                            </Grid>
                        </Grid>
                        {props.generalSettings.showIconInAccreditationBadge &&
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Icon for accreditation badge<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Icon_for_accreditation_badge.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={8} md={5} className={"grid-file-input"}>
                                    {props.generalSettings.accreditationUploadIcon &&
                                        <div className={"file-item-wrap"}>
                                            <Box className={"details-box"}>
                                                <FileItem type={"image"} name={props.generalSettings.accreditationUploadIcon} url={iconSrc} />
                                            </Box>
                                            {props.generalSettings.accreditationUploadIconDate && <div className={"date"}>Uploaded {props.generalSettings.accreditationUploadIconDate}</div>}
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>

                <Box mt={5} mb={2}><h6>Header</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Header background type<LightTooltip
                                    title={"Header background type"}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.headerBackgroundType || "No background"}
                            </Grid>
                        </Grid>
                        {props.generalSettings.headerBackgroundType === "Solid colour" ?
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Header background colour<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Header_background_colour.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.headerBackgroundColor}
                                </Grid>
                            </Grid>
                            : ""
                        }
                        {props.generalSettings.headerBackgroundType === "Image background" ?
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Header background image<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Header_background_image.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6} className={"grid-file-input"}>
                                    <div className={"file-item-wrap"}>
                                        <Box className={"details-box"}>
                                            <FileItem type={"image"} name={props.generalSettings.headerBackgroundImage} url={headerBgSrc}/>
                                        </Box>
                                        {props.generalSettings.headerBackgroundImageUploadDate && <div className={"date"}>Uploaded {props.generalSettings.headerBackgroundImageUploadDate}</div>}
                                    </div>
                                </Grid>
                            </Grid>
                            : ""
                        }
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                </Grid>

                <Box mt={3} mb={3}><h6>Typefaces</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Font family for headings<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Font_family_for_headings.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.headingFontFamily}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Font family for body text<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Font_family_for_body_text.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.bodyTextFontFamily}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Font family for footer text<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Font_family_for_footer_text.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.footerFontFamily}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={"background-grey default-round-corner"} p={3}>
                            Headings are the text or “labels” that appear at the head of a section or page. The text wrapped in &lt;h1&gt;, &lt;h2&gt; and &lt;h3&gt; tags will be changed affected based on your “Font family for headings” choice.
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                    </Grid>
                </Grid>


                <Box mt={3} mb={3}><h6>Colour system</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Theme type<LightTooltip
                                    title={"Theme type"}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.themeType}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Background colour<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Background_colour.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.backgroundColor}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Body text colour<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Body_text_colour.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.textColor}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Primary colour<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Primary_colour.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.primaryColor ?? LightMode.primaryColor}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Text colour for Primary Background<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Text_colour_for_primary_background.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.primaryBGTextColor ?? LightMode.primaryBGTextColor}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Secondary colour<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Secondary_colour.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.secondaryColor ?? LightMode.secondaryColor}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Text colour for Secondary Background<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Text_colour_for_secondary_background.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.secondaryBGTextColor ?? LightMode.secondaryBGTextColor}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Tertiary colour<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Tertiary_colour.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.tertiaryColor}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Text colour for Tertiary Background<LightTooltip
                                    title={"Text colour for Tertiary Background"}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.tertiaryBGTextColor ?? LightMode.tertiaryBGTextColor}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Success colour<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Success_colour.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.successColor}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Text colour for Success Background<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Text_colour_for_success_background.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.successBGTextColor ?? LightMode.successBGTextColor}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={"background-grey default-round-corner"} mt={7} p={3}>
                            <p>The Primary Colour will be displayed for certain Headings, Primary Actionable Components like Buttons and Links, Table Headings and as the background colour for the feature icons. The Primary Colour will also be applied to the Inverse Button through its border colour and text.</p>

                            <h6 className={"mt-30"}>Example Usage</h6>

                            <Grid className={"pt-20"} container spacing={3}>
                                <Grid item xs={4}>Headings</Grid>
                                <Grid item xs={8}>
                                    <Typography color={"secondary"} className={"heading-example mb-0"}>
                                        Lorem Ipsum Heading Here
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>Buttons</Grid>
                                <Grid item xs={8}>
                                    <img style={{ width: 352 }} src="/images/general-settings-button-examples.png" alt="" />
                                </Grid>
                                <Grid item xs={4}>Links</Grid>
                                <Grid item xs={8} className={"buttons"}>
                                    This is body copy, followed by a <strong className="text-link-example">text link</strong>.
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>


                <Box mt={5} mb={3}><h6>Elements / Components</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Primary button text style<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Primary_button_text_style.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.primaryButtonTextStyle?.toUpperCase()}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Inverse button style<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Inverse_button_style.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.inverseButtonTextStyle?.toUpperCase()}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Inverse button text style<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Inverse_button_text_style.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.inverseButtonStyle?.toUpperCase().replace("transparent", "Transparent")}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Button hover effect<LightTooltip
                                    title="Button hover effect"
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.buttonHoverEffect}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Link hover effect<LightTooltip
                                    title="Button hover effect"
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.linkHoverEffect}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={"background-grey default-round-corner"} p={3}>
                            <p>The Primary button text style refers to the text colour of the Primary Button. The background colour of the Primary Button is inherited from the chosen Primary Colour - see above.</p>

                            <h6 className={"mt-30"} >Example Usage</h6>

                            <Grid className={"pt-20"} container spacing={3}>
                                <Grid item xs={4}>Primary Button</Grid>
                                <Grid item xs={8}>
                                    <img style={{ width: 167 }} src="/images/general-settings-primary-button.png" alt="" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </div>


            <hr className={"mt-30"} />

            <div className={"mt-50"}>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>Insight Settings</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Insight Stage</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.incInsightStage ? "Include" : "Exclude"}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <hr className={"mt-30"} />

            <div className={"mt-50"}>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>API Settings</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Provide Consent Via API<LightTooltip title="Provide Consent Via API" placement="right-start"
                                    arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="provide-consent-via-api"
                                        name="provide-consent-via-api"
                                        value={props.generalSettings.provideConsentViaApi ? props.generalSettings.provideConsentViaApi : 0}
                                        onChange={(event) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                provideConsentViaApi: event.target.value as boolean,
                                            })
                                        }}
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Enable Multibank Sessions<LightTooltip title="Enable Multibank Sessions" placement="right-start"
                                    arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="enable-multibank-sessions"
                                        name="enable-multibank-sessions"
                                        value={props.generalSettings.multibankEnabled ? props.generalSettings.multibankEnabled : 0}
                                        onChange={(event) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                multibankEnabled: event.target.value as boolean,
                                            })
                                        }}
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>API Key<LightTooltip title="Principal’s API Key" placement="right-start"
                                    arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    disabled
                                    required
                                    variant="outlined"
                                    name="api"
                                    className="w-100"
                                    value={props.generalSettings.principalUuid}
                                />
                                <div><a href={"/#"} onClick={(e) => { e.preventDefault(); handleGenerateApiKey(); }}>Generate New API Key</a>&nbsp;</div>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Statement Delivery Email
                                    <LightTooltip title="Please specify one or more email addresses each separated by semi-colon as a delimiter, e.g. test1@test.com.au; test2@test.com.au; ...." placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="statement-delivery-email"
                                    className="w-100"
                                    value={props.generalSettings.statementDeliveryEmail}
                                    onChange={(event) => {
                                        statementDeliveryEmailValidationError.innerHTML = "";
                                        let emails = event.target.value;
                                        statementDeliveryEmailValidationError.innerHTML = "";
                                        if (emails !== "") {
                                            let invalidEmails = validateIfTargetsAreValidEmails(emails);
                                            if (invalidEmails.length > 0) {
                                                statementDeliveryEmailValidationError.innerHTML = "You have entered at least one invalid email addresses: " + invalidEmails.join("; ");
                                            }
                                        }
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            statementDeliveryEmail: emails,
                                        })
                                    }}
                                    ref={props.statementDeliveryEmailRef}
                                />
                                <div className="mandatory-fields-hint" ref={(message) => { statementDeliveryEmailValidationError = message; }}></div>
                            </Grid>
                        </Grid>

                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Schema Version<LightTooltip title="Schema Version" placement="right-start"
                                    arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="schema-version"
                                        name="schema-version"
                                        value={props.generalSettings.schemaVersion ? props.generalSettings.schemaVersion : '20210803'}
                                        onChange={(event) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                schemaVersion: event.target.value as string,
                                            })
                                        }}
                                    >
                                        <MenuItem value={'20210803'}>20210803</MenuItem>
                                        <MenuItem value={'20230101'}>20230101</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Raw Data Export Format<LightTooltip title="Raw Data Export Format" placement="right-start"
                                    arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="raw-data-export-format"
                                        name="raw-data-export-format"
                                        value={props.generalSettings.dataExportFormat ? props.generalSettings.dataExportFormat : ''}
                                        onChange={(event) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                dataExportFormat: event.target.value as string,
                                            })
                                        }}
                                    >
                                        <MenuItem value={'xml'}>XML</MenuItem>
                                        <MenuItem value={'json'}>JSON</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Statement Output Format<LightTooltip title="Statement Output Format" placement="right-start"
                                    arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="statement-output-format"
                                        name="statement-output-format"
                                        value={props.generalSettings.statementExportFormat ? props.generalSettings.statementExportFormat : ''}
                                        onChange={(event) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                statementExportFormat: event.target.value as string,
                                            })
                                        }}
                                    >
                                        <MenuItem value={'pdf'}>PDF Only</MenuItem>
                                        <MenuItem value={'html'}>HTML Only</MenuItem>
                                        <MenuItem value={'pdf & html'}>PDF & HTML</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Statement Transaction Period (Days)<LightTooltip title="Statement Transaction Period (Days)"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="statement-transaction-period-days"
                                    className="w-100"
                                    value={props.generalSettings.statementTransactionPeriodDays}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            statementTransactionPeriodDays: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Statement Delivery Endpoint
                                    <LightTooltip title="Please specify one or more URLs each separated by semi-colon as a delimiter, e.g. https://test1.com.au; https://test2.com.au; ...." placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="statement-delivery-endpoint"
                                    className="w-100"
                                    value={props.generalSettings.statementDeliveryEndpoint}
                                    onChange={(event) => {
                                        statementDeliveryEndpointValidationError.innerHTML = "";
                                        let urls = event.target.value;
                                        if (urls !== "") {
                                            let invalidUrls = validateIfTargetsAreValidUrls(urls);
                                            if (invalidUrls.length > 0) {
                                                statementDeliveryEndpointValidationError.innerHTML = "You have entered at least one invalid endpoint URL: " + invalidUrls.join("; ");
                                            }
                                        }
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            statementDeliveryEndpoint: urls,
                                        })
                                    }}
                                    ref={props.statementDeliveryEndpointRef}
                                />
                                <div className="mandatory-fields-hint" ref={(message) => { statementDeliveryEndpointValidationError = message; }}></div>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Consent Expiry Notification Endpoint
                                    <LightTooltip title="Please specify one or more URLs each separated by semi-colon as a delimiter, e.g. https://test1.com.au; https://test2.com.au; ...." placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="statement-delivery-endpoint"
                                    className="w-100"
                                    value={props.generalSettings.consentExpiryNotificationEndpoint}
                                    onChange={(event) => {
                                        consentExpiryNotificationEndpointValidationError.innerHTML = "";
                                        let urls = event.target.value;
                                        if (urls !== "") {
                                            let invalidUrls = validateIfTargetsAreValidUrls(urls);
                                            if (invalidUrls.length > 0) {
                                                consentExpiryNotificationEndpointValidationError.innerHTML = "You have entered at least one invalid endpoint URL: " + invalidUrls.join("; ");
                                            }
                                        }
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            consentExpiryNotificationEndpoint: event.target.value,
                                        })
                                    }}
                                    ref={props.consentExpiryEndpointRef}
                                />
                                <div className="mandatory-fields-hint" ref={(message) => { consentExpiryNotificationEndpointValidationError = message; }}></div>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>JS Post Message Domain URL
                                    <LightTooltip title="This specifies the domain for a JS Post Message - it should be set to the domain (e.g. http://yourdomain.com) of your application from which the JS Post message data from the Consent Flow will be sent to the parent frame"
                                        placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cdr-policy-url"
                                    className="w-100"
                                    value={props.generalSettings.callbackURL}
                                    placeholder="JS Post Message Domain URL"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            callbackURL: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Reporting Delivery Email
                                    <LightTooltip title="Please specify Reporting Delivery Email" placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cdr-policy-url"
                                    className="w-100"
                                    value={props.generalSettings.reportingDeliveryEmail}
                                    placeholder="Reporting Delivery Email"
                                    onChange={(event) => {
                                        reportingDeliveryEmailValidationError.innerHTML = "";
                                        let emails = event.target.value;
                                        reportingDeliveryEmailValidationError.innerHTML = "";
                                        if (emails !== "") {
                                            let invalidEmails = validateIfTargetsAreValidEmails(emails);
                                            if (invalidEmails.length > 0) {
                                                reportingDeliveryEmailValidationError.innerHTML = "You have entered at least one invalid email addresses: " + invalidEmails.join("; ");
                                            }
                                        }
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            reportingDeliveryEmail: event.target.value as string
                                        })
                                    }}
                                />
                                <div className="mandatory-fields-hint" ref={(message) => { reportingDeliveryEmailValidationError = message; }}></div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Box mt={5} mb={3}><h6>Consent Email Notifications</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Send Consent Expiry Email?<LightTooltip title="Send Consent Expiry Email?"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="send-consent-expiry-email"
                                        name="send-consent-expiry-email"
                                        value={props.generalSettings.sendConsentExpiryEmail ? props.generalSettings.sendConsentExpiryEmail : 0}
                                        onChange={(event) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                sendConsentExpiryEmail: event.target.value as boolean,
                                            })
                                        }}
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Consent Expiry Email Text<LightTooltip title="Consent Expiry Email Text"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="consent-expiry-email-text"
                                    className="w-100"
                                    multiline={true}
                                    value={props.generalSettings.consentExpiryEmailText}
                                    placeholder="Consent Expiry email main text"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            consentExpiryEmailText: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Box mt={5} mb={3}><h6>Consent API Webhook Notifications</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Consent Confirmation URL<LightTooltip title="Consent Confirmation URL"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="consent-confirmation-url"
                                    className="w-100"
                                    value={props.generalSettings.consentConfirmationPdfURL}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            consentConfirmationPdfURL: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Consent Withdrawal URL<LightTooltip title="Consent Withdrawal URL"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="consent-withdrawal-url"
                                    className="w-100"
                                    value={props.generalSettings.consentRevocationPdfURL}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            consentRevocationPdfURL: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Recurring Consent Reminder URL<LightTooltip title="Recurring Consent Reminder URL"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="recurring-consent-reminder-URL"
                                    className="w-100"
                                    value={props.generalSettings.consentRecurringNotificationURL}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            consentRecurringNotificationURL: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Consent Expiry URL<LightTooltip title="Consent Expiry URL"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="consent-expiry-url"
                                    className="w-100"
                                    value={props.generalSettings.consentExpiryPdfURL}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            consentExpiryPdfURL: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <hr className={"mt-30"} />

            <div className={"mt-50"}>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>Redirection Settings</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Success Redirect URL
                                    <LightTooltip title="This specifies a URL for the Consent Flow to redirect back to after the successful completion of the Consent Flow. It provided it will automatically triger to redirect to the specifid location after 5 seconds"
                                        placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cdr-policy-url"
                                    className="w-100"
                                    value={props.generalSettings.redirectURL}
                                    placeholder="Success Redirect URL"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            redirectURL: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Redirect After Seconds
                                    <LightTooltip title="Automatically triger to redirect to the specifid location after setup number of seconds"
                                                  placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cdr-policy-url"
                                    className="w-100"
                                    value={props.generalSettings.redirectAfterSeconds}
                                    placeholder="Redirect After Seconds"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            redirectAfterSeconds: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Box mt={5} mb={3}><h6>Cancellation Settings</h6></Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Show Cancel Button
                                    <LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/pre-consent/Cancel_button_cdr.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchWithLabel
                                    checked={props.generalSettings.includeCancelButtonAndPopUp}
                                    onChange={value => handleGeneralSettingsChange({ ...props.generalSettings, includeCancelButtonAndPopUp: value })}
                                >
                                    {props.generalSettings.includeCancelButtonAndPopUp ? "Include" : "Exclude"}
                                </SwitchWithLabel>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Cancellation Redirect URL
                                    <LightTooltip title="This specifies a URL for the Consent Flow to redirect back to after the user cancels the Consent Flow. If provided, the redirect will be triggered to this URL after the user confirms cancellation."
                                        placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cancellation-redirect-url"
                                    className="w-100"
                                    value={props.generalSettings.cancellationRedirectURL}
                                    placeholder="Cancellation Redirect URL"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            cancellationRedirectURL: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Cancellation Modal Heading
                                    <LightTooltip title="Cancel modal heading" placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cancel-modal-heading"
                                    className="w-100"
                                    value={props.generalSettings.cancelModalPopUpHeading}
                                    placeholder="Cancel modal heading text"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            cancelModalPopUpHeading: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Cancellation Modal Text
                                    <LightTooltip title="Cancel modal text" placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cancel-modal-text"
                                    className="w-100"
                                    multiline={true}
                                    value={props.generalSettings.cancelModalPopUpMainText}
                                    placeholder="Cancel modal main text"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            cancelModalPopUpMainText: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Box mt={5} mb={3}><h6>Enhanced Redirection Settings</h6></Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Enable for success</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchWithLabel
                                    checked={props.generalSettings.enhancedRedirection?.enableForSuccess || false}
                                    onChange={value => handleEnhancedRedirectionSettingsChange({ ...props.generalSettings.enhancedRedirection, enableForSuccess: value })}
                                >
                                    {props.generalSettings.enhancedRedirection?.enableForSuccess ? "Include" : "Exclude"}
                                </SwitchWithLabel>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Enable for cancellation</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchWithLabel
                                    checked={props.generalSettings.enhancedRedirection?.enableForCancellation || false}
                                    onChange={value => handleEnhancedRedirectionSettingsChange({ ...props.generalSettings.enhancedRedirection, enableForCancellation: value })}
                                >
                                    {props.generalSettings.enhancedRedirection?.enableForCancellation ? "Include" : "Exclude"}
                                </SwitchWithLabel>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Client ID</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="enhanced-redirection-client-id"
                                    className="w-100"
                                    value={props.generalSettings.enhancedRedirection?.clientId}
                                    placeholder="Client ID"
                                    onChange={(event) => {
                                        handleEnhancedRedirectionSettingsChange({
                                            ...props.generalSettings.enhancedRedirection,
                                            clientId: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Client Secret</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="enhanced-redirection-client-secret"
                                    className="w-100"
                                    value={props.generalSettings.enhancedRedirection?.clientSecret}
                                    placeholder="Client Secret"
                                    onChange={(event) => {
                                        handleEnhancedRedirectionSettingsChange({
                                            ...props.generalSettings.enhancedRedirection,
                                            clientSecret: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Client credentials API endpoint URL</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="enhanced-redirection-client-credentials-endpoint"
                                    className="w-100"
                                    value={props.generalSettings.enhancedRedirection?.clientCredentialsEndpoint}
                                    placeholder="Client credentials API endpoint URL"
                                    onChange={(event) => {
                                        handleEnhancedRedirectionSettingsChange({
                                            ...props.generalSettings.enhancedRedirection,
                                            clientCredentialsEndpoint: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>JWT generation API endpoint URL</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="enhanced-redirection-jwt-generation-endpoint"
                                    className="w-100"
                                    value={props.generalSettings.enhancedRedirection?.jwtGenerationEndpoint}
                                    placeholder="JWT generation API endpoint URL"
                                    onChange={(event) => {
                                        handleEnhancedRedirectionSettingsChange({
                                            ...props.generalSettings.enhancedRedirection,
                                            jwtGenerationEndpoint: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <hr className={"mt-30"} />

            <div className={"mt-50"}>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>Additional Settings</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Banks Overridden <br />For CDR Data Retrieval<LightTooltip title="Override CDR data retrieval"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ul>
                                    {props.generalSettings.cdrRetrievalOverridedDataHolders?.map(el => <li>{el.name}</li>)}
                                </ul>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <hr className={"mt-30"} />

            <div className={"mt-50"}>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>Direct Data Capture Settings</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>BankFeeds API key</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {sanitize(props.generalSettings.directDataCaptureAPIKey)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <div className={"mt-50"}>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>External Data Pipelines</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Enhanced Categorisation</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.enhancedCategorisation ? "Yes" : "No"}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>iTRS Score</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.itrsScore ? "Yes" : "No"}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Categorisation Improvement</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.categorisationImprovement ? "Yes" : "No"}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Spend Analytics</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {props.generalSettings.spendAnalytics ? "Yes" : "No"}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </form>

        {/* Preview popup */}
        <Popup
            key={"preview-popup"}
            maxWidth={"md"}
            open={props.previewSettings}
            withCloseButton
            onClose={props.onClosePreviewSettings}
        >
            <ThemeProvider<Theme> theme={customTheme}>
                <Box component={"div"} className={"pt-20 pb-30"} sx={styles.primaryBlock}>
                    <Container>
                        <h5><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />&nbsp; Lorem ipsum dolor sit amet</h5>
                        <Divider component={"hr"} className={"dashed"} sx={styles.separator} />
                        <Box mt={4}>
                            <h2><strong>Lorem ipsum dolor sit amet</strong></h2>
                            <p>Hasellus vitae accumsan lectus. Cras eget porta tortor, a accumsan nibh. Maecenas fringilla in velit eget facilisis.</p>
                            <AccreditationBadge
                                companyName={"Your business name"}
                                accreditationNumber={"123456"}
                                displayStyle={2}
                            />

                            <h3><FontAwesomeIcon icon={faAngleRight} /> Pellentesque tristique dolor enim</h3>
                            <p>Hasellus vitae accumsan lectus. Cras eget porta tortor, a accumsan nibh. Maecenas fringilla in velit eget facilisis.</p>

                            <Box component={"div"} className={"highlight-block data-request-card"} sx={styles.dataClusterCard}>
                                <label>
                                    <SwitchWithLabel checked={true}>
                                        <Typography component={"span"} color={"inherit"} className="typo-display">
                                            <h5>Mauris ligula tortor</h5>
                                            <p className="mandatory-field-indicator">&nbsp;*</p>
                                        </Typography>
                                    </SwitchWithLabel>
                                </label>
                                <h6>Why we need it</h6>
                                <p>Vestibulum ante ipsum</p>
                            </Box>

                            <Box component={"div"} className={"highlight-block data-request-card"} sx={styles.dataClusterCard}>
                                <label>
                                    <SwitchWithLabel checked={false}>
                                        <Typography component={"span"} className="typo-display">
                                            <h5>Pellentesque habitant morbi</h5>
                                        </Typography>
                                    </SwitchWithLabel>
                                </label>
                                <h6>Why we need it</h6>
                                <p>Duis rhoncus lectus</p>
                            </Box>
                        </Box>
                    </Container>
                </Box>

                <Box component={"div"} className={"secondary-block"} sx={styles.secondaryBlock}>
                    <Container>
                        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
                        <div className={"content"}>
                            <Container maxWidth={"sm"} className={"p-0"}>
                                <p>Aliquam accumsan ultricies leo, sed maximus nunc mollis vel. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                            </Container>
                        </div>
                        <div className={"cta"}>
                            <ButtonBlock>
                                <Button variant={"outlined"} color={"secondary"}>Secondary action</Button>
                                <Button variant={"contained"} color={"secondary"}>Primary action</Button>
                            </ButtonBlock>
                        </div>
                    </Container>
                </Box>
            </ThemeProvider>
        </Popup>

    </>;
}

export default EditGeneralSettingsDevMode;
