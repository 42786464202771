import React from "react";

import { Container } from "@mui/material";

import CancelButton from "../components/CancelButton";
import Header from "../components/Header";
import PrimaryBlock from "../components/PrimaryBlock";
import GeneralSettings from "../models/GeneralSettings";
import PreConsent from "../models/PreConsent";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";

interface UnauthorisedProps {
    basePath: string;
    api: OpenBankingPlatformAPI;
    generalSettingsConfig?: GeneralSettings;
    preConsentConfig?: PreConsent;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
}

const Unauthorised = (props: UnauthorisedProps) => {
    return <>
        <div className={"page-top"}>
            <Header
                generalSettings={props.generalSettingsConfig}
                principalLogoUrl={props.principalLogoUrl}
                headerBgImageUrl={props.headerBgImageUrl}
            />
            <main>
                <PrimaryBlock>
                    <Container maxWidth="sm" className="success-confirmation">
                        <h2>Unauthorised Access</h2>
                        <div>
                            <p>You do have the required privileges for accessing this application.</p>
                        </div>
                        <div className="text-align-center">
                            <CancelButton
                                basePath={props.basePath}
                                type='error'
                                api={props.api}
                                generalSettingsConfig={props.generalSettingsConfig}
                                showConfirmation={false}
                            >Exit</CancelButton>
                        </div>
                    </Container>
                </PrimaryBlock>
            </main>
        </div>
    </>;
}

export default Unauthorised;