import React from "react";

import { Grid, useTheme } from "@mui/material";
import {sanitize} from "../helpers/HtmlSanitizerHelper";
import Broker from "../admin/models/Broker";

interface AccreditationBadgeProps {
    companyName?: string;
    iconUrl?: string;
    showIcon?: boolean;
    accreditationNumber?: string;
    cdrLogoUrl?: string;
    displayStyle?: 1 | 2;
    isTrustedAdviser?: boolean;
    broker?: Broker;
}

const AccreditationBadge = (props: AccreditationBadgeProps) => {
    const theme = useTheme();
    const style = {
        style1: {
            background: theme.palette.info.main,
            color: theme.palette.info.contrastText
        },
        style2: {
            background: "none",
            "&:before": {
                background: theme.palette.secondary.main,
            }
        }
    };

    return <Grid container className={`accreditation-badge ${props.displayStyle ? "style-" + props.displayStyle : ""}`} sx={props.displayStyle === 2 ? style.style2 : style.style1}>
        <Grid item lg={props.cdrLogoUrl ? 8 : 12} xs={12} className="align-self-center">
            {props.iconUrl && props.showIcon
                ? <img style={{maxWidth: "100%", height: 25, marginBottom: 7}} src={props.iconUrl} alt={props.companyName} />
                : <h5><strong>{sanitize(props.companyName)}</strong></h5>
            }
            {props.isTrustedAdviser ?
                <p>{sanitize(props.accreditationNumber)}</p> :
                <p>
                    Accredited Data Recipient: <a href={"https://www.cdr.gov.au/find-a-provider?provider=" + sanitize(props.accreditationNumber)} target="_blank" rel="noopener noreferrer">{sanitize(props.accreditationNumber)}</a>
                </p>
            }
        </Grid>
        {props.cdrLogoUrl && <Grid item lg={4} xs={12}>
            <img style={{width: "100%", maxWidth: 240}} src={props.cdrLogoUrl} alt="" />
        </Grid>}
    </Grid>;
}

export default AccreditationBadge;
