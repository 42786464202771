import React, { useCallback, useEffect, useState } from "react";
import { Navigate, Route, Routes as RouterSwitch, useLocation, useNavigate } from "react-router-dom";

import { Container } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import {
    isClientManager,
    isClientManagerOrStandardUser,
    isDeveloper,
    isStandardUser,
    isAdmin, isAdminOrClientManager,
    isMortgageBroker, isAdminOrMortgageBroker
} from "../../helpers/AuthUserHelper";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPI from "../../openbankingplatform/OpenBankingPlatformAPI";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import store from "../../store/store";
import AdminFooter from "../components/AdminFooter";
import AdminHeader from "../components/AdminHeader";
import UserLogin from "../models/UserLogin";
import AccountsList from "./AccountsList";
import AdminHome from "./AdminHome";
import AdminLogin from "./AdminLogin";
import AppError from "./AppError";
import BillingModelConfiguration from "./BillingModelConfiguration";
import CdrToDdcBankMappingsList from "./CdrToDdcBankMappingsList";
import CdrToDdcBankMappingView from "./CdrToDdcBankMappingView";
import ConsentsList from "./ConsentsList";
import EditSystemSettings from "./EditSystemSettings";
import ErrorLogsList from "./ErrorLogsList";
import InvoicingDetails from "./InvoicingDetails";
import InvoicingList from "./InvoicingList";
import PrincipalConfiguration from "./PrincipalConfiguration";
import ResetPassword from "./ResetPassword";
import SendResetPasswordEmail from "./SendResetPasswordEmail";
import UpdateAccount from "./UpdateAccount";
import UpdateUser from "./UpdateUser";
import UsersList from "./UsersList";
import BrokersList from "./BrokersList";
import UpdateBroker from "./UpdateBroker";
import ViewConsent from "./ViewConsent";
import NotFound from "./NotFound";
import AdminBrokerAggregatorHome from "./AdminBrokerAggregatorHome";
import MtlsCertificatesList from "./MtlsCertificatesList";
import MtlsCertificatesView from "./MtlsCertificatesView";
import CdrConsumerReporting from "./CdrConsumerReporting";
import CdrClientsCertificatesList from "./CdrClientsCertificatesList";
import CdrClientsCertificatesView from "./CdrClientsCertificatesView";

interface AdminState
{
    backgroundOperationInProgress: boolean;
    loggedIn: boolean;
    resetInfo?: {
        url?: string;
        token?: string;
        email?: string;
        reason?: string;
    };
    isLoading: boolean;
    loginStatusMessage: string;
    loginError: boolean;
    loginErrorMessage: string;
    adminObApi: OpenBankingPlatformAPIInterface;
    loginResponse?: {
        status?: string;
        qrCode?: string;
        secret?: string;
    };
    mfaResponse?: {
        status?: string;
        error?: string;
    }
}

interface CurrentUserState
{
    currentUser?: CurrentUser;
}

interface AdminProps
{
    basePath: string;
    api: any;
}

const Admin = (props: AdminProps) => {
    const apiBaseUrl = process.env.REACT_APP_API_DOMAIN;
    if (!apiBaseUrl) {
        throw Error("REACT_APP_API_DOMAIN is not defined in the configuration");
    }
    const adminApiUrl = process.env.REACT_APP_ADMIN_API_URL;
    if (!adminApiUrl) {
        throw Error("REACT_APP_ADMIN_API_URL is not defined in the configuration");
    }
    const storedAdminToken = sessionStorage.getItem('storedAdminTokenString') ?? ''; //This need to be implemented as jwt token and store
    const sessionStateString = sessionStorage.getItem('sessionState');
    const sessionState = sessionStateString ? JSON.parse(sessionStateString) : null;
    const isLoggedIn = sessionState ? sessionState.isLoggedIn : false;

    const [state, setState] = useState<AdminState>({
        backgroundOperationInProgress: false,
        loggedIn: isLoggedIn,
        isLoading: true,
        loginStatusMessage: '',
        loginError: false,
        loginErrorMessage: '',
        adminObApi: new OpenBankingPlatformAPI(new URL(adminApiUrl), storedAdminToken),
    });

    const [user, setCurrentUser] = useState<CurrentUserState>({
    })

    const location = useLocation();
    useEffect(() => {
        const hideTabBar = () => {
            setState(state => ({
                ...state,
                tabs: undefined,
            }))
        }
        if (!location.pathname.includes("configuration")) {
            hideTabBar();
        }

    }, [location.pathname])

    const loginPath = props.basePath + '/login';
    const navigate = useNavigate();

    //To check if the token is valid and authenticated
    // eslint-disable-next-line
    useEffect(() => {
        // @ts-ignore
        if (location.state && location.state.loggedIn) {
            // @ts-ignore
            const loggedIn = location.state.loggedIn;
            setState({
                ...state,
                loggedIn: loggedIn,
            });
        }

        // @ts-ignore
        if (location.state && location.state.currentUser) {
            // @ts-ignore
            const userData = location.state.currentUser as CurrentUser;
            setCurrentUser((currentUser) => ({
                ...currentUser,
                currentUser: userData,
            }));
        }

// @ts-ignore
        if (location.state && location.state.errorMessage) {
            // @ts-ignore
            const loginErrorMessage = location.state.errorMessage;
            setState({
                ...state,
                loginError: true,
                loginErrorMessage: loginErrorMessage,
                loginResponse: {
                    status: 'login'
                }
            });
        }

        // @ts-ignore
        if (location.state && location.state.loginStatusMessage) {
            // @ts-ignore
            const loginStatusMessage = location.state.loginStatusMessage;
            setState({
                ...state,
                loginError: false,
                loginStatusMessage: loginStatusMessage
            });
        }


        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        setState((state) => ({
            ...state,
            isLoading: false,
        }));
        if (!state.loggedIn) {
            if (location.pathname.includes("/forgot-password")) {
                navigate(props.basePath + "/forgot-password");
            } else if (location.pathname.includes("/reset-password")) {
                navigate(location.pathname, {
                    state: {
                        params: location.search
                    }
                })
            } else{
                navigate(loginPath);
            }
        } else {
            (async() => {
                try {
                    const validatedUser = await validateUser()
                    setCurrentUser((currentUser) => ({
                        ...currentUser,
                        currentUser: validatedUser,
                    }));
                } catch (error) {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath)
                }
            })();
        }
        // eslint-disable-next-line
    }, [state.loggedIn]);


    useEffect(() => {
        setState((state) => ({
            ...state,
            isLoading: false,
        }));
        if (!state.loggedIn && state.resetInfo) {

                navigate(props.basePath +"/reset-password/"+state.resetInfo.token, {
                    state: {
                        params: state.resetInfo
                    }
                });
                //navigate(props.basePath + "/reset-password/"+state.reset_token);
           
        } 
        // eslint-disable-next-line
    }, [state.resetInfo]);

    const redirectUserOnLogin = () => {
        if (store.getState().currentUser) {
            var currentUser = store.getState().currentUser as unknown as CurrentUser;
            if (isAdmin(currentUser.role_id) || isMortgageBroker(currentUser.role_id)) {
                navigate(props.basePath + "/home", {
                    state: {
                        currentUser: currentUser
                    }
                })
            } else if (isClientManager(currentUser.role_id)) {
                navigate(props.basePath + "/account", {
                    state: {
                        currentUser: currentUser
                    }
                })
            } else if (isStandardUser(currentUser.role_id) || isDeveloper(currentUser.role_id)) {
                navigate(props.basePath + "/account", {
                    state: {
                        currentUser: currentUser
                    }
                })
            }
            /*
            if (isSuperAdmin(currentUser.role_id)) {
                navigate(props.basePath + "/home", {
                    state: {
                        currentUser: currentUser
                    }
                })
            } else if (isClientManagerOrStandardUser(currentUser.role_id)) {
                navigate(props.basePath + "/configuration", {
                    state: {
                        currentUser: currentUser
                    }
                })
            }*/
        }
    }

    const getLoginRouteRedirect = () => {
        if (user.currentUser?.id) {
            if (state.loggedIn) {
                if (isAdmin(user.currentUser?.role_id)) {
                    return <Navigate to={props.basePath + "/home"} />
                } else if (isClientManager(user.currentUser?.role_id)) {
                    return <Navigate to={props.basePath + "/account"} />;
                } else if (isStandardUser(user.currentUser?.role_id)) {
                    return <Navigate to={props.basePath + "/account"}/>;
                } else if (isDeveloper(user.currentUser?.role_id)) {
                    return <Navigate to={props.basePath + "/account"}/>;
                } else if (isMortgageBroker(user.currentUser?.role_id)) {
                    return <Navigate to={props.basePath + "/home"}/>;
                }
                /*
                if (isSuperAdmin(user.currentUser?.role_id)) {
                    return <Navigate to={props.basePath + "/home"} />
                } else if (isClientManager(user.currentUser?.role_id) || isStandardUser(user.currentUser?.role_id)) {
                    return <Navigate to={props.basePath + "/configuration"}/>;
                }*/
            }
            return <Navigate to={props.basePath + "/login"}/>
        }
        return <Navigate to={props.basePath + "/login"}/>
    }

    //eslint-disable-next-line
    const getCsrfToken = useCallback(state.adminObApi.getCsrfToken, []);
    // eslint-disable-next-line
    const doAdminLogin = useCallback(state.adminObApi.doAdminLogin, []);
    // eslint-disable-next-line
    const validateUser = useCallback(state.adminObApi.validateUser, []);
    // eslint-disable-next-line
    const doAdminMfaLogin = useCallback(state.adminObApi.doAdminMfaLogin, []);

    const handleLoginSuccess = () => {
        (async() => {
            const userData = await validateUser();
            store.dispatch({
                type: 'login',
                currentUser: userData
            });
            setCurrentUser((currentUser) => ({
                ...currentUser,
                currentUser: userData,
            }));
            setState({
                ...state,
                loggedIn: true,
                isLoading: false,
                loginErrorMessage: ''
            });

            sessionStorage.setItem('sessionState', JSON.stringify({
                isLoggedIn: true
            }));
            sessionStorage.setItem('brokersCSVImportFailedAttempts', process.env.REACT_APP_CSV_IMPORT_FAILED_ATTEMPTS || '2');
            redirectUserOnLogin();
        })();
    }

    const handleLogin = (loginCredentials: UserLogin) => {
        // TODO: add authentication mechanisms here to interact with the back end and establish the session
        (async () => {
            try {
                setState({
                    ...state,
                    isLoading: true,
                });
                await getCsrfToken();
                const loginRequest = await doAdminLogin(loginCredentials);

                if(loginRequest.status !== 'success'){
                    if(loginRequest.reset_token){
                        setState({
                            ...state,
                            loggedIn: false,
                            isLoading: false,
                            resetInfo:{url:loginRequest.reset_url,token:loginRequest.reset_token,email:loginRequest.reset_email,reason:loginRequest.reset_reason}
                        });
                   }else{
                        setState({
                            ...state,
                            isLoading: false,
                            loginResponse: loginRequest,
                        });
                   }
                }else{
                    handleLoginSuccess();
                }
            } catch (error) {
                let errorMessage = 'Unable to login - invalid email or password provided.';
                if((error as any).response.status === 429){
                    errorMessage = (error as any).response.data.errors;
                }
                
                setState({
                    ...state,
                    loggedIn: false,
                    isLoading: false
                });
                navigate(props.basePath + '/login', {
                    state: {
                        errorMessage: errorMessage,
                    }
                });
                sessionStorage.removeItem('sessionState');
                /*
                setState((state) => ({
                    ...state,
                    isLoading: false,
                    loginError: true,
                }));
                navigate(loginPath);*/
                //adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath)

            }
        })();

    }

    const handleMfaSubmit = (mfaCode: string) => {
        (async() => {
            try {
                setState({
                    ...state,
                    isLoading: true,
                });

                const mfaLoginResponse = await doAdminMfaLogin(mfaCode);
                if(mfaLoginResponse.status !== 'success'){
                    setState({
                        ...state,
                        isLoading: false,
                        mfaResponse: mfaLoginResponse
                    });
                }else{
                    handleLoginSuccess();
                }
            } catch (error) {
                setState({
                    ...state,
                    isLoading: false,
                    mfaResponse: {
                        status: 'error',
                        error: (error as any).response.data.errors
                    }
                })
            }
        })();
    }

    //To handle logout
    // eslint-disable-next-line
    const doAdminLogout = useCallback(state.adminObApi.doAdminLogout, []);
    const handleLogout = () => {
        // TODO: clear the session and log out the user
        // TODO: add authentication mechanisms here to interact with the back end and establish the session
        (async () => {
            try {
                setState({
                    ...state,
                    loginErrorMessage: '',
                    loginResponse: undefined,
                    mfaResponse: undefined,
                });
                await doAdminLogout();
                setState({
                    ...state,
                    loggedIn: false,
                    loginError: false,
                    loginErrorMessage: '',
                    loginResponse: undefined,
                    mfaResponse: undefined,
                })
                setCurrentUser((currentUser) => ({
                    ...currentUser,
                    currentUser: undefined,
                }));
                sessionStorage.removeItem('sessionState');
            } catch (error) {
                setState((state) => ({
                    ...state,
                    isLoading: false,
                    loginError: true,
                }));
            }
        })();
        //Remove stored token
        sessionStorage.removeItem("storedAdminTokenString");
        // Return the user back to the login screen
        navigate(props.basePath + "/login");
    }

    if (state.isLoading) {
        return <LoadingSpinner position={"fixed"} overlay />
    }

    return <>
        <AdminHeader
            apiBaseUrl={apiBaseUrl}
            loggedIn={state.loggedIn}
            onLogout={handleLogout}
            showMenu={state.loggedIn}
            basePath={props.basePath}
            showNavigation={location.pathname !== loginPath}
            currentUser={user.currentUser}
        />

        <Container className={"admin-content"}>
            {(state.backgroundOperationInProgress)
                ? <>Saving...</>
                : <RouterSwitch>
                    <Route path="login" element={
                        <AdminLogin
                            onLogin={handleLogin}
                            onMfaSubmit={handleMfaSubmit}
                            basePath={props.basePath}
                            loginStatusMessage={state.loginStatusMessage}
                            loginError={state.loginError}
                            loginErrorMessage={state.loginErrorMessage}
                            loginResponse={state.loginResponse}
                            mfaResponse={state.mfaResponse}
                        />
                    } />

                    <Route path="forgot-password" element={
                        <SendResetPasswordEmail
                            adminObApi={new OpenBankingPlatformAPI(new URL(apiBaseUrl + '/password-recovery'), '')}
                            //adminObApi={state.adminObApi}
                            basePath={props.basePath}
                        />
                    } />

                    <Route path="reset-password/:token" element={
                        <ResetPassword
                            adminObApi={new OpenBankingPlatformAPI(new URL(apiBaseUrl + '/password-recovery'), '')}
                            basePath={props.basePath}
                        />
                    } />

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path={"/*"} element={
                        <AdminHome
                            usersListPath={props.basePath + "/users-list"}
                            accountsListPath={props.basePath + "/accounts-list"}
                            consentsListPath={props.basePath + "/consents-list"}
                            invoicingListPath={props.basePath + "/invoicing-list"}
                            errorLogsListPath={props.basePath + "/error-logs-list"}
                            cdrToDdcMappingsPath={props.basePath + "/cdr-to-ddc-bank-mappings"}
                            systemSettingsPath={props.basePath + "/system-settings"}
                            documentationPath={apiBaseUrl + "/api/documentation"}
                            brokersListPath={props.basePath + "/brokers-list"}
                            mtlsCertificatesListPath={props.basePath + "/mtls-certificates-list"}
                            cdrReportsPath={props.basePath + "/cdr-consumer-reporting"}
                            cdrClientsCertificatesListPath={props.basePath + "/cdr-clients-certificates-list"}
                        />
                    } /> }

                    {(isClientManagerOrStandardUser(user.currentUser?.role_id)) &&
                    <Route path={"/*"} element={
                        <UpdateAccount adminObApi={state.adminObApi}
                                       basePath={props.basePath}
                                       currentUser={user.currentUser}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path={"home"} element={
                        <AdminHome
                            usersListPath={props.basePath + "/users-list"}
                            accountsListPath={props.basePath + "/accounts-list"}
                            consentsListPath={props.basePath + "/consents-list"}
                            invoicingListPath={props.basePath + "/invoicing-list"}
                            errorLogsListPath={props.basePath + "/error-logs-list"}
                            cdrToDdcMappingsPath={props.basePath + "/cdr-to-ddc-bank-mappings"}
                            systemSettingsPath={props.basePath + "/system-settings"}
                            documentationPath={apiBaseUrl + "/api/documentation"}
                            brokersListPath={props.basePath + "/brokers-list"}
                            mtlsCertificatesListPath={props.basePath + "/mtls-certificates-list"}
                            cdrReportsPath={props.basePath + "/cdr-consumer-reporting"}
                            cdrClientsCertificatesListPath={props.basePath + "/cdr-clients-certificates-list"}
                        />
                    } /> }

                    {(isMortgageBroker(user.currentUser?.role_id)) &&
                    <Route path={"home"} element={
                        <AdminBrokerAggregatorHome
                            errorLogsListPath={props.basePath + "/error-logs-list"}
                            brokersListPath={props.basePath + "/brokers-list"}
                        />
                    } /> }

                    {(isAdminOrClientManager(user.currentUser?.role_id)) &&
                    <Route path="users-list" element={
                        <UsersList
                            api={state.adminObApi}
                            userDetailsPath={props.basePath + "/user"}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                        />
                    } /> }

                    {(isAdminOrClientManager(user.currentUser?.role_id)) &&
                    <Route path="user/*" element={
                        <UpdateUser
                            api={state.adminObApi}
                            basePath={props.basePath}
                            userUpdateError={false}
                            currentUser={user.currentUser}
                        />
                    } /> }

                    {(isAdminOrClientManager(user.currentUser?.role_id)) &&
                    <Route path="user/:id" element={
                        <UpdateUser
                            api={state.adminObApi}
                            basePath={props.basePath}
                            userUpdateError={false}
                            currentUser={user.currentUser}
                        />
                    } /> }

                    {(isAdminOrMortgageBroker(user.currentUser?.role_id)) &&
                    <Route path="brokers-list" element={
                        <BrokersList
                            api={state.adminObApi}
                            brokerDetailsPath={props.basePath + "/broker"}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                        />
                    } /> }

                    {(isAdminOrMortgageBroker(user.currentUser?.role_id)) &&
                    <Route path="broker/*" element={
                        <UpdateBroker
                            api={state.adminObApi}
                            basePath={props.basePath}
                            userUpdateError={false}
                            currentUser={user.currentUser}
                        />
                    } /> }

                    {(isAdminOrMortgageBroker(user.currentUser?.role_id)) &&
                    <Route path="broker/:id" element={
                        <UpdateBroker
                            api={state.adminObApi}
                            basePath={props.basePath}
                            userUpdateError={false}
                            currentUser={user.currentUser}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="accounts-list" element={
                        <AccountsList
                            api={state.adminObApi}
                            basePath={props.basePath}
                            accountDetailsPath={props.basePath + "/account"}
                            currentUser={user.currentUser}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="consents-list" element={
                        <ConsentsList
                            api={state.adminObApi}
                            basePath={props.basePath}
                            consentDetailsPath={props.basePath + "/consent"}
                            currentUser={user.currentUser}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="consent/:id" element={
                        <ViewConsent
                            adminObApi={state.adminObApi}
                            basePath={props.basePath}
                        />
                    } /> }

                    <Route path="account/*" element={
                        <UpdateAccount adminObApi={state.adminObApi}
                                       basePath={props.basePath}
                                       currentUser={user.currentUser}
                        />
                    } />

                    {/* This is redundant */}
                    {/* {(isAdmin(user.currentUser?.role_id)) &&
                        <Route path="account/:id" element={
                            <UpdateAccount adminObApi={state.adminObApi}
                                           basePath={props.basePath}
                                           currentUser={user.currentUser}
                            />
                        }/>
                    } */}

                    {(isAdmin(user.currentUser?.role_id)) &&
                        <Route path="account/:id/*" element={
                            <UpdateAccount adminObApi={state.adminObApi}
                                           basePath={props.basePath}
                                           currentUser={user.currentUser}
                            />
                        }/>
                    }

                    <Route path="configuration" element={
                        <PrincipalConfiguration
                            adminObApi={state.adminObApi}
                            basePath={props.basePath}
                            currentUser={user.currentUser}
                        />
                    } />

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="error-logs-list" element={
                        <ErrorLogsList
                            api={state.adminObApi}
                            userDetailsPath={props.basePath + "/user"}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="invoicing-list" element={
                        <InvoicingList
                            api={state.adminObApi}
                            basePath={props.basePath}
                            currentUser={user.currentUser}
                            invoicingDetailsPath={props.basePath + "/invoicing"}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="invoicing/:id" element={
                        <InvoicingDetails
                            api={state.adminObApi}
                            basePath={props.basePath}
                            currentUser={user.currentUser}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="billing-configuration/:id" element={
                        <BillingModelConfiguration
                            api={state.adminObApi}
                            basePath={props.basePath}
                            currentUser={user.currentUser}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="cdr-to-ddc-bank-mappings" element={
                        <CdrToDdcBankMappingsList
                            api={state.adminObApi}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                            detailsPath={props.basePath + "/cdr-to-ddc-bank-mapping"}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="cdr-to-ddc-bank-mapping/*" element={
                        <CdrToDdcBankMappingView
                            api={state.adminObApi}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="cdr-to-ddc-bank-mapping/:id" element={
                        <CdrToDdcBankMappingView
                            api={state.adminObApi}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                        />
                    } /> }

                    <Route path="system-settings" element={
                        <EditSystemSettings
                            api={state.adminObApi}
                            userDetailsPath={props.basePath + "/user"}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                        />
                    } />

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="mtls-certificates-list" element={
                        <MtlsCertificatesList
                            api={state.adminObApi}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                            detailsPath={props.basePath + "/mtls-certificates"}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="mtls-certificates/*" element={
                        <MtlsCertificatesView
                            api={state.adminObApi}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="mtls-certificates/:id" element={
                        <MtlsCertificatesView
                            api={state.adminObApi}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="/cdr-consumer-reporting" element={
                        <CdrConsumerReporting
                            api={state.adminObApi}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="cdr-clients-certificates-list" element={
                        <CdrClientsCertificatesList
                            api={state.adminObApi}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                            detailsPath={props.basePath + "/cdr-clients-certificates"}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="cdr-clients-certificates/*" element={
                        <CdrClientsCertificatesView
                            api={state.adminObApi}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                        />
                    } /> }

                    {(isAdmin(user.currentUser?.role_id)) &&
                    <Route path="cdr-clients-certificates/:id" element={
                        <CdrClientsCertificatesView
                            api={state.adminObApi}
                            currentUser={user.currentUser}
                            basePath={props.basePath}
                        />
                    } /> }

                    <Route path="error" element={
                        <AppError />
                    } />

                    <Route path={"/"} element={
                        <>
                            { user.currentUser?.id && getLoginRouteRedirect() }
                        </>
                    } />

                    {(user.currentUser) &&
                    <Route path="*" element={
                        <NotFound />
                    } /> }
                </RouterSwitch>
            }
        </Container>

        <AdminFooter/>
    </>
}

export default Admin;