import React from "react";
import Moment from "react-moment";
import { convertFromRaw, Editor, EditorState } from "draft-js";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from "@mui/material";

import ConsentListItemData from "../models/ConsentListItemData";
import DataCluster from "../openbankingplatform/models/DataCluster";
import {sanitize} from "../helpers/HtmlSanitizerHelper";
import PreConsent from "../models/PreConsent";
import Consent from "../models/Consent";
import GeneralSettings from "../models/GeneralSettings";

interface ConsentDataClusterProps extends DataCluster {
    principalName?: string;
    sharingStartAt?: string;
    initialSharingStartAt?: string;
    sharingEndAt?: string;
    consentDetails?: ConsentListItemData;
    richDescription?: string;
    snapShotPreConsentData?: PreConsent;
    snapShotConsentData?: Consent;
    generalSettings?: GeneralSettings;
}

const ConsentDataCluster = (props: ConsentDataClusterProps) => {
    const theme = useTheme();
    const style = {
        styledAccordion: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText
        },
        accordionHeading : {
            color: theme.palette.info.contrastText
        }
    };
    
    const dataList = props.data_list;

    const richDescription = (props.richDescription && <>
        <Editor editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(props.richDescription)))} readOnly={true} onChange={() => {}}/>
    </>)

    return <Accordion className={"styled-accordion"} sx={style.styledAccordion}>
        <Typography component={"div"}>
            <AccordionSummary expandIcon={<KeyboardArrowDownRoundedIcon sx={style.accordionHeading} />}>{sanitize(props.title)}</AccordionSummary>
        </Typography>
        <AccordionDetails>
            <Box>
                <h6>Why we need it</h6>
                {props.snapShotConsentData && <p>{
                    props.snapShotConsentData?.dataClustersWithOptions.find((dataCluster) => {
                        return dataCluster.identifier === props.identifier;
                    })?.purposeInformation
                }</p>}
                {richDescription}
            </Box>
            {!(dataList.length === 1 && dataList[0] === "") && <>
            <Box mt={2}>
                <h6>What we are collecting</h6>
                <ul>
                    {dataList.map(function(name, index) {
                        return <li key={index}>{name}</li>
                    })}
                </ul>
            </Box>
            </>}

            {(props.identifier === 'data_cluster_10') ?
                <Box mt={2}>
                    <h6>Historical data we have collected</h6>
                    <p>We have collected transaction data that may date back to 01 January 2017.</p>
                </Box> : ""
            }

            <Box mt={2}>
                <h6>When we have collected your data</h6>
                <p>We first collected your {sanitize(props.title.toLowerCase())} from {sanitize(props.consentDetails?.institution_name)} on <Moment format="DD MMM YYYY">{props.initialSharingStartAt}</Moment>
                </p>
                <p>
                    {(props.consentDetails?.sharing_period !== '0') ?
                        <>We will continue to collect this every time you use {sanitize(props.principalName)} until <Moment format="DD MMM YYYY">{props.sharingEndAt}</Moment></> :
                        <>This consent was for once-off use only.</>
                    }
                </p>
            </Box>
        </AccordionDetails>
    </Accordion>

}

export default ConsentDataCluster;