import * as EmailValidator from "email-validator";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoIcon from "@mui/icons-material/Info";
import {Button, FormControl, Grid, Link, MenuItem, Select, TextField} from "@mui/material";

import {isAdmin} from "../../helpers/AuthUserHelper";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import LightTooltip from "../components/LightTooltip";
import Account from "../models/Account";
import Principal from "../models/Principal";
import UpdateBrokerData from "../models/UpdateBrokerData";
import LoadingSpinner from "../../components/LoadingSpinner";
import Configuration from "../models/Configuration";
import {sanitize} from "../../helpers/HtmlSanitizerHelper";


interface UpdateUserProps {
    basePath: string;
    api: OpenBankingPlatformAPIInterface;
    userUpdateError: boolean;
    currentUser?: CurrentUser;
}

interface UpdateBrokerState {
    backgroundOperationInProgress: boolean;
    principalsListLoading: boolean;
    accounts: Array<Account>;
    principals: Array<Principal>;
    configurations: Array<Configuration>;
    updateMessage: string;
}

const UpdateBroker = (props: UpdateUserProps) => {

    const { id } = useParams<{ id: any }>();

    const [broker, setBroker] = useState<UpdateBrokerData>({
        id: 0,
        first_name: '',
        last_name: '',
        type: 'residential mortgage broker',
        sub_type: 'ACL',
        acl_credit_license_number: '',
        acl_credit_license_holder_name: '',
        acl_abn: '',
        acr_credit_representative_number: '',
        acr_credit_license_number: '',
        acr_credit_representative_name: '',
        acr_abn: '',
        errorLicOrRepNumberMessage: '',
        errorCodeMessage: '',
        codeIsValid: isAdmin(props.currentUser?.role_id) ? 1 : 0
    });

    const [state, setState] = useState<UpdateBrokerState>({
        backgroundOperationInProgress: false,
        principalsListLoading: false,
        accounts: [],
        principals: [],
        configurations: [],
        updateMessage: '',
    })

    const navigate = useNavigate();

    //eslint-disable-next-line
    const getBrokerDetails = useCallback(props.api.getBrokerById, []);
    useEffect(() => {
        (async () => {
            if (id) {
                try {
                    const loadBroker = await getBrokerDetails(id);
                    setBroker((brokerDetails)=>({
                        ...brokerDetails,
                        id: id,
                        ...loadBroker,
                        entered_code: '',
                    }))
                    setState({
                        ...state,
                        backgroundOperationInProgress: false
                    });
                } catch (error) {
                    /*
                    // TODO Uncomment this code when fallback is removed
                    setUser((userDetails)=>({
                        ...userDetails,
                        apiHasReturnedAnError: true,
                    }))
                    navigate("/token-error");*/
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            } else {
                // setState({
                //     ...state,
                //     backgroundOperationInProgress: false
                // });
            }
        })();
        },
        //eslint-disable-next-line
        []
    )

    //eslint-disable-next-line
    const getAccounts = useCallback(props.api.getTrustedAdviserAccountsList, []);
    useEffect(() => {
            (async () => {
                try {
                    if (isAdmin(props.currentUser?.role_id)) {
                        setState((state)=>({
                            ...state,
                            backgroundOperationInProgress: true,
                        }));
                        const loadedAccounts = await getAccounts();
                        setState((state)=>({
                            ...state,
                            backgroundOperationInProgress: false,
                            accounts: loadedAccounts,
                        }))
                    } else {
                        setBroker((brokerDetails)=>({
                            ...brokerDetails,
                            account_id: "" + props.currentUser?.account_id
                        }))
                    }
                } catch (error) {
                    /*
                    // TODO Uncomment this code when fallback is removed
                    setState((state)=>({
                        ...state,
                        apiHasReturnedAnError: true,
                    }))
                    navigate("/token-error");*/
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            })();
        },
        //eslint-disable-next-line
        []
    )

    // eslint-disable-next-line
    const getPrincipals = useCallback(props.api.getPrincipalsByAccountId, []);
    useEffect(() => {
        (async () => {
            const account_id: number = broker ? Number(broker.account_id) : 0;
            if (account_id) {
                try {
                    setState((state)=>({
                        ...state,
                        principalsListLoading: true,
                        principal_id: 0
                    }));
                    const loadedPrincipals = await getPrincipals(account_id);
                    setState((state)=>({
                        ...state,
                        principalsListLoading: false,
                        principals: loadedPrincipals
                    }))
                } catch (error) {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            } else {
                setState((state)=>({
                    ...state,
                    principals: []
                }));
            }
        })();
    },
    //eslint-disable-next-line
    [broker.account_id]);

    const location = useLocation();
    useEffect(() => {
        if (location.state) {
            // @ts-ignore
            if (location.state.message) {
                // @ts-ignore
                const updateMessage = location.state.message;
                setState((state)=>({
                    ...state,
                    updateMessage: updateMessage
                }))
            }
            // @ts-ignore
            if (location.state.userDetails) {
                // @ts-ignore
                setBroker((brokerDetails)=>({
                    ...brokerDetails
                }))
                setState((state)=>({
                    ...state,
                    backgroundOperationInProgress: false
                }))
            }
        }
    }, [location, id]);

    const shouldRenderAccountSelection = ():boolean => {
        return isAdmin(props.currentUser?.role_id);
    }


    const handleGoBack = () => {
        navigate(`${props.basePath}/brokers-list`);
    };

    const allASICFieldsArePopulated = (): boolean=> {
        if (broker.sub_type === 'ACL') {
            return (broker.acl_credit_license_number !== ''  && broker.acl_abn !== '');
        } else if (broker.sub_type === 'ACR') {
            return (broker.acr_credit_representative_number !== '' /*&& broker.acr_credit_license_number !== '' && broker.acr_abn !== ''*/);
        }
        return false;
    }

    const allMandatoryFieldsArePopulated =
        broker.first_name !== ''
        && broker.last_name !== ''
        && broker.email !== ''
        && broker.account_id !== ''
        && broker.principal_id !== ''
        && broker.mobile_number !== ''
        && broker.type !== ''
        //&& broker.business_phone_number !== ''
        //&& broker.address !== ''
        && broker.sub_type !== ''
        /*&& ((broker.sub_type === 'ACL' && broker.acl_credit_license_number !== '' && broker.acl_abn !== '') ||
        (broker.sub_type === 'ACR' && broker.acr_credit_representative_number !== '' && broker.acr_credit_license_number !== ''  && broker.acr_abn !== ''))*/
        && allASICFieldsArePopulated()
        && broker.code !== ''
        && broker.codeIsValid === 1;

    const shouldRenderACLDetails = ():boolean => {
        if (broker) {
            return (broker.sub_type === "ACL");
        }
        return false;
    }

    const shouldRenderACRDetails = ():boolean => {
        if (broker) {
            return (broker.sub_type === "ACR");
        }
        return false;
    }

    //eslint-disable-next-line
    const getCreditLicenseeOrRepresentativeName = useCallback(props.api.getAsicCreditLicenseeOrRepresentative, []);
    const handleLicenseeNumberBlur = (e: any) => {
        (async () => {
            const creditLicenseeName = await getCreditLicenseeOrRepresentativeName('ACL', e.target.value);
            setBroker((broker)=>({
                ...broker,
                acl_credit_license_holder_name: creditLicenseeName.data.cred_lic_name
            }));
        })();
    }

    const handleRepresentativeNumberBlur = (e: any) => {
        (async () => {
            const creditRepresentativeName = await getCreditLicenseeOrRepresentativeName('ACR', e.target.value);
            if (creditRepresentativeName.data) {
                setBroker((broker)=>({
                    ...broker,
                    acr_credit_representative_name: creditRepresentativeName.data.cred_rep_name
                }));
            }
        })();
    }

    //eslint-disable-next-line
    const isNewBrokerCodeUnique = useCallback(props.api.isNewBrokerCodeUnique, []);
    const handleCodeChange = (e: any) => {
        setBroker((broker)=>({
            ...broker,
            code: sanitize(e.target.value)
        }));
    }
    const handleCodeBlur = (e: any) => {
        (async () => {
            const isCodeUniqueResponse = await isNewBrokerCodeUnique(e.target.value, broker.id);
            setBroker((broker)=>({
                ...broker,
                /*code: isCodeUniqueResponse.total === 0 ? broker.code : '',*/
                errorCodeMessage: isCodeUniqueResponse.total === 0 ? "" : isCodeUniqueResponse.message,
                codeIsValid: isCodeUniqueResponse.total === 0 ? 1 : 0
            }));
        })();
    }

    //eslint-disable-next-line
    const createNewBroker = useCallback(props.api.doBrokerSignUp, []);
    //eslint-disable-next-line
    const updateExistingBroker = useCallback(props.api.doBrokerUpdate, []);
    const handleBrokerUpdate = () => {

        setState({
            ...state,
            backgroundOperationInProgress: true,
        });

        //Save and send to server
        (async () => {
            try {
                if (broker.id) {
                    const updatedBrokerDetails = await updateExistingBroker(broker);
                    setBroker((broker)=>({
                        ...broker,
                        errorMessage: "",
                    }))
                    setState({
                        ...state,
                        backgroundOperationInProgress: false
                    })
                    navigate(props.basePath + `/broker/` + id, {
                        state: {
                            message: "Broker's details successfully updated.",
                            userDetails: updatedBrokerDetails
                        }
                    });
                } else {
                    const newBroker = await createNewBroker(broker);
                    setBroker((broker)=>({
                        ...broker,
                        errorMessage: "",
                    }))
                    setState({
                        ...state,
                        backgroundOperationInProgress: false
                    })
                    navigate(props.basePath + `/broker/` + newBroker.id, {
                        state: {
                            message: "New broker successfully created.",
                            brokerDetails: newBroker
                        }
                    });
                }
            } catch (error) {
                setState({
                    ...state,
                    backgroundOperationInProgress: false
                })
                if ((error as any).response.status === 422) {
                    // @ts-ignore
                    var errorMessageArray = [];
                    var allErrors = (error as any).response.data.errors;
                    for (var i = 0; i < allErrors.length; i++) {
                        var errors = allErrors[i];
                        if ('name' in errors) {
                            errorMessageArray.push(errors.password);
                        }
                        if ('email' in errors) {
                            errorMessageArray.push(errors.password);
                        }
                        if ('password' in errors) {
                            errorMessageArray.push(errors.password);
                        }
                        if ('error' in errors) {
                            errorMessageArray.push(errors.error);
                        }
                    }

                    setBroker((broker)=>({
                        ...broker,
                        // @ts-ignore
                        errorMessage: "Failed to " + (id ? 'update' : 'create') + " trusted adviser - " + errorMessageArray.join(", "),
                    }))
                } else {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }
        })();
    }

    const userCreateErrorMsg = broker.errorMessage ? broker.errorMessage : '';

    const validateEmail = (newEmail?: string) => {
        if (newEmail) {
            return EmailValidator.validate(newEmail);
        }
        return false;
    }
    const hasValidEmail = validateEmail(broker.email);

    let emailValidationError: any = null;
    const setEmail = (newEmail: string) => {
        setBroker((broker)=>({
            ...broker,
            email: newEmail
        }))
        emailValidationError.innerHTML = "";
        if (newEmail !== "") {
            if (!validateEmail(newEmail)) {
                emailValidationError.innerHTML = "Please enter a valid email address";
            }
        }
    }

    return <div>
                {(state.backgroundOperationInProgress)
                    ? <LoadingSpinner loadingText={""} />
                    : <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                            </Grid>
                        </Grid>
                        <div className="update-user">
                            <h2><strong>{id?'Update Trusted Adviser Details':'Create New Trusted Adviser Details'}</strong></h2>

                        {(broker.errorMessage !== '') ? <span className={"error-text"}>{userCreateErrorMsg}</span> : ""}
                        {(state.updateMessage !== '') ? <span className={"update-message-text"}>{state.updateMessage}</span> : ""}

                        <br /><br />

                        <form className={"admin-form user-settings-form"} autoComplete={"off"}>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label className="required">First Name<LightTooltip title="Broker's First Name" placement="right-start"
                                                              arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="First Name"
                                        value={broker.first_name ? broker.first_name : ''}
                                        onChange={(event) => {
                                            setBroker({
                                                ...broker,
                                                first_name: sanitize(event.target.value)
                                            })
                                        }}/>
                                </Grid>
                            </Grid>

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label className="required">Last Name<LightTooltip title="Broker's Last Name" placement="right-start"
                                                              arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Last Name"
                                        value={broker.last_name ? broker.last_name : ''}
                                        onChange={(event) => {
                                            setBroker({
                                                ...broker,
                                                last_name: sanitize(event.target.value)
                                            })
                                        }}/>
                                </Grid>
                            </Grid>

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Email<LightTooltip title="Broker's Email" placement="right-start"
                                                              arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Email"
                                        value={broker.email ? broker.email : ''}
                                        onChange={(event) => {
                                            setEmail(sanitize(event.target.value as string))
                                        }}/>
                                        <div className="mandatory-fields-hint" ref={(message) => { emailValidationError = message; }}></div>
                                </Grid>
                            </Grid>

                            {shouldRenderAccountSelection() &&
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Account</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                            id="name-input"
                                            name="name-input"
                                            value={broker.account_id ? broker.account_id : '0'}
                                            onChange={(event) => {
                                                setBroker({
                                                    ...broker,
                                                    account_id: event.target.value
                                                })
                                            }}>
                                            <MenuItem value={0}>- Please select -</MenuItem>
                                            {state.accounts.map((item, i) => (
                                                <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>}

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Principal</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <FormControl variant="outlined" className="w-100">
                                        {state.principalsListLoading ? <>
                                            <div>please wait...</div>
                                        </> : <Select
                                            id="name-input"
                                            name="name-input"
                                            value={broker.principal_id ? broker.principal_id : '0'}
                                            onChange={(event) => {
                                                setBroker({
                                                    ...broker,
                                                    principal_id: event.target.value
                                                })
                                            }}>
                                            <MenuItem value={0}>- Please select -</MenuItem>
                                            {state.principals.map((item, i) => (
                                                <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>}
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label className="required">Mobile Number<LightTooltip title="Broker's Mobile Number" placement="right-start"
                                                              arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Mobile Number"
                                        value={broker.mobile_number ? broker.mobile_number : ''}
                                        onChange={(event) => {
                                            setBroker({
                                                ...broker,
                                                mobile_number: sanitize(event.target.value)
                                            })
                                        }}/>
                                </Grid>
                            </Grid>

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Type of Trusted Adviser</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                            id="name-input"
                                            name="name-input"
                                            value={broker.type ? broker.type : 'mortgage broker'}
                                            onChange={(event) => {
                                                setBroker({
                                                    ...broker,
                                                    type: event.target.value
                                                })
                                            }}>
                                                {/* <MenuItem value={"accountant"}>Accountant</MenuItem>
                                                <MenuItem value={"lawyer"}>Lawyer</MenuItem>
                                                <MenuItem value={"tax practitioner"}>Tax Practitioner</MenuItem>
                                                <MenuItem value={"BAS agent"}>BAS Agent</MenuItem>
                                                <MenuItem value={"financial adviser/counsellor"}>Financial Adviser/Counsellor</MenuItem> */}
                                                <MenuItem value={"residential mortgage broker"}>Mortgage Broker</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Business Phone Number</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                <TextField
                                    variant="outlined"
                                    id="name-input"
                                    name="name-input"
                                    className="w-100"
                                    placeholder="Business Phone Number"
                                    value={broker.business_phone_number ? broker.business_phone_number : ''}
                                    onChange={(event) => {
                                        setBroker({
                                            ...broker,
                                            business_phone_number: sanitize(event.target.value)
                                        })
                                    }}/>
                                </Grid>
                            </Grid>

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Legal Address</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Legal Address"
                                        value={broker.address ? broker.address : ''}
                                        onChange={(event) => {
                                            setBroker({
                                                ...broker,
                                                address: sanitize(event.target.value)
                                            })
                                        }}/>
                                    </Grid>
                            </Grid>

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Sub-type of Mortgage Brokers</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                            id="name-input"
                                            name="name-input"
                                            value={broker.sub_type ? broker.sub_type : 'ACL'}
                                            onChange={(event) => {
                                                setBroker({
                                                    ...broker,
                                                    sub_type: event.target.value
                                                })
                                            }}>
                                            <MenuItem value={"ACL"}>ACL</MenuItem>
                                            <MenuItem value={"ACR"}>ACR</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {shouldRenderACLDetails() ?
                            <>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Credit License Number</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Credit License Number"
                                        value={broker.acl_credit_license_number ? broker.acl_credit_license_number : ''}
                                        onChange={(event) => {
                                            setBroker({
                                                ...broker,
                                                acl_credit_license_number: event.target.value
                                            })
                                        }}
                                        onBlur={handleLicenseeNumberBlur}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Credit License Holder Name</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Credit License Holder Name"
                                        value={broker.acl_credit_license_holder_name ? broker.acl_credit_license_holder_name : ''}
                                        onChange={(event) => {
                                            setBroker({
                                                ...broker,
                                                acl_credit_license_holder_name: sanitize(event.target.value)
                                            })
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>ABN</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="ABN"
                                        value={broker.acl_abn ? broker.acl_abn : ''}
                                        onChange={(event) => {
                                            setBroker({
                                                ...broker,
                                                acl_abn: sanitize(event.target.value)
                                            })
                                        }}/>
                                </Grid>
                            </Grid>
                            </>
                             : ""}

                            {shouldRenderACRDetails() ? 
                            <>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Credit Representative Number</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Credit Representative Number"
                                        value={broker.acr_credit_representative_number ? broker.acr_credit_representative_number : ''}
                                        onChange={(event) => {
                                            setBroker({
                                                ...broker,
                                                acr_credit_representative_number: sanitize(event.target.value)
                                            })
                                        }}
                                        onBlur={handleRepresentativeNumberBlur}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Credit Representative Name</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Credit Representative Name"
                                        value={broker.acr_credit_representative_name ? broker.acr_credit_representative_name : ''}
                                        onChange={(event) => {
                                            setBroker({
                                                ...broker,
                                                acr_credit_representative_name: sanitize(event.target.value)
                                            })
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Credit License Number</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Credit License Holder Name"
                                        value={broker.acr_credit_license_number ? broker.acr_credit_license_number : ''}
                                        onChange={(event) => {
                                            setBroker({
                                                ...broker,
                                                acr_credit_license_number: sanitize(event.target.value)
                                            })
                                        }}/>
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>ABN</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="ABN"
                                        value={broker.acr_abn ? broker.acr_abn : ''}
                                        onChange={(event) => {
                                            setBroker({
                                                ...broker,
                                                acr_abn: sanitize(event.target.value)
                                            })
                                        }}/>
                                </Grid>
                            </Grid>
                            </>
                             : ""}

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Trusted Adviser Code</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder=""
                                        value={broker.code ? broker.code : ''}
                                        onChange={handleCodeChange}
                                        onBlur={handleCodeBlur}
                                        />
                                        {(broker.errorCodeMessage !== '') ? <span className={"error-text"}>{broker.errorCodeMessage}</span> : ""}
                                </Grid>
                            </Grid>

                            {(broker.existing_code) ?
                                <Grid container className={"form-group"}>
                                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                        <label>Current Consent Link</label>
                                    </Grid>
                                    <Grid item xs={12} sm={7} md={5}>
                                        <a href={process.env.REACT_APP_TRUSTED_ADVISERS_BASE_PATH + "/" + broker.existing_code} target="_blank" rel="noreferrer">
                                            {window.location.origin + process.env.REACT_APP_TRUSTED_ADVISERS_BASE_PATH + "/" + broker.existing_code}
                                        </a>
                                    </Grid>
                                </Grid> : ""
                            }

                            <Button variant={"contained"} color={"secondary"} onClick={handleBrokerUpdate}
                                    disabled={state.principalsListLoading || !allMandatoryFieldsArePopulated || !hasValidEmail}>Save</Button>
                        </form>
                    </div>
                    </div>
                }
    </div>
}

export default UpdateBroker;