import React, { PropsWithChildren, useState } from "react";
import { MACRO_DATA_HOLDER_NAME } from "../helpers/PreconsentInsightHelper";

import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import HighlightBlock from "./HighlightBlock";
import SwitchWithLabel from "./SwitchWithLabel";
import EditableRichText from "../admin/components/EditableRichText";

interface DataInsightCardProps
{
    insightName: string,
    dataHolderName?: string,
    insightInformation?: string | JSX.Element,
    clusterPermissionNames?: Array<string>,
    onSelectionChange?: (newState: boolean) => void;
    isSelected: boolean;
    isNotValid?: boolean;
    howWasThisInsightGeneratedText: string;
    children?: React.ReactNode;
    notValidErrorMessage?: string;
}

const DataInsightCard = (props: PropsWithChildren<DataInsightCardProps>) => {
    const handleChange = (checked: boolean) => {
        if (props.onSelectionChange) {
            props.onSelectionChange(checked);
        }
    }

    const [isOpen, setState] = useState(false);
    const toggle = () => {
        const newState = !isOpen;
        setState(newState);
    }

    const information = (props.insightInformation as string).replace(MACRO_DATA_HOLDER_NAME, (props?.dataHolderName || ''));
    const howWasThisInsightGeneratedText = props.howWasThisInsightGeneratedText.replace(MACRO_DATA_HOLDER_NAME, (props?.dataHolderName || ''));

    return <HighlightBlock className="data-request-card">
        <label>
            <SwitchWithLabel checked={props.isSelected} onChange={handleChange}>
                <Typography component={"span"} color={"inherit"} className="typo-display">
                    <h5>{props.insightName}</h5>    
                </Typography>
            </SwitchWithLabel>
        </label>
        {props.isNotValid && <p className="mandatory-fields-info">
            {props.notValidErrorMessage}
        </p>}
        {props.insightInformation && <>
        <p>We used your data to generate the insights:</p>
            <EditableRichText editMode={false} value={information} onChange={() => {}} />
        </>}
        {props.children}
        <div className={"data-list-container"}>
            <Typography color={"inherit"} className={"data-list-toggle"} onClick={() => toggle()}>
                <>How was this insight generated? <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} size={"lg"}/></>
            </Typography>
            <ul className={"data-list" + ((isOpen) ? " visible" : " hidden")} style={{paddingLeft:"0"}}>
                {howWasThisInsightGeneratedText}
            </ul>
        </div>
    </HighlightBlock>;
}

export default DataInsightCard;
