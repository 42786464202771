import React, { useEffect, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";

import ButtonBlock from "../../components/ButtonBlock";
import SwitchWithLabel from "../../components/SwitchWithLabel";
import AdditionalUsesOfData from "../../models/AdditionalUsesOfData";
import Consent from "../../models/Consent";
import CurrentUser from "../../models/CurrentUser";
import CustomAdditionalUsesOfData from "../../models/CustomAdditionalUsesOfData";
import DataClusterOptionSet from "../../models/DataClusterOptionSet";
import Configuration from "../models/Configuration";
import EditableText from "./EditableText";
import LightTooltip from "./LightTooltip";
import { validateIfTargetsAreValidEmails } from "../../helpers/InputFieldValidationHelper";
import EditableRichText from "./EditableRichText";
import {convertToRaw, EditorState} from "draft-js";

interface EditConsentSettingsProps {
    basePath: string,
    configuration: Configuration,
    mode: "edit" | "view",
    onConfigurationUpdate?: (newConfiguration: Configuration, mode: 'view' | 'edit') => void,
    onCancel?: () => void,
    currentUser?: CurrentUser,
}

interface ConsentSettingsState
{
    consentCopy: Consent;
}

const ConfigurationConsentSettings = (props: EditConsentSettingsProps) => {
    const editMode = (props.mode === "edit");
    const bgClass = editMode ? "background-grey" : "";

    const [state, setState] = useState<ConsentSettingsState>({
        consentCopy: { ...props.configuration.consent },
    });

    useEffect(() => {
        setState((state) => ({
            ...state,
            consentCopy: { ...props.configuration.consent }
        }))
    }, [props.mode, props.configuration.consent]);


    const setConsentSettings = (newConsent: Consent) => {
        setState({
            ...state,
            consentCopy: newConsent,
        });
    }

    const setConsentAdditionalUsesOfDataSettings = (newAdditionalUsesOfDataSettings: AdditionalUsesOfData) => {
        setConsentSettings({
            ...consent,
            additionalUsesOfData: newAdditionalUsesOfDataSettings
        })
    }

    const setCustomAdditionalUseOfDataSettings = (index: number, customAdditionalUse: CustomAdditionalUsesOfData) => {
        const newCustomValues = [
            ...(consent.additionalUsesOfData?.custom || [])
        ];
        newCustomValues[index] = customAdditionalUse;
        
        setConsentAdditionalUsesOfDataSettings({
            ...consent.additionalUsesOfData,
            custom: newCustomValues,
        })
    }

    const removeCustomAdditionalUseOfDataSetting = (index: number) => {
        const newCustomValues = [
            ...(consent.additionalUsesOfData?.custom || [])
        ]
        newCustomValues.splice(index, 1);

        setConsentAdditionalUsesOfDataSettings({
            ...consent.additionalUsesOfData,
            custom: newCustomValues,
        });
    }

    const setThirdPartiesAdditionalUseOfDataSettings = (index: number, thirdPartiesAdditionalUse: CustomAdditionalUsesOfData) => {
        const newThirdPartiesValues = [
            ...(consent.thirdParties || [])
        ];
        newThirdPartiesValues[index] = thirdPartiesAdditionalUse;
        
        setConsentSettings({
            ...consent,
            thirdParties: newThirdPartiesValues,
        })
    }

    const removeThirdPartiesAdditionalUseOfDataSetting = (index: number) => {
        const newThirdPartiesValues = [
            ...(consent.thirdParties || [])
        ]
        newThirdPartiesValues.splice(index, 1);

        setConsentSettings({
            ...consent,
            thirdParties: newThirdPartiesValues,
        });
    }

    const setDataCluster = (updatedDataClusterWithOptions: DataClusterOptionSet) => {
        const newDataClustersWithOptions = state.consentCopy.dataClustersWithOptions.map((dataClusterWithOptions) => {
            if (dataClusterWithOptions.clusterId === updatedDataClusterWithOptions.clusterId) {
                return updatedDataClusterWithOptions;
            } else {
                return dataClusterWithOptions;
            }
        })

        setConsentSettings({
            ...state.consentCopy,
            dataClustersWithOptions: newDataClustersWithOptions,
        })
    }

    const anyDataClusterSelected: boolean =
        (state.consentCopy.dataClustersWithOptions)
            ? state.consentCopy.dataClustersWithOptions.filter(dataCluster => dataCluster.isEnabled).length > 0
            : false;

    const handleSave = () => {
        const newConfiguration: Configuration = {
            ...props.configuration,
            consent: state.consentCopy,
        }

        props.onConfigurationUpdate && props.onConfigurationUpdate(newConfiguration, 'view');
    };

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    }

    const onEditorStateChange = (field: string, editorState: EditorState) => {
        setConsentSettings({
            ...consent,
            [field]: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        })
    };

    const dataClusters =
        <Grid item xs={12} sm={7} md={5}>
            {state.consentCopy.dataClustersWithOptions.map((dataClusterWithOptions, index) => {
                const detailsBox = (dataClusterWithOptions.isEnabled &&
                    <Box className={`details-box ${bgClass}`}>
                        <div>
                            <FormControlLabel
                                className = {"disabled-checkbox-label-color"}
                                control={<Checkbox 
                                    name={dataClusterWithOptions.authorisationScopeId}
                                    checked={dataClusterWithOptions.isMandatory} 
                                    disabled={!editMode}
                                    className = {"disabled-checkbox-color"}
                                    onChange={
                                    (event, checked) => {
                                        setDataCluster({
                                            ...dataClusterWithOptions,
                                            isMandatory: checked,
                                        })
                                    }
                                }/>}
                                label="Is mandatory"
                            />
                        </div>

                        <label className={"mt-10"}>"Why we need it" text<LightTooltip
                            title="This text will be displayed to the customer" placement="right-start"
                            arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        <EditableText
                            editMode={editMode}
                            name={"usageExplanatoryText"}
                            value={dataClusterWithOptions.purposeInformation}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    setDataCluster({...dataClusterWithOptions, purposeInformation: e.target.value})
                                }
                            }}
                        />

                        <label className={editMode ? "mt-20" : "mt-10"}>List of data fields</label>
                        <ul>
                            {dataClusterWithOptions.clusterPermissionNames.map((permissionName, index) =>
                                <li key={index}>{permissionName}</li>
                            )}
                        </ul>
                    </Box>);

                    return <div className={"form-group"} key={index}>
                        <FormControlLabel
                            className = {"disabled-checkbox-label-color"}
                            control={<Checkbox 
                                name={dataClusterWithOptions.authorisationScopeId} 
                                checked={dataClusterWithOptions.isEnabled}
                                className = {"disabled-checkbox-color"}
                                color = {"primary"}
                                disabled={!editMode}
                                               onChange={
                                                   (event, checked) => {
                                                       setDataCluster({
                                                           ...dataClusterWithOptions,
                                                           isEnabled: checked,
                                                       })
                                                   }
                                               }/>}
                            label={dataClusterWithOptions.clusterName}
                        />
                        {detailsBox}
                    </div>
                }
            )}
        </Grid>;

    const consent = state.consentCopy;
    const form =
        <form className={"admin-form pre-consent-form mt-50"} autoComplete={"off"}>
            <div>
                <Box mb={2}>
                    <h4>Data Request</h4>
                </Box>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Active consent duration explanatory text<LightTooltip
                            title={<img className="tooltip-image" src="/images/admin/consent/Active_consent_duration_explanatory_text.png" alt="" />}
                            placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            textArea
                            editMode={editMode}
                            name={"activeConsentDurationExplanatoryText"}
                            value={consent.activeConsentDurationText}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    setConsentSettings({...consent, activeConsentDurationText: e.target.value})
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>"Data we need" text</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            textArea
                            editMode={editMode}
                            name={"dataWeNeedText"}
                            value={consent.dataWeNeedText}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    setConsentSettings({...consent, dataWeNeedText: e.target.value})
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data cluster &amp; permission text<LightTooltip
                            title={<img className="tooltip-image" src="/images/admin/consent/Data_cluster_permission_text.png" alt="" />}
                            placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl>
                            <SwitchWithLabel
                                checked={consent.inclDataClusterPermissionText}
                                disabled={!editMode}
                                onChange={value => {
                                    setConsentSettings({...consent, inclDataClusterPermissionText: value})
                                }}>
                                {editMode ? "Include" : consent.inclDataClusterPermissionText ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data types to collect &amp; usage explanatory text<LightTooltip
                            title={<img className="tooltip-image" src="/images/admin/consent/Data_types_to_collect_usage_explanatory_text.png" alt="" />}
                            placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    {dataClusters}
                </Grid>

                {/* BEGIN NEW ADDITIONAL */}
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Additional uses of data<LightTooltip
                                title={<img className="tooltip-image" src="/images/admin/consent/Additional_uses_of_data.png" alt="" />}
                                placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <Box className={`details-box ${bgClass}`}>
                            <Box>
                                <label>Main Heading<LightTooltip title="Main heading for additional uses of data" placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                <EditableText
                                    editMode={editMode}
                                    name={"additionalUsesOfData.mainHeading"}
                                    value={consent.additionalUsesOfData?.mainHeading}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            setConsentAdditionalUsesOfDataSettings({...consent.additionalUsesOfData, mainHeading: e.target.value})
                                        }
                                    }}
                                />
                            </Box>
                            <Box mt={4}>
                                <label>Subheading<LightTooltip title="Subheading for additional uses of data" placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                <EditableText
                                    editMode={editMode}
                                    name={"additionalUsesOfData.subheading"}
                                    value={consent.additionalUsesOfData?.subheading}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            setConsentAdditionalUsesOfDataSettings({...consent.additionalUsesOfData, subheading: e.target.value})
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box className={`details-box ${bgClass}`} mt={4}>
                            <Box display="flex" justifyContent={'space-between'}>
                                <label>Categorisation Improvements</label>
                                <FormControl>
                                    <SwitchWithLabel 
                                        checked={consent.additionalUsesOfData?.categorisation?.include || false}
                                        disabled={!editMode}
                                        onChange={value => {
                                            setConsentAdditionalUsesOfDataSettings({
                                                ...consent.additionalUsesOfData,
                                                categorisation: {
                                                    ...consent.additionalUsesOfData?.categorisation,
                                                    include: value
                                                }
                                            })
                                        }}
                                    >
                                        {editMode ? "Include" : consent.additionalUsesOfData?.categorisation?.include ? "Include" : "Exclude"}
                                    </SwitchWithLabel>
                                </FormControl>
                            </Box>
                            <Box mt={2}>
                                <label>Label</label>
                                <EditableText
                                    textArea
                                    editMode={editMode}
                                    name={"additionalUsesOfData.subheading"}
                                    value={consent.additionalUsesOfData?.categorisation?.label}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            setConsentAdditionalUsesOfDataSettings({
                                                ...consent.additionalUsesOfData,
                                                categorisation: {
                                                    ...consent.additionalUsesOfData?.categorisation,
                                                    label: e.target.value
                                                }
                                            })
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box className={`details-box ${bgClass}`} mt={4}>
                            <Box display="flex" justifyContent={'space-between'}>
                                <label>Spend Analytics</label>
                                <FormControl>
                                    <SwitchWithLabel 
                                        checked={consent.additionalUsesOfData?.spendAnalytics?.include || false}
                                        disabled={!editMode}
                                        onChange={value => {
                                            setConsentAdditionalUsesOfDataSettings({
                                                ...consent.additionalUsesOfData,
                                                spendAnalytics: {
                                                    ...consent.additionalUsesOfData?.spendAnalytics,
                                                    include: value
                                                }
                                            })
                                        }}
                                    >
                                        {editMode ? "Include" : consent.additionalUsesOfData?.spendAnalytics?.include ? "Include" : "Exclude"}
                                    </SwitchWithLabel>
                                </FormControl>
                            </Box>
                            <Box mt={2}>
                                <label>Label</label>
                                <EditableText
                                    textArea
                                    editMode={editMode}
                                    name={"additionalUsesOfData.subheading"}
                                    value={consent.additionalUsesOfData?.spendAnalytics?.label}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            setConsentAdditionalUsesOfDataSettings({
                                                ...consent.additionalUsesOfData,
                                                spendAnalytics: {
                                                    ...consent.additionalUsesOfData?.spendAnalytics,
                                                    label: e.target.value
                                                }
                                            })
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box className={`details-box ${bgClass}`} mt={4}>
                            <Box display="flex" justifyContent={'space-between'}>
                                <label>Marketing</label>
                                <FormControl>
                                    <SwitchWithLabel 
                                        checked={consent.additionalUsesOfData?.marketing?.include || false}
                                        disabled={!editMode}
                                        onChange={value => {
                                            setConsentAdditionalUsesOfDataSettings({
                                                ...consent.additionalUsesOfData,
                                                marketing: {
                                                    ...consent.additionalUsesOfData?.marketing,
                                                    include: value
                                                }
                                            })
                                        }}
                                    >
                                        {editMode ? "Include" : consent.additionalUsesOfData?.marketing?.include ? "Include" : "Exclude"}
                                    </SwitchWithLabel>
                                </FormControl>
                            </Box>
                            <Box mt={2}>
                                <label>Label</label>
                                <EditableText
                                    textArea
                                    editMode={editMode}
                                    name={"additionalUsesOfData.subheading"}
                                    value={consent.additionalUsesOfData?.marketing?.label}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            setConsentAdditionalUsesOfDataSettings({
                                                ...consent.additionalUsesOfData,
                                                marketing: {
                                                    ...consent.additionalUsesOfData?.marketing,
                                                    label: e.target.value
                                                }
                                            })
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent={'space-between'} alignItems={'center'} mt={4}>
                            {(consent.additionalUsesOfData?.custom || editMode) && <label>Custom additional uses of data</label>}
                            {editMode && (consent.additionalUsesOfData?.custom || []).length < 10 && <Button size="small" color="secondary" variant="outlined" style={{minWidth: 'auto', height: 'auto'}}
                             onClick={() => setConsentAdditionalUsesOfDataSettings({
                                 ...consent.additionalUsesOfData,
                                 custom: [...(consent.additionalUsesOfData?.custom || []), {
                                    key: '',
                                    label: '',
                                 }]
                             })}
                            >Add</Button>}

                        </Box>
                        {(consent.additionalUsesOfData?.custom || []).map((customAdditionalUse, index) => {
                            return (<Box className={`details-box ${bgClass}`} mt={4} key={index}>
                                <Box>
                                    <label>Key</label>
                                    <EditableText
                                        editMode={editMode}
                                        name={`additionalUsesOfData[${index}].key`}
                                        value={customAdditionalUse.key}
                                        eventHandlers={{
                                            onChange: (e: any) => {
                                                setCustomAdditionalUseOfDataSettings(index, {...customAdditionalUse, key: e.target.value})
                                            }
                                        }}
                                    />
                                </Box>
                                <Box mt={4}>
                                    <label>Label</label>
                                    <EditableText
                                        textArea
                                        editMode={editMode}
                                        name={`additionalUsesOfData[${index}].label`}
                                        value={customAdditionalUse.label}
                                        eventHandlers={{
                                            onChange: (e: any) => {
                                                setCustomAdditionalUseOfDataSettings(index, {...customAdditionalUse, label: e.target.value})
                                            }
                                        }}
                                    />
                                </Box>
                                {editMode && <Box mt={2} display="flex" justifyContent={'flex-end'}>
                                    <Button size="small" color="secondary" variant="contained" style={{minWidth: 'auto', height: 'auto'}}
                                        onClick={() => removeCustomAdditionalUseOfDataSetting(index)}
                                    >Remove</Button>
                                </Box>}
                            </Box>);
                        })}
                    </Grid>
                </Grid>
                {/* END NEW ADDITIONAL */}

                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Supporting Third Parties<LightTooltip title="Supporting Third Parties" placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <Box display="flex" justifyContent={'space-between'} alignItems={'center'}>
                            {editMode && (consent.thirdParties || []).length < 10 && <Button size="small" color="secondary" variant="outlined" style={{minWidth: 'auto', height: 'auto'}}
                                onClick={() => setConsentSettings({
                                    ...consent,
                                    thirdParties: [...(consent.thirdParties || []), {
                                    key: '',
                                    label: '',
                                    }]
                                })}
                            >Add</Button>}
                        </Box>
                        {(consent.thirdParties || []).map((customAdditionalUse, index) => {
                            return (<Box className={`details-box ${bgClass}`} mt={4} key={index}>
                                <Box>
                                    <label>Third Party Name</label>
                                    <EditableText
                                        editMode={editMode}
                                        name={`additionalUsesOfData[${index}].key`}
                                        value={customAdditionalUse.key}
                                        eventHandlers={{
                                            onChange: (e: any) => {
                                                setThirdPartiesAdditionalUseOfDataSettings(index, {...customAdditionalUse, key: e.target.value})
                                            }
                                        }}
                                    />
                                </Box>
                                <Box mt={4}>
                                    <label>Service they provide</label>
                                    <EditableText
                                        textArea
                                        editMode={editMode}
                                        name={`additionalUsesOfData[${index}].label`}
                                        value={customAdditionalUse.label}
                                        eventHandlers={{
                                            onChange: (e: any) => {
                                                setThirdPartiesAdditionalUseOfDataSettings(index, {...customAdditionalUse, label: e.target.value})
                                            }
                                        }}
                                    />
                                </Box>
                                {editMode && <Box mt={2} display="flex" justifyContent={'flex-end'}>
                                    <Button size="small" color="secondary" variant="contained" style={{minWidth: 'auto', height: 'auto'}}
                                        onClick={() => removeThirdPartiesAdditionalUseOfDataSetting(index)}
                                    >Remove</Button>
                                </Box>}
                            </Box>);
                        })}
                    </Grid>
                </Grid>

                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Supporting Third Parties Email
                            <LightTooltip title="Please specify an email address" placement="right-start" arrow>
                                <InfoIcon color={"secondary"} />
                            </LightTooltip>
                        </label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        {editMode ? <TextField
                            name="statement-delivery-email"
                            value={consent.thirdPartiesEmail}    
                            onChange={(event: any) => {
                                let email = event.target.value;
                                thirdPartiesEmailValidationError.innerHTML = "";
                                if (email !== "") {
                                    let invalidEmail = validateIfTargetsAreValidEmails(email);
                                    if (invalidEmail.length > 0) {
                                        thirdPartiesEmailValidationError.innerHTML = "You have entered invalid email address: " + invalidEmail;
                                    }
                                }
                                setConsentSettings({...consent, thirdPartiesEmail: event.target.value})
                            }}
                        />: <>
                        {consent.thirdPartiesEmail}
                        </>}
                        <div className="mandatory-fields-hint" ref={(message) => { thirdPartiesEmailValidationError = message; }}></div>
                    </Grid>
                </Grid>

                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Frequency of data collection text<LightTooltip title="Frequency of data collection text for data request"
                                                                               placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl>
                            <SwitchWithLabel 
                                checked={consent.includeFrequencyOfDataCollectionText}
                                disabled={!editMode}
                                onChange={value => {
                                    setConsentSettings({...consent, includeFrequencyOfDataCollectionText: value})
                                }}
                            >
                                {editMode ? "Include" : consent.includeFrequencyOfDataCollectionText ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                        <Box className={`details-box ${bgClass}`}>
                            <Box>
                                <label>Main Heading<LightTooltip title="Main heading for duration of data sharing"
                                                                  placement="right-start" arrow><InfoIcon
                                    color={"secondary"}/></LightTooltip></label>
                                <EditableText
                                    editMode={editMode}
                                    name={"fodcHeading"}
                                    value={consent.frequencyOfDataCollectionTextMainHeading}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            setConsentSettings({...consent, frequencyOfDataCollectionTextMainHeading: e.target.value})
                                        }
                                    }}
                                />
                            </Box>
                            <Box mt={4}>
                                <label>Main Text<LightTooltip title="Main text for duration of data sharing"
                                                               placement="right-start" arrow><InfoIcon
                                    color={"secondary"}/></LightTooltip></label>
                                <EditableText
                                    textArea
                                    editMode={editMode}
                                    name={"fodcText"}
                                    value={consent.frequencyOfDataCollectionTextMainText}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            setConsentSettings({...consent, frequencyOfDataCollectionTextMainText: e.target.value})
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Sharing period type<LightTooltip title="Sharing period type" placement="right-start" arrow>
                            <InfoIcon color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        {editMode ?
                            <FormControl variant="outlined" className="w-100">
                                <Select
                                    name="sharing-period-type"
                                    value={consent.sharingPeriodType || "default"}
                                    onChange={(event: SelectChangeEvent) => {
                                        setConsentSettings({
                                            ...consent,
                                            sharingPeriodType: event.target.value as string,
                                            fixedSharingPeriod: consent.fixedSharingPeriod || ""
                                        })
                                    }}
                                >
                                    <MenuItem value={"default"}>Default (selectable)</MenuItem>
                                    <MenuItem value={"fixed"}>Fixed</MenuItem>
                                </Select>
                            </FormControl>
                            : (consent.sharingPeriodType === "fixed" ? "Fixed" : "Default (selectable)")
                        }
                    </Grid>
                </Grid>
                
                {consent.sharingPeriodType === "fixed" &&
                    <Grid container className={"mt-30"}>
                        <Grid item xs={12} sm={4} md={3}>
                            <label>Fixed sharing period<LightTooltip title="Fixed sharing period" placement="right-start" arrow>
                                <InfoIcon color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {editMode ?
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        name="fixed-sharing-period"
                                        value={consent.fixedSharingPeriod || ""}
                                        onChange={(event: SelectChangeEvent) => {
                                            setConsentSettings({...consent, fixedSharingPeriod: event.target.value as string})
                                        }}
                                    >
                                        <MenuItem value={"none"}>- Please select -</MenuItem>
                                        <MenuItem value={"0"}>Once-off</MenuItem>
                                        <MenuItem value={"1"}>1 Month</MenuItem>
                                        <MenuItem value={"3"}>3 Months</MenuItem>
                                        <MenuItem value={"6"}>6 Months</MenuItem>
                                        <MenuItem value={"12"}>12 Months</MenuItem>
                                    </Select>
                                </FormControl>
                                : (consent.fixedSharingPeriod === "0" ? "Once-off"
                                    : consent.fixedSharingPeriod + (consent.fixedSharingPeriod === "1" ? " Month" : " Months")
                                )
                            }
                        </Grid>
                    </Grid>
                }

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data Deidentification and Deletion Heading<LightTooltip title="Data Deidentification and Deletion Heading"
                                                                                  placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            editMode={editMode}
                            name={"data-deidentification-and-deletion-heading"}
                            value={consent.dataDeidentificationAndDeletionHeading}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    setConsentSettings({...consent, dataDeidentificationAndDeletionHeading: e.target.value})
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Display Data Deidentification section<LightTooltip title="Whether to include data deletion section"
                                                                                  placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl>
                            <SwitchWithLabel
                                checked={consent.includeDataDeidentificationStandardText}
                                disabled={!editMode}
                                onChange={value => {
                                    setConsentSettings({...consent, includeDataDeidentificationStandardText: value})
                                }}
                            >
                                {editMode ? "Include" : consent.includeDataDeidentificationStandardText ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data Deidentification Text<LightTooltip title="Data Deidentification Text"
                                                                                       placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            textArea
                            editMode={editMode}
                            name={"data-deidentification-text"}
                            value={consent.dataDeidentificationText}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    setConsentSettings({...consent, dataDeidentificationText: e.target.value})
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data Deidentification Popup Link Text<LightTooltip title="Data Deidentification Popup Link Text"
                                                                                       placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            editMode={editMode}
                            name={"data-deidentification-popup-link-text"}
                            value={consent.dataDeidentificationPopupLinkText}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    setConsentSettings({...consent, dataDeidentificationPopupLinkText: e.target.value})
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data Deidentification Popup Heading<LightTooltip title="Data Deidentification Popup Heading"
                                                                        placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            editMode={editMode}
                            name={"data-deletion-popup-link-text"}
                            value={consent.dataDeidentificationPopupHeading}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    setConsentSettings({...consent, dataDeidentificationPopupHeading: e.target.value})
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data Deidentification Popup Contents<LightTooltip title="Data Deidentification Popup Contents"
                                                                         placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableRichText
                            editMode={editMode}
                            value={consent.dataDeidentificationPopupContents}
                            onChange={editorState => {
                                onEditorStateChange('dataDeidentificationPopupContents', editorState)
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Display Data Deletion section<LightTooltip title="Whether to include data deletion section"
                                                                         placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl>
                            <SwitchWithLabel 
                                checked={consent.includeDataDeletionStandardText}
                                disabled={!editMode}
                                onChange={value => {
                                    setConsentSettings({...consent, includeDataDeletionStandardText: value})
                                }}
                            >
                                {editMode ? "Include" : consent.includeDataDeletionStandardText ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data Deletion Text<LightTooltip title="Data Deletion Text"
                                                                          placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableRichText
                            editMode={editMode}
                            value={consent.dataDeletionText}
                            onChange={editorState => {
                                onEditorStateChange('dataDeletionText', editorState)
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data Deletion Popup Link Text<LightTooltip title="Data Deletion Popup Link Text"
                                                                                  placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            editMode={editMode}
                            name={"data-deletion-popup-link-text"}
                            value={consent.dataDeletionPopupLinkText}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    setConsentSettings({...consent, dataDeletionPopupLinkText: e.target.value})
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data Deletion Popup Heading<LightTooltip title="Data Deletion Popup Heading"
                                                                          placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            editMode={editMode}
                            name={"data-deletion-popup-link-text"}
                            value={consent.dataDeletionPopupHeading}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    setConsentSettings({...consent, dataDeletionPopupHeading: e.target.value})
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data Deletion Popup Contents<LightTooltip title="Data Deletion Popup Contents"
                                                               placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableRichText
                            editMode={editMode}
                            value={consent.dataDeletionPopupContents}
                            onChange={editorState => {
                                onEditorStateChange('dataDeletionPopupContents', editorState)
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Managing Your Data Heading<LightTooltip title="Managing Your Data Heading"
                                                                                       placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            editMode={editMode}
                            name={"managing-your-data-heading"}
                            value={consent.managingYourDataHeading}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    setConsentSettings({...consent, managingYourDataHeading: e.target.value})
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Managing Your Data Text<LightTooltip title="Managing Your Data Text"
                                                               placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableRichText
                            editMode={editMode}
                            value={consent.managingYourDataText}
                            onChange={editorState => {
                                onEditorStateChange('managingYourDataText', editorState)
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Replace an existing consent text<LightTooltip title="Whether to include option to replace an existing consent text"
                                                                              placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl>
                            <SwitchWithLabel 
                                checked={consent.includeReplaceAnExistingConsentText}
                                disabled={!editMode}
                                onChange={value => {
                                    setConsentSettings({...consent, includeReplaceAnExistingConsentText: value})
                                }}
                            >
                                {editMode ? "Include" : consent.includeReplaceAnExistingConsentText ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                    </Grid>
                </Grid>

            </div>

            <p/><p/><br />

            <div>
                <Box mb={2}>
                    <h4>Consent Amendment - Deletion of Previously Collected Data</h4>
                </Box>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data Deidentification and Deletion Heading<LightTooltip title="Data Deidentification and Deletion Heading"
                                                                                       placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            editMode={editMode}
                            name={"data-deidentification-and-deletion-heading"}
                            value={consent.consentAmendmentDataDeidentificationAndDeletionHeading}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    setConsentSettings({...consent, consentAmendmentDataDeidentificationAndDeletionHeading: e.target.value})
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data Deidentification Text<LightTooltip title="Data Deidentification Text"
                                                                       placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            textArea
                            editMode={editMode}
                            name={"data-deidentification-text"}
                            value={consent.dataDeidentificationText}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    setConsentSettings({...consent, dataDeidentificationText: e.target.value})
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            <p/><p/><br />

            <div>
                <Box mb={2}>
                    <h4>Consent Success Confirmation</h4>
                </Box>

                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Consent Success Message<LightTooltip title="Consent Success Message"
                                                                                       placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableRichText
                            editMode={editMode}
                            value={consent.consentSuccessMessage}
                            onChange={editorState => {
                                onEditorStateChange('consentSuccessMessage', editorState)
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            <div>
                {(props.mode === 'edit') &&
                <Grid container className={"mt-20"}>
                    <Grid item xs={12}>
                        {!anyDataClusterSelected &&
                        <p className="error-text">Please choose at least one data type to collect.</p>
                        }
                        <ButtonBlock className={"text-align-center"}>
                            <Button onClick={() => handleCancel()} variant={"outlined"} color={"secondary"}>Cancel</Button>
                            <Button disabled={!anyDataClusterSelected} onClick={() => handleSave()} variant={"contained"} color={"secondary"}>Save</Button>
                        </ButtonBlock>
                    </Grid>
                </Grid>
                }
            </div>

        </form>;

    let thirdPartiesEmailValidationError: any = null;

    return <Container>
        <h2 className={"page-title"}>Consent Configuration</h2>
        <h5 className="text-large">{props.configuration.generalSettings.principalName}</h5>

        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={11} md={8}>
                The Consent stage contains several steps, which may include a CDR value proposition; the data request;
                selecting a data holder; and the step before authentication.
            </Grid>
        </Grid>

        {form}
    </Container>
}

export default ConfigurationConsentSettings;