import React, { useCallback, useEffect, useState } from "react";
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";

import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs, Container, Grid, Hidden, Link, Typography } from "@mui/material";

import ConsentDataCluster from "../components/ConsentDataCluster";
import ConsentDetailsHeader from "../components/ConsentDetailsHeader";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoadingSpinner from "../components/LoadingSpinner";
import MessageBox from "../components/MessageBox";
import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import ConsentListItemData from "../models/ConsentListItemData";
import GeneralSettings from "../models/GeneralSettings";
import ModalPopUpData from "../models/ModalPopUpData";
import DataCluster from "../openbankingplatform/models/DataCluster";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import modalPopUpContents from "../popups/ModalPopUpDataHelper";
import Popup from "../popups/Popup";
import { sanitize } from "../helpers/HtmlSanitizerHelper";
import PreConsent from "../models/PreConsent";
import Consent from "../models/Consent";

interface WithdrawConsentSuccessProps {
	basePath: string,
	api: OpenBankingPlatformAPI,
	dashboardPath: string,
	preconsentConfig?: PreConsent,
	consentConfig?: Consent,
	generalSettingsConfig?: GeneralSettings;
	principalLogoUrl?: string;
	headerBgImageUrl?: string;
	loadPrincipalConfiguration: (principal_id?: number) => void;
	footerText?: string;
	isTrustedAdviser?: boolean;
}

interface ConsentDetailsState {
	principalName?: string;
	consentDetails?: ConsentListItemData;
	dataClusters: Array<DataCluster>;
	sharingStartAt?: string;
	sharingEndAt?: string;
	effectiveAt?: string;
	withdrawnAt?: string;
	consentStatus: string;
	downloadPdfData?: any;
	apiHasReturnedAnError: boolean;
	modalPopUpData: Array<ModalPopUpData>;
}

const WithdrawConsentSuccess = (props: WithdrawConsentSuccessProps) => {

	const { id } = useParams<{ id: any }>();

	const [state, setState] = useState<ConsentDetailsState>({
		dataClusters: [],
		consentStatus: '',
		apiHasReturnedAnError: false,
		modalPopUpData: []
	});
	const [open3, setOpen3] = React.useState(false);
	const [popUpModalDatas, setPopUpModalDatas] = React.useState<ModalPopUpData>();

	const navigate = useNavigate();
	const handleGoBack = () => {
		navigate(props.dashboardPath);
	};

	const providerPopup = () => {
		const providerpopupopupdatas = state.modalPopUpData[0];
		openPopup3(providerpopupopupdatas);
	}

	const deleteDataPopup = () => {
		const deletepopupopupdatas = state.modalPopUpData[2];
		openPopup3(deletepopupopupdatas);
	}

	const openPopup3 = (currentPopUpData: ModalPopUpData) => {
		setOpen3(true);
		setPopUpModalDatas(currentPopUpData);
	};

	const closePopup3 = () => {
		setOpen3(false);
	};

	const popUpgeneralTexts = popUpModalDatas?.popUpGeneralText.map((item, index) =>
		<p key={index} dangerouslySetInnerHTML={{ __html: sanitize(item.content) }}></p>
	);

	const popUpBoxTexts = popUpModalDatas?.popupBoxContents.map((item, index) =>
		<div key={index}>
			<h6>{item.heading}</h6>
			<p>{item.content}</p>
		</div>
	);

	const popUpBlockTexts = popUpModalDatas?.popupBlockContents.map((item, index) => {
		return (
			<div key={index}>
				<h6>{item.heading}</h6>
				<br />
				{
					item.content?.map((word, index) => {
						return (
							<p key={index} dangerouslySetInnerHTML={{ __html: sanitize(word.text) }}></p>
						)
					})
				}
				<br />
			</div>
		)
	});

	//eslint-disable-next-line
	const getConsent = useCallback(props.api.getConsentById, []);
	//eslint-disable-next-line
	const getDataClusters = useCallback(props.api.getSelectedDataClustersForConsent, []);
	useEffect(() => {
		(async () => {
			try {
				const loadedDataClusters = await getDataClusters(id);
				const loadConsent = await getConsent(id);
				setState((state) => ({
					...state,
					consentDetails: loadConsent,
					dataClusters: loadedDataClusters,
					sharingStartAt: loadConsent.sharing_start_at,
					sharingEndAt: loadConsent.sharing_end_at,
					effectiveAt: loadConsent.effective_at,
					withdrawnAt: loadConsent.withdrawn_at,
					consentStatus: loadConsent.status,
					snapShotPreConsentData: loadConsent.consent_snapshot?.preConsent,
					snapShotConsentData: loadConsent.consent_snapshot?.consent,
					modalPopUpData: modalPopUpContents(
						props.generalSettingsConfig,
						loadConsent.consent_snapshot?.preConsent,
						loadConsent.consent_snapshot?.consent,
						props.generalSettingsConfig?.principalName,
						loadConsent.institution_name,
					),
				}))

			} catch (error) {

				// TODO Uncomment this code when fallback is removed
				setState((state) => ({
					...state,
					apiHasReturnedAnError: true,
				}))
				consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
			}
		})();
	},
		//eslint-disable-next-line
		[]
	)

	const dataClustersList = state.dataClusters.map((item, i) =>
		<ConsentDataCluster
			principalName={props.generalSettingsConfig?.principalName}
			sharingStartAt={state.sharingStartAt}
			sharingEndAt={state.sharingEndAt}
			{...item} />
	);

	//eslint-disable-next-line
	const getDownloadPdfData = useCallback(props.api.getDownloadPdf, []);
	const handleDownloadConsentPdf = (consent_uuid: string, consent_type: number) => {
		//Send to server
		(async (consent_uuid, consent_type) => {
			try {
				const pdfData = await getDownloadPdfData(consent_uuid, consent_type);
				setState(state => ({
					...state,
					downloadPdfData: pdfData,
				}));
			} catch (error) {
				console.log(error);
			}
		})(consent_uuid, consent_type);
	}
	useEffect(() => {
		if (state.downloadPdfData) {
			let blob = new Blob([state.downloadPdfData], { type: 'application/pdf' });
			let url = window.URL.createObjectURL(blob);
			let link = document.createElement('a');
			link.setAttribute('download', 'consent-withdrawal.pdf');
			link.href = window.URL.createObjectURL(blob);
			link.click();
			window.URL.revokeObjectURL(url);
		}
	}, [state.downloadPdfData]);

	const handleShowHistory = () => {
		navigate(props.dashboardPath + `/consent-history/${id}`, {
			state: state.consentDetails
		});
	}

	// Reset to default configured principal on dashboard load
	useEffect(() => {
		(async () => {
			await props.loadPrincipalConfiguration(0);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <div className={"page-wrapper withdraw-consent-success"}>
		<div className={"page-top"}>
			<main>
				<Header
					generalSettings={props.generalSettingsConfig}
					principalLogoUrl={props.principalLogoUrl}
					headerBgImageUrl={props.headerBgImageUrl}
					isTrustedAdviser={props.isTrustedAdviser}
				/>
				{(state.consentDetails) ?
					<Container maxWidth={"lg"}>
						<Breadcrumbs aria-label="breadcrumb">
							<Link color="inherit" href="/#" onClick={(e: any) => { e.preventDefault(); navigate(props.dashboardPath); }}>Dashboard</Link>
							<span>{sanitize(state.consentDetails?.institution_name)}</span>
						</Breadcrumbs>

						<ConsentDetailsHeader
							basePath={props.basePath}
							consentDetails={state.consentDetails}
							consentStatus={state.consentStatus}
							handleShowHistory={handleShowHistory}
							api={props.api}
						/>

						<Hidden smUp>
							<Box mb={3}>
								<Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e: any) => { e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
							</Box>
						</Hidden>

						<MessageBox icon={<CheckCircleOutlineIcon />} background={"green"}>
							<p className={"withdraw-success-msg-margin"}>You have successfully stopped sharing the data below with us. We will no longer have access to this data. This information has been archived.</p>
							{state.consentDetails?.id ? (
								<Typography component={'div'}>
									<FontAwesomeIcon icon={faDownload} />&nbsp; <a id="downloadPdf" style={{ color: "inherit" }} href="/#" onClick={(e) => { e.preventDefault(); handleDownloadConsentPdf(state.consentDetails?.uuid as string, 2); }} >Download withdraw confirmation PDF</a>
								</Typography>
							) : null}
						</MessageBox>

						<Grid container spacing={5} className={"data-collection-details data-collection-details-padding"}>

							{/* Column 1 */}
							<Grid item xs={12} md={6}>
								<Box mb={3}>
									<Typography component={'div'} color={"secondary"}><h3 className="font-weight-normal">Data we have collected</h3></Typography>
								</Box>

								{dataClustersList}

							</Grid>
							{/* End of Column 1 */}

							{/* Column 2 */}
							<Grid item xs={12} md={6}>
								<Accordion className={"simple-accordion"} defaultExpanded>
									<Typography component={'div'} color={"secondary"}>
										<AccordionSummary expandIcon={<KeyboardArrowDownRoundedIcon color={"secondary"} />}>How your data was used</AccordionSummary>
									</Typography>
									<AccordionDetails>
										<h6>Purpose of data sharing</h6>
										<p>We collected and used your data to help you track your budget.</p>

										<h6>Sharing with third parties</h6>
										<p>Supporting third parties no longer have access to your data. They were bound by the same terms of this arrangement. <a href="/#" onClick={(e) => {
											e.preventDefault();
											providerPopup();
										}}>Learn more</a></p>

										<h6>What happened to your shared data?</h6>
										<p>The data you have shared has been deleted. <a href="/#" onClick={(e) => {
											e.preventDefault();
											deleteDataPopup()
										}}>Learn more</a></p>

									</AccordionDetails>
								</Accordion>

								<Accordion className={"simple-accordion"} defaultExpanded>
									<Typography component={'div'} color={"secondary"}>
										<AccordionSummary expandIcon={<KeyboardArrowDownRoundedIcon color={"secondary"} />}>Key dates</AccordionSummary>
									</Typography>
									<AccordionDetails>
										<h6>When you gave consent</h6>
										<p>
											<Moment format="DD MMM YYYY">
												{state.sharingStartAt}
											</Moment>
										</p>

										<h6>When you cancelled your consent</h6>
										<p>
											<Moment format="DD MMM YYYY">
												{state.withdrawnAt}
											</Moment>
										</p>

										<h6>Sharing period</h6>
										<p>
											<Moment format="DD MMM YYYY">
												{state.sharingStartAt}
											</Moment>
											-
											<Moment format="DD MMM YYYY">
												{state.sharingEndAt}
											</Moment>
										</p>

										<h6>How often we accessed your data</h6>
										<p>We accessed your data every time you used {sanitize(props.generalSettingsConfig?.principalName)}.</p>
									</AccordionDetails>
								</Accordion>
							</Grid>
							{/* End of Column 2 */}
						</Grid>

						<Hidden xsDown>
							<Box mb={6}>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={3} className={"align-self-center"}>
										<Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e: any) => { e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
									</Grid>
								</Grid>
							</Box>
						</Hidden>
					</Container>
					: (state.apiHasReturnedAnError) ?
						<>
							<h3 className={"error-title"}>Error</h3>
							<p className={"error-text"}>We're sorry but our system has encountered an error. Please try
								again later or contact support.</p>
						</> : <LoadingSpinner position={"fixed"} overlay />
				}

				{/* Information popup */}
				<Popup
					heading={popUpModalDatas?.popUpHeading}
					open={open3}
					withCloseButton
					onClose={closePopup3}
				>
					{popUpgeneralTexts}
					{popUpBoxTexts && popUpBoxTexts.length > 0 &&
						<Box my={3} p={3} className={"background-grey"}>{popUpBoxTexts}</Box>
					}
					{popUpBlockTexts}
					<a href={props.generalSettingsConfig?.cdrPolicyURL} target="_blank" rel="noopener noreferrer">View Consumer Data Right policy</a>
				</Popup>
			</main>
		</div>

		<Footer generalSettingsConfig={props.generalSettingsConfig} />

	</div>;
}

export default WithdrawConsentSuccess;